import React, { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import axios from 'axios';
import moment from 'moment';

import { Button } from '@mui/material';
import { QueryClient, useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { dataReviewApi, mealApi, mealPushQuestionApi } from 'api';
import {
  CreateMealItemFoodMatchDetailsRequest,
  CreateMealItemRequest,
  FoodResponse,
  MealItemCustomAddonResponse,
  MealItemResponse,
  MealPhotoQueueResponse,
  MealPushQuestionResponse,
  MealPushQuestionStatusEnum,
  NutrientEstimatesRequest,
  UpdateMealPushQuestionRequest,
} from 'api/generated/MNT';
import { getMealHistory } from 'apiClients/mealHistory';
import { type MealItem, type MealQueueItem } from 'apiClients/mpq';
import { deleteMealItem } from 'apiClients/review';
import { config } from 'config';
import { useAuth } from 'context/appContext';
import { useFeatures } from 'context/FeatureContext';
import { logTrackedError } from 'errorTracking';
import { NUTRIENT_100G, nutrientScale } from 'food-editor/components/food-editor';
import { pluralize } from 'food-editor/utils/utils';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { Location, NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { useAsyncResult } from 'react-use-async-result';
import { useMealQueueService } from 'services/MealQueueService';
import {
  mpqImLblDetectResultToDebugContext,
  mpqImLblQueryKey,
  mpqImLblShouldPrefillMeal,
  mpqLoadImLblDetectResult,
} from 'services/MpqImLblService';
import { snackBarShow } from 'services/SnackBarService';
import {
  DraftItem,
  formatDraftItemFoodName,
  mealItemResponseToDraftItem,
  QueueDiff,
  QueueDiffWithReason,
} from 'types/DraftItem';
import { useQueryNeverRefetch } from 'utils';
import {
  isValidMealItem,
  mealItemGetNutrientOverrides,
  mealItemGetUpdatedNutrientOverrides,
  NutrientDef,
  nutrientGetDef,
} from 'utils/mealItems';
import { mkRandId } from 'utils/telemetry';
import { imLblMatchToDraftItem } from '../meal-builder/MealPhotoQueueImageAutomaticLabels';
import { PatientContext, usePatientContext } from '../meal-builder/usePatientContext';

export const getEmptyMealItem = (): MealItem => ({
  id: undefined,
  food_name: '',
  custom_usda_id: null,
  custom_nutrient_estimates: {},
  custom_addons: [],
  serving_unit_label: '',
  serving_unit_amount: 0,
  servings: 1,
  addons: [],
  custom_item: false,
  custom_item_source: null,
  custom_item_source_id: null,
  nutrient_overrides: {},
});

async function saveQueueInternal(opts: {
  reviewerId: number,
  queueItem: MealQueueItem,
  draftItems: DraftItem[],
}) {
  try {
    const res = await dataReviewApi.appApiMealPostMealItemsFromDataReviewerGroupQueue({
      data_reviewer_id: opts.reviewerId,
      data_reviewer_group_id: opts.queueItem.data_reviewer_group_id,
      meal_photo_queue_id: opts.queueItem.id,
      CreateMealItemFromQueueRequest: opts.draftItems.map(di => ({
        ...di.item,
        extra_addons: [],
        food_match_details: di.foodMatchDetails ?? undefined,
        meal_push_question: di.pushQuestionUpdate ?? undefined,
      })),
    });

    const resp = res.data;
    return {
      success: true as const,
      queueItem: resp,
    };
  } catch (e) {
    if (axios.isAxiosError(e) && e?.response?.status == 404) {
      return {
        success: false as const,
        didAlreadySubmit: (e.response?.data as any)?.message == 'meal photo queue item has already been reviewed',
      };
    }

    logTrackedError({
      sourceName: 'saveQueueInternal',
      origin: e as any,
      stackError: new Error(),
      context: { queueId: opts.queueItem.id },
      userMessage: `Unexpected error saving queue. Please try again.`,
    });

    return { success: false as const };
  }
}

const getDraftItemsForQueue = async (
  queue: MealPhotoQueueResponse,
): Promise<{ mealDeleted: boolean, draftItems: DraftItem[] }> => {
  // TODO: send these back as part of the GetMealPhotoQueueItem response
  const meal = await mealApi.appApiMealGetMeal({
    meal_id: queue.created_meal_id,
    patient_id: queue.patient_id,
    include_archived: true,
  });

  return {
    mealDeleted: !!meal.data.is_deleted,
    draftItems: meal.data.meal_items.map(item => {
      // Temporarily copy custom_nutrient_estimates to reviewer nutrient_overrides
      // until custom_nutrient_estimates is migrated and fully deprecated
      // We only want them if it is a custom item!
      const reviewerOverrides = mealItemGetNutrientOverrides({ item: item, sourceName: 'reviewer' });
      const updatedReviewerOverrides = !reviewerOverrides && item.custom_nutrient_estimates && item.custom_item
        ? mealItemGetUpdatedNutrientOverrides({
          nutrientOverrides: item.nutrient_overrides,
          sourceName: 'reviewer',
          sourceId: '1',
          newNutrients: Object.keys(item.custom_nutrient_estimates || {}).reduce(
            (acc: Partial<NutrientEstimatesRequest>, n) => {
              acc[n as keyof NutrientEstimatesRequest] = nutrientScale({
                value: (item.custom_nutrient_estimates?.[n as keyof NutrientEstimatesRequest] || 0),
                // custom_nutrient_estimates and nutrient_overrides are before percent eaten
                from: item.servings * item.serving_unit_amount,
                to: NUTRIENT_100G,
              });
              return acc;
            },
            {},
          ),
        })
        : item.nutrient_overrides;
      return mealItemResponseToDraftItem(item, updatedReviewerOverrides);
    }),
  };
};

const loadQueue = async (location: Location, navigate: NavigateFunction, queueItemId: string | undefined) => {
  if (!queueItemId) {
    return null;
  }

  const getDraftItems = async (
    queue: MealPhotoQueueResponse,
  ): Promise<{ mealDeleted: boolean, draftItems: DraftItem[] }> => {
    if (!queue || !queue.is_processed) {
      return {
        mealDeleted: false,
        draftItems: [],
      };
    }

    const getDraftItemsForQueueRes = await getDraftItemsForQueue(queueRes.data);
    return {
      mealDeleted: getDraftItemsForQueueRes.mealDeleted,
      draftItems: getDraftItemsForQueueRes.draftItems,
    };
  };

  const queueItemFromState = location.state?.queueItem as MealPhotoQueueResponse;
  if (queueItemFromState) {
    navigate(location.pathname, {
      replace: true,
      state: {
        ...(location.state || {}),
        queueItem: undefined,
      },
    });
  }

  if (queueItemFromState?.id?.toString() == queueItemId) {
    // TODO: figure out how to clear the state after the first time it's been
    // loaded
    delete location.state.queueItem;
    const getDraftItemsRes = await getDraftItems(queueItemFromState);
    return {
      queueItem: queueItemFromState,
      draftItems: getDraftItemsRes.draftItems,
      mealDeteted: getDraftItemsRes.mealDeleted,
    };
  }

  const queueRes = await dataReviewApi.appApiDataReviewerGetMealPhotoQueueItem({
    meal_photo_queue_id: +queueItemId,
  });
  const getDraftItemsRes = await getDraftItems(queueRes?.data);

  return {
    queueItem: queueRes.data || null,
    draftItems: getDraftItemsRes.draftItems,
    mealDeteted: getDraftItemsRes.mealDeleted,
  };
};

type LoadQueueReturn = Awaited<ReturnType<typeof loadQueue>>;

const PRELABEL_TIMEOUT = 10000;
const PRELABEL_DID_TIMEOUT = Symbol('timeout');

const queueLoadPrelabelledDraftItems = async (
  queue: LoadQueueReturn,
  queryClient: QueryClient,
): Promise<LoadQueueReturn> => {
  try {
    const res = await Promise.any([
      _queueLoadPrelabelledDraftItems(queue, queryClient),
      new Promise(resolve => {
        setTimeout(() => resolve(PRELABEL_DID_TIMEOUT), PRELABEL_TIMEOUT);
      }) as Promise<typeof PRELABEL_DID_TIMEOUT>,
    ]);
    if (res === PRELABEL_DID_TIMEOUT) {
      mixpanel.track('Queue prelabel timeout', {
        'Queue item ID': queue?.queueItem?.id,
        'Timeout': PRELABEL_TIMEOUT / 1000,
      });
      return queue;
    }
    if (!res) {
      throw new Error('Null response from _queueLoadPrelabelledDraftItems');
    }
    return res;
  } catch (e) {
    Sentry.captureException(e);
    console.error('Error loading prelabelled items:', e);
    return queue;
  }
};

const _queueLoadPrelabelledDraftItems = async (
  queue: LoadQueueReturn,
  queryClient: QueryClient,
): Promise<LoadQueueReturn> => {
  if (!queue) {
    return queue;
  }
  const detectResult = await mpqLoadImLblDetectResult(queue.queueItem);
  queryClient.setQueryData(mpqImLblQueryKey(queue.queueItem), detectResult);
  return {
    ...queue,
    draftItems: detectResult.result
      .filter(match => match.meal_item)
      .map(match =>
        imLblMatchToDraftItem({
          match,
          debugContext: {
            ...mpqImLblDetectResultToDebugContext(detectResult),
            prelabelled: true,
          },
        })
      ),
  };
};

export type SubmitMealResult = {
  success: boolean,
  accessTime?: number,
  processingTime?: number,
};

export const useNewQueueItemEditor = (opts: {
  queueItemId: string | undefined,
}) => {
  const { authInfo } = useAuth();
  const location = useLocation();
  const flags = useFeatures();
  const navigate = useNavigate();
  const mealQueueService = useMealQueueService();
  const queryClient = useQueryClient();

  const queueQuery = useQuery({
    queryKey: [opts.queueItemId],
    queryFn: async () => {
      let queue = await loadQueue(location, navigate, opts.queueItemId);
      if (!queue) {
        return queue;
      }

      if (mpqImLblShouldPrefillMeal(flags, queue.queueItem)) {
        queue = await queueLoadPrelabelledDraftItems(queue, queryClient);
      }

      return queue;
    },
    ...useQueryNeverRefetch,
  });

  const [internalState, setInternalState] = React.useState({
    queueItem: (null as any) as MealPhotoQueueResponse,
    draftItems: [] as DraftItem[],
    selectedItemOriginal: null as null | DraftItem,
    selectedItem: null as null | DraftItem,
    selectedItemIsNew: false as boolean,
    copiedItem: null as null | DraftItem,
    deletedDraftItems: [] as DraftItem[],
    mealDeleted: false as boolean,
  });
  const {
    queueItem,
    draftItems,
    selectedItemOriginal,
    selectedItem,
    selectedItemIsNew,
    copiedItem,
    deletedDraftItems,
    mealDeleted,
  } = internalState;

  const historyQuery = useQuery(['mealHistory', queueItem?.id, authInfo?.reviewer_id], async () => {
    if (!authInfo?.reviewer_id || !queueItem) {
      return null;
    }
    return getMealHistory(queueItem.data_reviewer_group_id, authInfo?.reviewer_id, queueItem.id);
  }, {
    ...useQueryNeverRefetch,
    cacheTime: 0,
  });
  const foodHistory = historyQuery.data;

  const addFoodToFoodHistoryList = (
    foodName: string,
    foodDate: any,
    foodCounts: { [foodName: string]: number },
    foodLatestDate: { [foodName: string]: string },
    foodReference: { [foodName: string]: MealItem },
    foodItem?: MealItemResponse,
  ) => {
    if (foodName in foodCounts) {
      foodCounts[foodName] += 1;
    } else {
      foodCounts[foodName] = 1;
    }
    if (foodDate > (foodLatestDate[foodName] || '')) {
      foodLatestDate[foodName] = foodDate;
      foodReference[foodName] = foodReference[foodName] = foodItem
        ? foodItem
        : foodReference[foodName];
    }
  };

  const groupedFoodHistory = React.useMemo(() => {
    const draftItemFoods = new Set((draftItems || []).map(draftItem => draftItem.item.food_name));
    const foodCounts: { [foodName: string]: number } = {};
    const foodLatestDate: { [foodName: string]: string } = {};
    const foodReference: { [foodName: string]: MealItemResponse } = {};
    foodHistory?.meals.forEach(meal => {
      meal.meal_items.forEach(mealItem => {
        const foodName = mealItem.food_name;
        addFoodToFoodHistoryList(foodName, meal.meal_date, foodCounts, foodLatestDate, foodReference, mealItem);

        mealItem.addons.forEach(addon => {
          const addonName = addon;
          addFoodToFoodHistoryList(addonName, meal.meal_date, foodCounts, foodLatestDate, foodReference);
        });

        mealItem.extra_addons?.forEach(addon => {
          const addonName = addon;
          addFoodToFoodHistoryList(addonName, meal.meal_date, foodCounts, foodLatestDate, foodReference);
        });

        mealItem.custom_addons?.forEach(addon => {
          const addonName = addon.food_name;
          const newMealItem = {
            ...getEmptyMealItem() as MealItemResponse,
            food_name: addon.food_name,
            serving_unit_label: addon.serving_unit_label,
            serving_unit_amount: addon.serving_unit_amount,
            servings: addon.servings,
          };
          addFoodToFoodHistoryList(addonName, meal.meal_date, foodCounts, foodLatestDate, foodReference, newMealItem);
        });
      });
    });

    return _(Object.entries(foodCounts))
      .filter(([foodName, count]) => !!foodReference[foodName] && isValidMealItem(foodReference[foodName]))
      .map(([foodName, count]) => ({
        foodName,
        count,
        latestDate: foodLatestDate[foodName],
        inDrafts: draftItemFoods.has(foodName),
        item: foodReference[foodName],
      }))
      .orderBy(['latestDate', 'count', 'foodName'], ['desc', 'desc', 'asc'])
      .value();
  }, [foodHistory, draftItems]);

  const getFoodHistory = React.useCallback((filter?: string) =>
    filter
      ? groupedFoodHistory.filter(f => f.foodName.toLowerCase().includes(filter.toLowerCase()))
      : groupedFoodHistory, [groupedFoodHistory]);

  const getFoodHistorySuggestions = React.useCallback((draftItem: DraftItem) => {
    const draftItemName = draftItem.item.food_name || draftItem.queryText || '';
    if (!draftItemName) {
      return {
        foodLookupName: draftItemName,
        foodHistorySuggestions: [],
      };
    }
    // This is a very crude filter; prelabelling tends to have the noun last
    // so see if there are any prefix matches with its first 5 characters and work backwards.
    // We also strip any text in brackets as that also tends not to be the main noun.
    const draftItemNameSplit = draftItemName?.replace(/ *\(.*?\) */g, '').split(' ').reverse();
    for (const word of draftItemNameSplit) {
      // Don't include other matched draft items.
      const filteredFoods = getFoodHistory(word.slice(0, 5)).filter((mi) =>
        !mi.inDrafts || (mi.inDrafts && mi.foodName === draftItemName)
      );
      // Show matched draft item first.
      if (filteredFoods) {
        return {
          foodLookupName: draftItemName,
          foodHistorySuggestions: _.sortBy(filteredFoods, mi => -mi.inDrafts),
        };
      }
    }
    return {
      foodLookupName: draftItemName,
      foodHistorySuggestions: [],
    };
  }, [getFoodHistory]);

  const getDiff = (
    existing: MealItem | MealItemCustomAddonResponse,
    current: MealItem | MealItemCustomAddonResponse,
    changeType: 'mealItem' | 'addon',
    parent?: MealItem,
  ): QueueDiff | null => {
    const nameChanged = current.food_name !== existing.food_name;
    const aliasChanged = changeType === 'mealItem'
      && (current as MealItem).food_name_alias !== (existing as MealItem).food_name_alias;
    const servingChanged = current.serving_unit_label !== existing.serving_unit_label
      || current.serving_unit_amount !== existing.serving_unit_amount
      || current.servings !== existing.servings;
    if (nameChanged || aliasChanged || servingChanged) {
      return {
        itemId: existing.id || 0,
        parentMealItem: parent,
        itemType: changeType,
        changeType: nameChanged || aliasChanged ? 'update' : 'updateSizing',
        before: existing,
        after: current,
      };
    }
    return null;
  };

  const queueDiffs = React.useMemo(() => {
    const diffs = queueItem?.existing_items?.reduce(
      (
        acc: QueueDiff[],
        existingItem,
      ) => {
        const draftItem = draftItems.find(di => di.id === existingItem.id);
        if (draftItem) {
          const mealItemDiff = getDiff(existingItem, draftItem.item, 'mealItem');
          if (mealItemDiff) {
            acc.push(mealItemDiff);
          }
          const addonDiffs = existingItem.custom_addons?.reduce((addonAcc: QueueDiff[], existingAddon) => {
            const draftItemAddon = draftItem.item.custom_addons?.find(diAddon => diAddon.id === existingAddon.id);
            if (draftItemAddon) {
              const addonDiff = getDiff(existingAddon, draftItemAddon, 'addon', draftItem.item);
              if (addonDiff) {
                addonAcc.push(addonDiff);
              }
            } else {
              addonAcc.push({
                itemId: existingAddon.id || 0,
                parentMealItem: draftItem.item,
                itemType: 'addon',
                changeType: 'delete',
                before: existingAddon,
              });
            }
            return addonAcc;
          }, []);

          const addonAddedDiffs = draftItem.item.custom_addons?.reduce((addonAddAcc: QueueDiff[], draftItemAddon) => {
            if (!draftItemAddon.id) {
              addonAddAcc.push({
                itemId: draftItemAddon.id || 0,
                parentMealItem: draftItem.item,
                itemType: 'addon',
                changeType: 'create',
                after: draftItemAddon,
              });
            }
            return addonAddAcc;
          }, []);

          // If meal item itself does not have changes but addons have changed
          const addonDiffsNoSizingChanges = addonDiffs?.filter(diff => diff.changeType !== 'updateSizing');
          if ((addonDiffsNoSizingChanges?.length || addonAddedDiffs?.length) && !mealItemDiff) {
            acc.push({
              itemId: draftItem.id || 0,
              itemType: 'mealItem',
              changeType: 'updateAddonsOnly',
              after: draftItem.item,
            });
          }
          if (addonDiffs) {
            acc.push(...addonDiffs);
          }
          if (addonAddedDiffs) {
            acc.push(...addonAddedDiffs);
          }
        } else {
          acc.push({
            itemId: existingItem.id,
            itemType: 'mealItem',
            changeType: 'delete',
            before: deletedDraftItems.find(draftItem => draftItem.id === existingItem.id)?.item,
          });
        }
        return acc;
      },
      [],
    );
    const addedItemsDiff = draftItems.reduce((acc: QueueDiff[], draftItem) => {
      if (!draftItem.id) {
        acc.push({
          itemId: 0,
          itemType: 'mealItem',
          changeType: 'create',
          after: draftItem.item,
        });
        acc.push(
          ...draftItem.item.custom_addons?.reduce((addonAddAcc: QueueDiff[], draftItemAddon) => {
            addonAddAcc.push({
              itemId: draftItemAddon.id || 0,
              parentMealItem: draftItem.item,
              itemType: 'addon',
              changeType: 'create',
              after: draftItemAddon,
            });
            return addonAddAcc;
          }, []) || [],
        );
      }
      return acc;
    }, []);
    diffs?.push(...addedItemsDiff);
    return diffs;
  }, [queueItem?.existing_items, draftItems, deletedDraftItems]);

  useEffect(() => {
    if (!selectedItem) {
      return;
    }
    console.log('Selected item:', selectedItem);
  }, [selectedItem]);

  const setDraftItems = (fn: (prevState: DraftItem[]) => DraftItem[]) => {
    setInternalState(prevState => ({
      ...prevState,
      draftItems: fn(prevState.draftItems),
    }));
  };

  const setDeletedDraftItems = (fn: (prevState: DraftItem[]) => DraftItem[]) => {
    setInternalState(prevState => ({
      ...prevState,
      deletedDraftItems: fn(prevState.deletedDraftItems),
    }));
  };

  const patientContext = usePatientContext(queueItem);

  useEffect(() => {
    setInternalState({
      queueItem: queueQuery.data?.queueItem ?? (null as any),
      draftItems: queueQuery.data?.draftItems ?? ([] as DraftItem[]),
      selectedItemOriginal: null,
      selectedItem: null,
      selectedItemIsNew: false,
      copiedItem: null,
      deletedDraftItems: [] as DraftItem[],
      mealDeleted: queueQuery.data?.mealDeteted ?? false,
    });
  }, [queueQuery.data]);

  const saveChanges = async (diffs?: QueueDiffWithReason[], reviewSessionId?: number) => {
    const patientId = patientContext.context?.patient_id;
    if (!authInfo?.access_token || !patientId || !queueItem) {
      return;
    }

    const now = new Date().toISOString();
    const reviewId = queueItem.is_processed && diffs ? reviewSessionId?.toString() ?? mkRandId(8) : null;

    await Promise.all(
      draftItems.map(async (_draftItem, idx) => {
        const draftItem = _draftItem;

        const addonDiffs = diffs?.filter(reason => reason.parentMealItem === draftItem.item);
        const addonsChangeReason = addonDiffs?.map(diff => {
          return {
            id: diff.itemId,
            food_name: diff.after?.food_name,
            change_reason: diff.reason,
          };
        });

        if (!draftItem.id) {
          const res = await mealApi.appApiMealPostMealItems({
            meal_id: queueItem.created_meal_id,
            patient_id: patientId,
            CreateMealItemRequest: [{
              extra_addons: [],
              ...draftItem.item,
              food_match_details: draftItem.foodMatchDetails ?? undefined,
              meal_push_question: draftItem.pushQuestionUpdate ?? undefined,
              change_context: reviewId
                ? {
                  review_id: reviewId,
                  review_time: now,
                  change_reason: diffs?.find(diff => diff.after === draftItem.item)?.reason,
                  addons_change_reason: addonsChangeReason,
                }
                : undefined,
            }],
          });
          const newId = res.data[0].id;
          setDraftItems(prevState =>
            prevState.map((draftItem, i) => i !== idx ? draftItem : { ...draftItem, id: newId })
          );
          return;
        }
        const res = await mealApi.appApiMealPutMealItem({
          meal_id: queueItem.created_meal_id,
          patient_id: patientId,
          meal_item_id: draftItem.id,
          UpdateMealItemRequest: {
            extra_addons: [],
            ...draftItem.item,
            food_match_details: draftItem.foodMatchDetails ?? undefined,
            meal_push_question: draftItem.pushQuestionUpdate ?? undefined,
            change_context: reviewId
              ? {
                review_id: reviewId,
                review_time: now,
                change_reason: diffs?.find(diff =>
                  diff.changeType !== 'updateAddonsOnly' && diff.after === draftItem.item
                )
                  ?.reason,
                addons_change_reason: addonsChangeReason,
              }
              : undefined,
          },
        });
      }).concat(deletedDraftItems.map(async (draftItem) => {
        if (!draftItem.id) {
          return; // Not an existing meal item
        }
        await deleteMealItem(
          patientId,
          queueItem.created_meal_id,
          draftItem.id,
          authInfo.access_token,
          reviewId
            ? {
              review_id: reviewId,
              review_time: now,
              change_reason: diffs?.find(diff => diff.before === draftItem.item)?.reason,
            }
            : undefined,
        );
        setDeletedDraftItems(prev => prev.filter(item => item.id != draftItem.id));
      })),
    );
  };

  const submitMeal = async (): Promise<SubmitMealResult> => {
    if (!authInfo || !queueItem) {
      return { success: false };
    }

    const { reviewer_id } = authInfo;
    const res = await saveQueueInternal({
      reviewerId: reviewer_id,
      queueItem,
      draftItems,
    });
    if (!res.success) {
      if (res.didAlreadySubmit) {
        mealQueueService.addRecentlyLabelledQueueId(queueItem.id);
        // note: this is done to maintain the current behavior
        return { success: true };
      }
      return res;
    }

    mealQueueService.addRecentlyLabelledQueueId(queueItem.id);
    const resp = res.queueItem;
    const first_reviewer_access_time = moment(resp.first_reviewer_access_time);
    const createdTime = moment(resp.created_time);
    const updatedTime = moment(resp.updated_time);
    const accessTime = first_reviewer_access_time.diff(createdTime);
    const accessTimeMins = moment.duration(accessTime).asMinutes();
    const processingTime = updatedTime.diff(first_reviewer_access_time);
    const processingTimeMins = moment.duration(processingTime).asMinutes();

    return {
      success: true,
      accessTime: accessTimeMins,
      processingTime: processingTimeMins,
    };
  };

  const claimQueueRes = useAsyncResult<unknown>();
  useEffect(() => {
    claimQueueRes.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queueItem]);

  const claimQueueInternal = async () => {
    if (!authInfo || !queueItem) {
      return;
    }

    const res = await dataReviewApi.appApiDataReviewerPostDataReviewerClaimQueue({
      meal_photo_queue_id: queueItem.id,
      data_reviewer_id: authInfo!.reviewer_id,
    });
    if (!res.data.ok) {
      throw new Error(res.data.message ?? 'Unknown error claiming queue');
    }
    setInternalState(previous => {
      const prevQueue = previous.queueItem;
      if (prevQueue?.id !== queueItem.id) {
        return previous;
      }
      return {
        ...previous,
        queueItem: {
          ...prevQueue,
          first_reviewer_user_id: authInfo!.reviewer_id,
          first_reviewer_access_time: (new Date()).toISOString(),
        },
      };
    });
  };

  const updateSelectedItem = (
    prevState: typeof internalState,
    newSelectedItem: DraftItem | null,
    fn?: (prevState: typeof internalState) => typeof internalState,
    opts?: { forUpdateDraftItem: boolean },
  ) => {
    const res = {
      ...prevState,
      selectedItem: newSelectedItem,
      selectedItemOriginal: newSelectedItem,
      selectedItemIsNew: false,
    };

    // Typically, if the selected item changes _and_ the old item was both new and
    // empty, it should be removed. However, if the item is being updated (ie, this
    // is a call to `updateDraftItem`), it should not be removed as it's just about
    // to be updated.
    const shouldRemoveEmptyItem = !opts?.forUpdateDraftItem
      && prevState.selectedItemIsNew
      && !prevState.selectedItem?.item?.food_name;
    if (shouldRemoveEmptyItem) {
      res.draftItems = res.draftItems.filter(i => (
        i !== prevState.selectedItem
        && i !== prevState.selectedItemOriginal
      ));
    }
    return fn ? fn(res) : res;
  };

  const _clearSelectedItem = () => {
    setInternalState(prev => updateSelectedItem(prev, null));
  };

  const snackBarShowWithUndo = (opts: {
    message: string,
    mixpanelAction: string,
  }) => {
    snackBarShow({
      message: opts.message,
      action: React.createElement(Button, {
        onClick: () => {
          snackBarShow(null);
          mixpanel.track('Queue Editor: undo', {
            Action: opts.mixpanelAction,
          });
          setInternalState(internalState);
        },
      }, ['Undo']),
    });
  };

  const removeDraftItem = (draftItem: DraftItem, opts?: { silent: boolean }) => {
    if (!opts?.silent) {
      snackBarShowWithUndo({
        message: `Removed "${formatDraftItemFoodName(draftItem)}"`,
        mixpanelAction: 'Remove item',
      });
    }

    mixpanel.track('Meal item removed', {
      'Food name': draftItem.item.food_name || draftItem.searchItem?.name || draftItem.queryText || '',
      'Food match source': draftItem.foodMatchDetails?.source_name,
      'Has prelabelling': !!draftItem.imLblMatchResult,
    });

    setInternalState(prev => {
      const newSelectedItem = prev.selectedItem === draftItem ? null : prev.selectedItem;
      return updateSelectedItem(prev, newSelectedItem, prev => ({
        ...prev,
        draftItems: prev.draftItems.filter(i => i !== draftItem),
        deletedDraftItems: (
          prev.queueItem!.is_processed && draftItem.id
            ? [...prev.deletedDraftItems, draftItem]
            : prev.deletedDraftItems
        ),
      }));
    });
  };

  const trainingReferenceMealItems = React.useMemo(() => {
    if (!queueItem?.queue_metadata?.training_reference_meal_items) {
      return [];
    }
    return queueItem.queue_metadata.training_reference_meal_items.map(mealItem => {
      return mealItemResponseToDraftItem(mealItem);
    });
  }, [queueItem?.queue_metadata]);

  const res = {
    query: queueQuery as UseQueryResult<void>,

    queueItem,
    draftItems,
    deletedDraftItems,
    trainingReferenceMealItems,

    saveChanges,
    submitMeal,

    claimQueue: () => claimQueueRes.bind(claimQueueInternal()),
    claimQueueRes,

    handleMatchItemSelect: (match: {
      matchText: string,
      mealItem: CreateMealItemRequest | null | undefined,
      relatedFoodResponse: FoodResponse | null | undefined,
      mixpanelSource: 'Meal notes' | 'Photo',
      foodMatchDetails: CreateMealItemFoodMatchDetailsRequest | null,
    }) => {
      const { matchText, mealItem, relatedFoodResponse } = match;
      const existingItem = draftItems.find(i => i.item.food_name == (mealItem?.food_name ?? matchText));
      if (existingItem) {
        res.selectedItemSet(existingItem);
        return;
      }

      if (!mealItem || !relatedFoodResponse) {
        res.selectedItemAddNewItem({
          id: null,
          item: getEmptyMealItem(),
          searchItem: null,
          queryText: matchText,
          foodMatchDetails: match.foodMatchDetails,
        });
        return;
      }

      mixpanel.track('Meal item selected', {
        Type: 'Regular item',
        Source: match.mixpanelSource,
        'Food name': mealItem.food_name,
        'Search text': matchText,
        'Add or update': 'add',
      });
      res.addDraftItem({
        id: null,
        item: {
          ...mealItem,
          custom_usda_id: null,
          custom_item_source: mealItem.custom_item_source ?? null,
          custom_item_source_id: mealItem.custom_item_source_id ?? null,
          percent_eaten: mealItem.percent_eaten ?? 1,
          servings: mealItem.servings ?? 1,
          custom_addons: mealItem.custom_addons?.map(addon => ({
            ...addon,
            food_image_url: null,
            food_name_translations: null,
            serving_unit_label_translations: null,
          })),
          food_ontology: mealItem.food_ontology ?? null,
        },
        queryText: mealItem.food_name,
        searchItem: relatedFoodResponse,
        foodMatchDetails: match.foodMatchDetails,
      });
    },

    addBulkDraftItems: (opts: {
      // Typically `replaceSelected` should be true, to remove the currently
      // selected item and replace it with the new items. However, there may
      // be cases where this is not desired.
      replaceSelected: boolean,
      items: DraftItem[],
    }) => {
      const { items } = opts;
      if (opts.replaceSelected && selectedItem) {
        removeDraftItem(selectedItem);
      }
      const newSelectedItem = items.length == 1 ? items[0] : null;
      setInternalState(prevState => {
        snackBarShowWithUndo({
          message: `Added ${pluralize(items.length, 'item')}: ${items.map(formatDraftItemFoodName).join(', ')}`,
          mixpanelAction: `Added ${pluralize(items.length, 'item')}`,
        });
        return updateSelectedItem(prevState, newSelectedItem, prevState => ({
          ...prevState,
          selectedItemIsNew: true,
          draftItems: [...prevState.draftItems, ...items],
        }));
      });
    },

    addDraftItem: (newDraftItem: DraftItem) => {
      setInternalState(prevState => {
        return updateSelectedItem(prevState, newDraftItem, prevState => ({
          ...prevState,
          draftItems: [...prevState.draftItems, newDraftItem],
        }));
      });
    },

    duplicateDraftItem: (existingDraftItem: DraftItem) => {
      setInternalState(prevState => ({
        ...prevState,
        draftItems: [...prevState.draftItems, { ...existingDraftItem, id: null }],
      }));
    },

    removeDraftItem,

    updateDraftItem: (
      oldItem: DraftItem,
      newItem: Partial<DraftItem>,
    ) => {
      setInternalState(prev => {
        const mergedNewItem = { ...oldItem, ...newItem };

        console.log('updateDraftItem', { oldItem, newItem: mergedNewItem });

        const newSelectedItem = prev.selectedItem === oldItem ? mergedNewItem : prev.selectedItem;
        return updateSelectedItem(prev, newSelectedItem, prev => ({
          ...prev,
          draftItems: prev.draftItems.map(i => i !== oldItem ? i : mergedNewItem),
        }), { forUpdateDraftItem: true });
      });
    },

    updateDraftItemMealItem: (
      draftItem: DraftItem,
      newMealItem: Partial<MealItem>,
    ) => {
      res.updateDraftItem(draftItem, {
        item: {
          ...draftItem.item,
          ...newMealItem,
        },
      });
    },

    selectedItemOriginal,
    selectedItem,
    selectedItemIsNew: internalState.selectedItemIsNew,

    /**
     * Select an existing item to edit. This will set the selected item to the
     * existing item, and will not update the draft items list until
     * `selectedItemDone` is called.
     */
    selectedItemSet: (item: DraftItem) => {
      if (!item) {
        // Note: this is to prevent bugs where the selectedItem is set to null
        // instead of calling clearSelectedItem(). This SHOULD NOT BE USED; instead,
        // call `selectedItemCancel()` or `selectedItemDone()`.
        _clearSelectedItem();
        return;
      }

      if (selectedItem === item) {
        return;
      }

      if (item && !draftItems.includes(item)) {
        throw new Error('setSelectedItem: item not in draftItems');
      }

      setInternalState(prev => updateSelectedItem(prev, item));
    },

    /**
     * Select a new item to edit. This will set the selected item to the new item
     * and add it to the draft items list, but will remove the item from the drafts
     * if `selectedItemCancel` is called.
     */
    selectedItemAddNewItem: (item: DraftItem) => {
      if (draftItems.includes(item)) {
        throw new Error('selectNewItem: item already in draftItems');
      }
      setInternalState(prev => {
        return updateSelectedItem(prev, item, prev => ({
          ...prev,
          selectedItemIsNew: true,
          draftItems: [...prev.draftItems, item],
        }));
      });
    },

    /**
     * "Cancel" editing the selected item. This will revert the item in the draft
     * items list to its original state, and clear the selected item.
     */
    selectedItemCancel: () => {
      if (selectedItem && selectedItemOriginal) {
        if (selectedItemIsNew) {
          setDraftItems(prev => prev.filter(i => i !== selectedItem));
        } else if (selectedItem != selectedItemOriginal) {
          res.updateDraftItemMealItem(selectedItem, selectedItemOriginal.item);
        }
      }

      _clearSelectedItem();
    },

    /**
     * "Done" editing the selected item. This will update the item in the draft
     * items list, and clear the selected item.
     */
    selectedItemDone: () => {
      if (!selectedItem) {
        throw new Error('selectedItemDone: no item selected');
      }

      if (selectedItem != selectedItemOriginal) {
        res.updateDraftItem(selectedItem, {
          item: {
            ...selectedItem.item,
            custom_usda_id: null,
          },
        });
      }

      _clearSelectedItem();
    },

    /**
     * Adds `addonItem` as an addon to `targetItem`.
     *
     * Notes:
     * - The `addonItem` must not be a `custom_item`. This function will fail with
     *   an alert and error logged to Sentry if the `addonItem` is a `custom_item`.
     * - Unless `keepMealItemSize: true`, the size of `addonItem` will be cleared,
     *   and the user will be required to re-enter the size. This is because, at
     *   the moment, items are typically sizes as meal items, not addons, so the
     *   size likely won't make sense (ex, if NLP matches "milk", it will be sized
     *   as "1 cup" (meal item size), but if it's an addon, it should be "2
     *   tbsp").
     * - If the meal itme has a `custom_addons` field, the existing addons will be
     *   added to the new item.
     */
    addItemAsAddon: (opts: {
      targetItem: DraftItem,
      addonMealItem: MealItem,
      focusTargetItem?: boolean,
      keepMealItemSize?: boolean,
    }) => {
      const { targetItem, addonMealItem } = opts;
      if (addonMealItem.custom_item) {
        const err = new Error('Attempt to add custom item as addon');
        if (config.IS_LOCAL) {
          throw err;
        }
        alert('Programming error: attempt to add custom item as addon (error tracked)');
        Sentry.captureException(err);
        return;
      }

      snackBarShowWithUndo({
        message: `Added "${addonMealItem.food_name}" as an addon to "${formatDraftItemFoodName(targetItem)}"`,
        mixpanelAction: 'Convert to addon',
      });

      const zeroSize = !opts.keepMealItemSize;
      const newAddon: MealItemCustomAddonResponse = {
        ...addonMealItem,
        id: undefined,
        serving_unit_label: zeroSize ? '' : addonMealItem.serving_unit_label,
        servings: zeroSize ? 0 : addonMealItem.servings,
        food_name_translations: {},
        serving_unit_label_translations: {},
        food_image_url: null,
      };

      if (opts.focusTargetItem ?? true) {
        res.selectedItemSet(targetItem);
      }
      res.updateDraftItem(targetItem, {
        ...targetItem,
        item: {
          ...targetItem.item,
          custom_addons: [
            ...targetItem.item.custom_addons ?? [],
            newAddon,
            ...(addonMealItem.custom_addons ?? []),
          ],
        },
      });
    },
    addAddonAsItem: (opts: { addonItem: MealItemCustomAddonResponse, insertBefore?: DraftItem }) => {
      const { addonItem: addon, insertBefore } = opts;
      snackBarShowWithUndo({
        message: `Converted "${addon.food_name}" to meal item`,
        mixpanelAction: 'Convert addon to meal item',
      });
      const newMealItem = {
        ...getEmptyMealItem(),
        food_name: addon.food_name,
        serving_unit_label: addon.serving_unit_label,
        serving_unit_amount: addon.serving_unit_amount,
        servings: addon.servings,
      };
      const newDraftItem = {
        id: null,
        item: newMealItem,
        searchItem: null,
        queryText: addon.food_name,
        foodMatchDetails: null,
      };
      setInternalState(prevState => {
        const insertAt = insertBefore
          ? prevState.draftItems.findIndex((di) => {
            return ((di.id !== null && di.id === insertBefore.id) || _.isEqual(di, insertBefore));
          })
          : prevState.draftItems.length;
        return ({
          ...prevState,
          draftItems: [
            ...prevState.draftItems.slice(0, insertAt),
            newDraftItem,
            ...prevState.draftItems.slice(insertAt),
          ],
        });
      });
    },
    addAddon: (opts: {
      targetItem: DraftItem,
      addonItem: MealItemCustomAddonResponse,
    }) => {
      const { targetItem, addonItem } = opts;
      snackBarShowWithUndo({
        message: `Added addon "${addonItem.food_name}" to "${formatDraftItemFoodName(targetItem)}"`,
        mixpanelAction: 'Add addon',
      });
      res.updateDraftItem(targetItem, {
        ...targetItem,
        item: {
          ...targetItem.item,
          // clear addonItem id when moved to a new meal item
          custom_addons: [...(targetItem.item.custom_addons || []), { ...addonItem, id: undefined }],
        },
      });
    },
    removeAddon: (opts: {
      sourceItem: DraftItem,
      addonItem: MealItemCustomAddonResponse,
      silent?: boolean,
    }) => {
      const { sourceItem, addonItem } = opts;
      if (!opts.silent) {
        snackBarShowWithUndo({
          message: `Removed addon "${addonItem.food_name}" from "${formatDraftItemFoodName(sourceItem)}"`,
          mixpanelAction: 'Remove addon',
        });
      }
      res.updateDraftItem(sourceItem, {
        ...sourceItem,
        item: {
          ...sourceItem.item,
          custom_addons: sourceItem.item.custom_addons?.filter(addon => addon !== addonItem),
        },
      });
    },
    copiedItem,
    setCopiedItem: (item: DraftItem | null) => {
      mixpanel.track('Meal item copied', {
        'Item name': item?.item.food_name,
      });
      snackBarShow({
        message: `Copied "${formatDraftItemFoodName(item)}"`,
      });
      setInternalState(prev => ({
        ...prev,
        copiedItem: item,
      }));
    },

    mealDeleted,
    getFoodHistorySuggestions,

    queueDiffs,

    toggleItemDoneStatus: (opts: {
      draftItem: DraftItem,
    }) => {
      res.updateDraftItem(opts.draftItem, {
        ...opts.draftItem,
        isMarkedDoneByUser: !opts.draftItem.isMarkedDoneByUser,
      });
    },

    isAllItemsMarkedDoneByUser: () => {
      return res.draftItems.every(i => i.isMarkedDoneByUser);
    },
  };

  return res;
};

export type QueueItemEditorState = ReturnType<typeof useNewQueueItemEditor>;
export const QueueItemEditorStateCtx = React.createContext<QueueItemEditorState>({} as any);

export const useQueueItemEditor = () => {
  const ctx = React.useContext(QueueItemEditorStateCtx);
  if (!ctx) {
    throw new Error('useQueueItemEditor must be used within a FoodSearchProvider');
  }
  return ctx;
};

const parseKeyNutrients = (nutrientsStr: string | null | undefined) => {
  return (
    (nutrientsStr || '')
      .split(',')
      .map((x) => (x.trim() == 'default_carbs_g' ? 'carbohydrate_g' : x.trim()))
      .filter((x) => !!x)
  );
};

export type RelevantNutrients = ReturnType<typeof useRelevantNutrients>;

export const useRelevantNutrients = (opts: {
  context: 'item' | 'summary',
  patientContext?: PatientContext,
  // If `showAllMacros` is set, all macronutrients will be shown, regardless of
  // the patient's flags.
  showAllMacros?: boolean,
  // If `includeNutrients` is set, these nutrients will be included in the list
  // of relevant nutrients, regardless of the patient's flags.
  includeNutrients?: string[],
}) => {
  const { queueItem } = useQueueItemEditor();
  const patientContext = usePatientContext(queueItem);

  const { context } = opts;
  const flags = opts.patientContext
    ? opts.patientContext.context?.patient_flags
    : patientContext.context?.patient_flags;
  const showAllMacros = opts.showAllMacros ? opts.showAllMacros : false;
  const keyNutrients = flags?.patient_app_show_macro_nutrition_card
    ? parseKeyNutrients(flags?.patient_app_key_nutrients)
    : [];
  const showCarbs = flags?.patient_show_carbs ?? flags?.patient_show_total_carbs
    ?? !!keyNutrients.find(n => n.indexOf('carbohydrate_g') >= 0 || n.indexOf('fiber_g') >= 0) ?? false;
  const showCals = flags?.patient_show_cals ?? false;

  const relevantNutrients = [
    {
      nutrient: 'carbohydrate_g',
      patientVisible: context == 'summary' || showCarbs || showAllMacros,
    },
    {
      nutrient: 'fiber_g',
      patientVisible: showCarbs || showAllMacros,
    },
    {
      nutrient: 'polyols_g',
      patientVisible: showCarbs || showAllMacros,
    },
    {
      nutrient: 'netcarb_g',
      patientVisible: context == 'summary' || showCarbs || showAllMacros,
    },
    {
      nutrient: 'protein_g',
      patientVisible: showAllMacros,
    },
    {
      nutrient: 'fat_g',
      patientVisible: showAllMacros,
    },
    {
      nutrient: 'energy_kcal',
      patientVisible: showCals || showAllMacros,
    },
  ] as {
    nutrient: string,
    patientVisible?: boolean,
    overrideVisible?: boolean,
  }[];

  keyNutrients.forEach(n => {
    const cur = relevantNutrients.find(i => i.nutrient == n);
    if (cur) {
      cur.patientVisible = true;
      return;
    }
    relevantNutrients.push({ nutrient: n, patientVisible: true });
  });

  opts.includeNutrients?.forEach(n => {
    const cur = relevantNutrients.find(i => i.nutrient == n);
    if (cur) {
      cur.overrideVisible = true;
      return;
    }
    relevantNutrients.push({ nutrient: n, overrideVisible: true });
  });

  return React.useMemo(
    () => {
      return relevantNutrients
        .filter(n => !flags || n.patientVisible || n.overrideVisible)
        .map(n => ({
          ...nutrientGetDef(n.nutrient as any),
          patientVisible: n.patientVisible,
          overrideVisible: n.overrideVisible,
        })) as (NutrientDef & { patientVisible?: boolean, overrideVisible?: boolean })[];
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [flags, showAllMacros],
  );
};

export const usePushQuestions = (opts: {
  patientId: number,
  mealId?: number,
  itemId?: number,
}) => {
  const { patientId, mealId, itemId } = opts;
  const queryClient = useQueryClient();

  const pushQuestionsQuery = useQuery(['push-questions', patientId, mealId], async () => {
    if (!mealId) {
      return [];
    }
    const pqRes = await mealPushQuestionApi.appApiMealPushQuestionGetMealPushQuestions({
      patient_id: patientId,
      meal_id: mealId,
    }).then(res => res.data);

    return pqRes.filter(pq => pq.question_status != MealPushQuestionStatusEnum.Deleted);
  }, {
    enabled: !!mealId,
  });

  const createPushQuestionMutation = useMutation<
    MealPushQuestionResponse,
    Error,
    UpdateMealPushQuestionRequest
  >(
    async (pqRequest) => {
      if (!mealId) {
        throw new Error('Cannot create push question without mealId');
      }
      const res = await mealPushQuestionApi.appApiMealPushQuestionPostMealPushQuestion({
        patient_id: patientId,
        meal_id: mealId,
        UpdateMealPushQuestionRequest: pqRequest,
      });
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['push-questions', patientId, mealId] });
      },
    },
  );

  const updatePushQuestionMutation = useMutation<
    MealPushQuestionResponse,
    Error,
    { questionId: number, pqRequest: UpdateMealPushQuestionRequest }
  >(
    async ({ questionId, pqRequest }) => {
      if (!mealId) {
        throw new Error('Cannot create push question without mealId');
      }
      const res = await mealPushQuestionApi.appApiMealPushQuestionPutMealPushQuestion({
        patient_id: patientId,
        meal_id: mealId,
        question_id: questionId,
        UpdateMealPushQuestionRequest: pqRequest,
      });
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['push-questions', patientId, mealId] });
      },
    },
  );

  return React.useMemo(() => {
    return {
      query: pushQuestionsQuery,
      mealQuestions: pushQuestionsQuery.data ?? [],
      itemQuestion: pushQuestionsQuery.data?.find(pq => pq.meal_item_id == itemId) ?? null,
      // create: createPushQuestionMutation,
      // update: updatePushQuestionMutation,
    };
    // pushQuestionsQuery is not stable, we only want to update when data is available
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId, pushQuestionsQuery.isSuccess]);
};

export const formatPercent = (decimal: number | undefined, digits: number = 0) => {
  if (decimal === null || decimal != decimal || decimal === undefined) {
    return '100%';
  }
  return `${(decimal * 100).toFixed(digits)}%`;
};
