import {
  getItemWeightWithoutAddons,
  getMealTotalWeight,
  MealSummary,
  NUTRIENT_WARNING_THRESHOLDS,
  NUTRIENT_WARNING_WEIGHT_1G,
  SummaryTable,
  useMealNutrientValues,
} from 'pages/QueueItem/meal-builder/MealSummary';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Alert,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  Modal,
  OutlinedInput,
  Stack,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
} from '@mui/material';
import IconButton from 'components/@extended/IconButton';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import { PatientAgeValue, PatientDiet } from 'pages/QueueItem/meal-builder/PatientAge';

import { type MealQueueItem, onSendHelpCallback, sendHelpRequest } from 'apiClients/mpq';
import { useAuth } from 'context/appContext';

import { LeftOutlined } from '@ant-design/icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { formatNumber } from 'food-editor/utils/utils';
import { InteractiveMealTable } from 'pages/QueueItem/meal-builder/InteractiveMealTable';
import {
  draftItemGetWarnings,
  formatMealItemSizing,
  MealBuilderActions,
} from 'pages/QueueItem/meal-builder/MealBuilder';
import { MealPhoto } from 'pages/QueueItem/meal-builder/MealPhoto';
import { usePatientContext } from 'pages/QueueItem/meal-builder/usePatientContext';
import {
  getEmptyMealItem,
  QueueItemEditorStateCtx,
  SubmitMealResult,
  useNewQueueItemEditor,
  usePushQuestions,
  useQueueItemEditor,
} from 'pages/QueueItem/services/QueueItemEditorService';
import '../../App.css';
import {
  Add,
  AddCircle,
  Biotech,
  Block,
  ChangeCircle,
  Check,
  CheckBox,
  CheckBoxOutlineBlank,
  CheckCircleOutline,
  Cottage,
  Fastfood,
  Help,
  Info,
  LocalGroceryStore,
  NewReleases,
  Pages,
  PlayArrow,
  RemoveCircle,
  Report,
  SearchOutlined,
  SoupKitchen,
} from '@mui/icons-material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { dataReviewApi } from 'api';
import {
  MealPushQuestionStatusEnum,
  MealResponse,
  QA2ChangeReason,
  QualityAssuranceSessionResponseStatusEnum,
} from 'api/generated/MNT';
import { pluralize } from 'async-result/utils';
import { Capabilities } from 'auth-capabilities';
import MainCard from 'components/MainCard';
import { MealNoteSelection, MealNoteView, useNlpPhraseParser } from 'components/MealNoteView';
import { MealQALogs } from 'components/MealQALogs';
import { PatientIDCondensed } from 'components/PatientID';
import { QueuePriorityIcon } from 'components/QueueSummaryIcons';
import { useMealQALogs } from 'components/useMealQALogs';
import { logTrackedError } from 'errorTracking';
import { EDITOR_BUTTON_THEME } from 'food-editor/components/food-editor';
import { HumanTime, useInterval } from 'food-editor/components/HumanTime';
import { usePromptBeforePageUnload } from 'hooks/usePromptBeforePageUnload';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import _ from 'lodash';
import { QueueReviewSessionHeader } from 'pages/InitialQA';
import { MealChangeLogs, MealQoSDetails } from 'pages/LegacyReviewItem';
import { Form } from 'react-bootstrap';
import { useAsyncResult } from 'react-use-async-result';
import { useFoodResponses } from 'services/FoodDetailsService';
import { useUserQualityAssuranceSession } from 'services/UserQualityAssuranceService';
import { DraftItem, MealItemFoodMatchDetailsNLP, QueueDiff, QueueDiffWithReason } from 'types/DraftItem';
import { useEventListener } from 'usehooks-ts';
import { getMaskedClinicType } from 'utils';
import { mealHistoryItemToMealItem } from 'utils/mealItems';
import { telemetrySend } from 'utils/telemetry';
import { FoodDrawerProvider, useFoodDrawerState, usePatientMealHistory } from './meal-builder/FoodDrawer';
import { MealName, MealTimeCondensed } from './meal-builder/MealNameAndTime';
import { RecommendedMeals } from './meal-builder/RecommendedMeals';
import { useRelevantNutrients } from './services/QueueItemEditorService';

type QueueSubmitAction = 'submit' | 'approve' | 'escalate';

const MealConfirmNutrientsDialog = (props: {
  open: boolean,
  mixpanelProps: Record<string, any>,
  onClose: () => void,
  onSubmit: () => void,
}) => {
  const { draftItems, queueItem } = useQueueItemEditor();
  const patientContext = usePatientContext(queueItem);
  const mixpanelProps = {
    'Meal ID': queueItem.created_meal_id,
    'Queue ID': queueItem.id,
    ...(props.mixpanelProps || {}),
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        mixpanel.track('Meal nutrient confirmation: result', {
          ...mixpanelProps,
          Action: 'Cancel',
        });
        props.onClose();
      }}
      fullWidth
      disableScrollLock
      hideBackdrop={true}
      PaperProps={{
        elevation: 24,
        sx: {
          position: 'fixed',
          right: '5%',
          width: '40%',
          maxWidth: 800,
          height: '90%',
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h3">Verify Meal Nutrients</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please confirm the following information are correct. The patient will see them <b>immediately</b>{' '}
          in their app.
        </DialogContentText>
        <MealSummary
          queue={queueItem}
          patientContext={patientContext}
          draftItems={draftItems}
          isForConfirmationDialog
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            mixpanel.track('Meal nutrient confirmation: result', {
              ...mixpanelProps,
              Action: 'Modify',
            });
            props.onClose();
          }}
        >
          Modify Meal
        </Button>
        <Button
          onClick={() => {
            mixpanel.track('Meal nutrient confirmation: result', {
              ...mixpanelProps,
              Action: 'Confirm',
            });
            props.onSubmit();
          }}
        >
          Confirm and Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EscalationDetailsDialog = (props: {
  open: boolean,
  onClose: () => void,
  onSubmit: () => void,
}) => {
  const { authInfo } = useAuth();
  const { queueItem } = useQueueItemEditor();
  const [reason, setReason] = useState<string>('');
  const [otherExplanation, setOtherExplanation] = useState<string>('');

  const escalationOptions = [
    {
      value: 'unrecognized_meal_item',
      label: (
        <Typography>
          I <b>don't recognize</b> a meal item
        </Typography>
      ),
    },
    {
      value: 'too_complex',
      label: (
        <Typography>
          This is <b>too complex</b> for me
        </Typography>
      ),
    },
    {
      value: 'higher_priority',
      label: (
        <Typography>
          Working on a <b>higher priority queue</b>
        </Typography>
      ),
    },
    {
      value: 'other',
      label: (
        <Typography>
          Other
        </Typography>
      ),
    },
  ];

  const handleSubmit = async () => {
    if (!reason) {
      return;
    }
    const escalationReason = `${reason}${otherExplanation ? `: ${otherExplanation}` : ''}`;
    const res = await dataReviewApi.appApiDataReviewerEscalateMealPhotoQueueItem({
      meal_photo_queue_id: queueItem.id,
      reason: escalationReason,
    });
    mixpanel.track('Escalate meal', {
      'Meal ID': queueItem.created_meal_id,
      'Queue ID': queueItem.id,
      'Queue Level': queueItem.queue_metadata?.review_effective_level || 0,
      'Reviewer': authInfo?.reviewer_id,
      'Reviewer Level': authInfo?.review_max_queue_level || 0,
      'Reason': escalationReason,
    });
    props.onSubmit();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth
      disableScrollLock
      hideBackdrop={true}
      PaperProps={{
        elevation: 24,
        sx: {
          position: 'fixed',
          right: '5%',
          width: '40%',
          maxWidth: 800,
          height: '60%',
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h3">Escalation Details</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: 2 }}>
          Please provide details on why you're escalating this queue.
        </DialogContentText>
        <ThemeProvider theme={EDITOR_BUTTON_THEME}>
          <Grid
            container
            spacing={1}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            {escalationOptions.map((option) => (
              <Grid item key={option.value} xs={3}>
                <Button
                  variant="contained"
                  color={reason === option.value ? 'success' : 'primary'}
                  onClick={() => {
                    setReason(option.value);
                  }}
                  disableElevation
                  disableRipple
                >
                  {option.label}
                </Button>
              </Grid>
            ))}
            {reason === 'other' && (
              <TextField
                margin="dense"
                id="other_details"
                name="other_details"
                label="Escalation reason"
                type="text"
                fullWidth
                variant="standard"
                onChange={(e) => setOtherExplanation(e.target.value)}
              />
            )}
          </Grid>
        </ThemeProvider>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!reason}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const QueueTimerChip = (props: { queueItem: MealQueueItem, size?: 'small' | 'medium' }) => {
  const { queueItem } = props;
  const [timeMinutes, setTimeMinutes] = useState(0);

  const refreshTime = () => {
    const time = queueItem.first_reviewer_access_time || queueItem.created_time;
    setTimeMinutes(moment().diff(moment(time), 'minutes'));
  };
  useEffect(refreshTime, [queueItem]);
  useInterval(1000, refreshTime);

  const color = timeMinutes > 6 ? 'error' : timeMinutes > 3 ? 'warning' : 'success';

  return <Chip variant="combined" color={color} label={pluralize(timeMinutes, 'minute') + ' old'} size={props.size} />;
};

type PostProcQuestionnaireQuestion = {
  id: string,
  label: string | JSX.Element,
  subQuestions?: Array<PostProcQuestionnaireQuestion>,
};

const PostProcessingQuestionnaire = (props: {
  header: JSX.Element,
  subheader: JSX.Element,
  questions: Array<PostProcQuestionnaireQuestion>,
  onValuesChange: (values: { [key: string]: any }) => void,
}) => {
  const [formValues, setFormValues] = useState<{ [key: string]: any }>({});

  const onQuestionnaireQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.checked,
    });
  };
  useEffect(() => {
    props.onValuesChange?.(formValues);
  }, [formValues]);

  let counter = 0;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="body1">
          {props.header}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={0.2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              {props.subheader}
            </Typography>
          </Grid>
          {props.questions.map((question, idx) => (
            <>
              <Grid
                item
                xs={12}
                key={question.id}
                style={{
                  paddingTop: 5,
                  marginLeft: -10,
                  paddingLeft: 10,
                  backgroundColor: ++counter % 2 == 0 ? '#f0f0f0' : undefined,
                }}
              >
                <Form.Group controlId={question.id}>
                  <Form.Check>
                    <Form.Check.Input
                      name={question.id}
                      onChange={onQuestionnaireQuestionChange}
                    />
                    <Form.Check.Label
                      style={{
                        maxWidth: idx == 0 ? 'calc(100% - 110px)' : '100%',
                      }}
                    >
                      {question.label}
                    </Form.Check.Label>
                  </Form.Check>
                </Form.Group>
              </Grid>
              {question.subQuestions?.map(subQuestion => (
                <Grid
                  item
                  xs={12}
                  key={question.id + ':' + subQuestion.id}
                  style={{
                    paddingTop: 5,
                    marginLeft: -10,
                    paddingLeft: 10,
                    backgroundColor: ++counter % 2 == 0 ? '#f0f0f0' : undefined,
                  }}
                >
                  <Form.Group controlId={question.id + ':' + subQuestion.id} style={{ marginLeft: 20 }}>
                    <Form.Check>
                      <Form.Check.Input
                        name={question.id + ':' + subQuestion.id}
                        onChange={onQuestionnaireQuestionChange}
                      />
                      <Form.Check.Label
                        style={{
                          maxWidth: idx == 0 ? 'calc(100% - 110px)' : '100%',
                        }}
                      >
                        {subQuestion.label}
                      </Form.Check.Label>
                    </Form.Check>
                  </Form.Group>
                </Grid>
              ))}
            </>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Form.Group controlId="other" style={{ marginTop: 10 }}>
          <Form.Control
            as="textarea"
            rows={3}
            onChange={e => setFormValues({ ...formValues, other: e.target.value })}
            placeholder="Any other comments to help the Operations team improve the labeling process?"
          />
        </Form.Group>
      </Grid>
    </Grid>
  );
};

const PostProcessingAccessTimeQuestionnaire = (props: {
  accessTimeMinutes: number,
  onValuesChange: (values: { [key: string]: any }) => void,
}) => {
  const questions: Array<PostProcQuestionnaireQuestion> = [
    {
      id: 'processing_other_queue',
      label: (
        <span>
          I was <strong>processing another queue</strong>
        </span>
      ),
    },
    {
      id: 'no_assistance',
      label: (
        <span>
          I <strong>did not receive assistance</strong> from the team on shift because...
        </span>
      ),
      subQuestions: [
        {
          id: 'technical_issue',
          label: (
            <span>
              ... they had <strong>a technical issue</strong>
            </span>
          ),
        },
        {
          id: 'team_absent',
          label: (
            <span>
              ... they were <strong>absent</strong>
            </span>
          ),
        },
        {
          id: 'not_attentive',
          label: (
            <span>
              ... they were <strong>not attentive</strong>
            </span>
          ),
        },
      ],
    },

    {
      id: 'other_task',
      label: (
        <span>
          I was focused on a <strong>higher priority task</strong> (please mention in the notes)
        </span>
      ),
    },
    {
      id: 'afk',
      label: (
        <span>
          I was <strong>away from the computer</strong>, and...
        </span>
      ),
      subQuestions: [
        {
          id: 'asked_team_to_cover',
          label: (
            <span>
              ... had asked the <strong>team to cover for me</strong>
            </span>
          ),
        },
        {
          id: 'no_one_to_cover',
          label: (
            <span>
              ... <strong>nobody was available</strong> to cover for me
            </span>
          ),
        },
        {
          id: 'unable_to_ask_team',
          label: (
            <span>
              ... I was <strong>not able to ask the team</strong> to cover for me
            </span>
          ),
        },
      ],
    },
    {
      id: 'technical_issue',
      label: (
        <span>
          I experienced a <strong>technical issue</strong>
        </span>
      ),
      subQuestions: [
        {
          id: 'not_loading',
          label: (
            <span>
              ... because the <strong>page was not loading</strong>
            </span>
          ),
        },
      ],
    },
  ];

  return (
    <PostProcessingQuestionnaire
      onValuesChange={props.onValuesChange}
      header={
        <>
          This queue took more than {POST_PROC_QUESTIONNAIRE_ACCESS_TIME_MINUTES} minutes to{' '}
          <strong>access</strong>. To help the Operations team understand how the labeling process can be improved,
          please answer the following questions.
        </>
      }
      subheader={
        <>
          This queue took <strong>{props.accessTimeMinutes.toFixed(1)}</strong> minutes to <strong>access</strong>{' '}
          because...
        </>
      }
      questions={questions}
    />
  );
};

const PostProcessingProcTimeQuestionnaire = (props: {
  procTimeMinutes: number,
  isL1Queue?: boolean,
  onValuesChange: (values: { [key: string]: any }) => void,
}) => {
  const questions: Array<PostProcQuestionnaireQuestion> = [
    {
      id: 'many_items',
      label: (
        <span>
          The meal contained <strong>many items</strong>
        </span>
      ),
    },
    {
      id: 'difficult_identification',
      label: (
        <span>
          Some item(s) were <strong>difficult to identify</strong>
        </span>
      ),
      subQuestions: [
        {
          id: 'bad_photo',
          label: (
            <span>
              ... because the <strong>photo is bad</strong> (ex, blurry, too dark, etc)
            </span>
          ),
        },
        {
          id: 'unfamiliar_foods',
          label: (
            <span>
              ... because the meal contains <strong>unfamiliar food(s)</strong> (ie, food you have never seen before)
            </span>
          ),
        },
        {
          id: 'ambiguous_foods',
          label: (
            <span>
              ... because some <strong>food(s) are ambiguous</strong> (ex, pork and chicken can look very similar)
            </span>
          ),
        },
      ],
    },
    {
      id: 'difficult_sizing',
      label: (
        <span>
          Some item(s) were <strong>difficult to size</strong>
        </span>
      ),
    },
    {
      id: 'research_required',
      label: (
        <span>
          <strong>Research was required</strong> to identify or size some item(s)
        </span>
      ),
    },
    {
      id: 'interrupted',
      label: (
        <span>
          I was <strong>interrupted</strong> with a higher priority task
        </span>
      ),
    },
    {
      id: 'training_approval',
      label: (
        <span>
          I am <strong>in training</strong> and needed to wait for approval
        </span>
      ),
    },
    {
      id: 'technical_issue',
      label: (
        <span>
          I experienced a <strong>technical issue</strong> (please make a note below)
        </span>
      ),
    },
  ];

  return (
    <PostProcessingQuestionnaire
      onValuesChange={props.onValuesChange}
      header={
        <>
          This queue took more than {props.isL1Queue
            ? POST_PROC_QUESTIONNAIRE_PROC_TIME_MINUTES_L1 + (' (L1)')
            : POST_PROC_QUESTIONNAIRE_PROC_TIME_MINUTES} minutes to{' '}
          <strong>process</strong>. To help the Operations team understand how the labeling process can be improved,
          please answer the following questions.
        </>
      }
      subheader={
        <>
          This queue took <strong>{props.procTimeMinutes.toFixed(1)}</strong> minutes to <strong>process</strong>{' '}
          because...
        </>
      }
      questions={questions}
    />
  );
};

const PostProcessingQuestionnairesModal = (props: {
  queueItem: MealQueueItem,
  queueSubmitResponse: SubmitMealResult | null,
  onSubmit: () => void,
}) => {
  const { authInfo } = useAuth();
  const { draftItems } = useQueueItemEditor();
  const telemetryRef = React.useRef({
    windowFocusCount: 0,
    mouseDownCount: 0,
    keyDownCount: 0,
    requestHelpCount: 0,
    requestHelpMessages: [] as string[],
  });
  useEventListener('mousedown', () => {
    telemetryRef.current.mouseDownCount += 1;
  });
  useEventListener('keydown', () => {
    telemetryRef.current.keyDownCount += 1;
  });
  useEventListener('focus', () => {
    telemetryRef.current.windowFocusCount += 1;
  });

  useEffect(() => {
    onSendHelpCallback.callback = (message) => {
      telemetryRef.current.requestHelpCount += 1;
      telemetryRef.current.requestHelpMessages.push(message);
    };
    return () => {
      onSendHelpCallback.callback = null;
    };
  }, []);

  const [formStack, setFormStack] = useState([] as ('AccessTime' | 'ProcTime')[]);
  useEffect(() => {
    if (props.queueItem.queue_metadata?.is_training) {
      return;
    }

    const newFormStack = [] as typeof formStack;
    if ((props.queueSubmitResponse?.accessTime ?? 0) > POST_PROC_QUESTIONNAIRE_ACCESS_TIME_MINUTES) {
      newFormStack.push('AccessTime');
    }

    if (
      (props.queueSubmitResponse?.processingTime ?? 0) > POST_PROC_QUESTIONNAIRE_PROC_TIME_MINUTES
      || (props.queueItem.queue_metadata?.review_effective_level === 1
        && (props.queueSubmitResponse?.processingTime ?? 0) > POST_PROC_QUESTIONNAIRE_PROC_TIME_MINUTES_L1)
    ) {
      newFormStack.push('ProcTime');
    }
    setFormStack(newFormStack);
  }, [props.queueSubmitResponse, props.queueItem.queue_metadata]);
  const modalVisible = formStack.length > 0;

  const [formValues, setFormValues] = useState<{ [key: string]: any }>({});
  const startTimeRef = React.useRef(0);
  useEffect(() => {
    if (!modalVisible) {
      return;
    }
    startTimeRef.current = Date.now();
  }, [modalVisible]);

  const submitRes = useAsyncResult();

  const onSubmit = () => {
    const curForm = formStack[0];
    if (!curForm) {
      props.onSubmit();
      return;
    }

    const submitValue = {
      queueId: props.queueItem.id,
      formDurationSeconds: (Date.now() - startTimeRef.current) / 1000,
      mealItemCount: draftItems.length,
      addonCount: _.sum(draftItems.map(i => i.item.custom_addons?.length || 0)),
      customItemCount: draftItems.filter(i => i.item.custom_item).length,
      ...telemetryRef.current,
      form: formValues,
    };
    let telemetryValue: number = 0;
    if (curForm == 'AccessTime') {
      Object.assign(submitValue, {
        accessTimeThresholdSeconds: POST_PROC_QUESTIONNAIRE_PROC_TIME_MINUTES * 60,
        accessTimeSeconds: (props?.queueSubmitResponse?.accessTime ?? 0) * 60,
      });
      telemetryValue = (props?.queueSubmitResponse?.accessTime ?? 0) * 60;
    } else if (curForm == 'ProcTime') {
      Object.assign(submitValue, {
        // Note: maintain this "type" for backwards compatibility, but new code
        // should not use it.
        type: 'post_proc_questionnaire',
        procTimeThresholdSeconds: (props.queueItem.queue_metadata?.review_effective_level === 1
          ? POST_PROC_QUESTIONNAIRE_PROC_TIME_MINUTES_L1
          : POST_PROC_QUESTIONNAIRE_PROC_TIME_MINUTES) * 60,
        procTimeSeconds: (props?.queueSubmitResponse?.processingTime ?? 0) * 60,
      });
      telemetryValue = (props?.queueSubmitResponse?.processingTime ?? 0) * 60;
    }
    const res = telemetrySend({
      name: `QueuePostProcQuestionnaire:${curForm}`,
      key: `${props.queueItem.id}:${authInfo?.reviewer_id}`,
      value: telemetryValue,
      properties: submitValue,
    });

    submitRes.bind(res);
    res.then(() => {
      if (formStack.length == 1) {
        props.onSubmit();
        return;
      }
      setFormStack(formStack.slice(1));
      setFormValues({});
    });
  };

  const curForm = formStack[0];

  if (!curForm) {
    return null;
  }

  return (
    <Dialog open={modalVisible} maxWidth="sm">
      <DialogTitle>
        <strong>{curForm == 'AccessTime' ? 'Access' : 'Processing'}</strong> Time Questionnaire
      </DialogTitle>

      <DialogContent>
        {curForm == 'AccessTime' && (
          <PostProcessingAccessTimeQuestionnaire
            accessTimeMinutes={props.queueSubmitResponse?.accessTime ?? 0}
            onValuesChange={setFormValues}
          />
        )}
        {curForm == 'ProcTime' && (
          <PostProcessingProcTimeQuestionnaire
            procTimeMinutes={props.queueSubmitResponse?.processingTime ?? 0}
            onValuesChange={setFormValues}
            isL1Queue={props.queueItem.queue_metadata?.review_effective_level === 1}
          />
        )}

        {submitRes.isError && (
          <Alert severity="error">
            Submit error: {'' + submitRes.error}
          </Alert>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={onSubmit} disabled={submitRes.isPending}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

const QUEUE_DIFF_REASONS = [
  {
    label: 'identification',
    title: 'Identification miss',
    description: 'A food in the photo or text was completely missed or a food was mistakenly added '
      + 'that is not in the photo or text.',
    subcategories: [
      {
        label: 'missing',
        title: 'Missing',
        description: 'Item can be identified or inferred from the photo, note, history, or '
          + 'general composition but was not added to the queue.',
      },
      {
        label: 'duplicate',
        title: 'Duplicate',
        description: 'Item is repeated individually or is already part of an existing item and '
          + 'should be removed.',
      },
      {
        label: 'wrong',
        title: 'Wrong',
        description: 'Items of similar or greater specificity are incorrectly identified for as '
          + 'an item of similar specificity within the same general ontology or of a different '
          + 'brand in the same ontology. e.g. "croissant" changed to "danish", "dempster\'s whole '
          + 'wheat bread" changed to "dave\'s killer bread", "cake pop" changed to "roasted rack '
          + 'of lamb", "brown rice" changed to "quinoa".\n\nNote: Items belonging in a similar '
          + 'grouping or are defining higher specificity should be selected as "Specificity" '
          + 'error. e.g. "bread" changed to "stonemill sourdough bread" (belongs to same ontology '
          + 'and is more specific, both are "breads").',
      },
      {
        label: 'extra',
        title: 'Extra',
        description: 'An additional item was added that could not be inferred from the photo, note, '
          + 'history, or general composition of the items in the queue.',
      },
    ],
  },
  {
    label: 'specificity',
    title: 'Poor specificity',
    description: 'An item with high specificity (clear identification in photo or NLP) was '
      + 'identified as a low specificity or generic item. i.e. "dark chocolate" is low '
      + 'specificity, "lindt dark chocolate" is high specificity. Or a user meal note was missed, '
      + 'or the cooking method was incorrect.',
    subcategories: [
      {
        label: 'minor',
        title: 'Minor',
        description: 'A lower specificity item is changed to a higher specificity item within '
          + 'the same family that does not significantly alter the nutrients or qualities of '
          + 'the item. e.g. "whole wheat bread" changed to "multigrain whole wheat bread", '
          + '"dark chocolate" changed to "lindt dark chocolate", "pasta" changed to "barilla '
          + 'fusilli pasta".',
      },
      {
        label: 'moderate',
        title: 'Moderate',
        description: 'A lower specificity item is changed to a higher specificity item within '
          + 'the same family that results in a tolerable nutrient change but alters the quality '
          + 'of the item, its composition, or appearance. e.g. "white rice" changed to "brown '
          + 'rice" (significant change whole grains), "bread" changed to "sourdough bread".',
      },
      {
        label: 'severe',
        title: 'Severe',
        description: 'A lower specificity item is changed to a higher specificity item within '
          + 'the same family that results in a significant change in nutrients and/or the '
          + 'quality of the item, its composition, or appearance. e.g. "bread" changed to "keto '
          + 'bread" (significant change in carbohydrates), "chicken" changed to "breaded fried '
          + 'chicken" (changes in carbs, fat), "dry pasta" changed to "cooked pasta", "yogurt" '
          + 'changed to "greek yogurt"',
      },
      { separator: true },
      {
        label: 'too specific',
        title: 'Too specific',
        description: 'Item is identified as too specific and there is no information to support '
          + 'the specificity. e.g. "pizza hut 4 cheese pizza" changed to "cheese pizza" (no '
          + 'indication from meal note, photo, or user history that this was from Pizza Hut and '
          + 'this was 4 cheese instead of normal cheese).',
      },
      {
        label: 'in meal note',
        title: 'In meal note',
        description: 'Item does not match the meal note that provides the needed specificity.',
      },
    ],
  },
  {
    label: 'patient history',
    title: 'Inaccurate patient history',
    description: "An item with low specificity did not match the patient's historical use pattern. "
      + 'i.e. for a patient with a history of logging "silk organic soy milk, original", '
      + 'logging "silk organic soy milk, original" instead of "soy milk".',
  },
  {
    label: 'other',
    title: 'Other',
    description: 'Provide a mandatory reason for this change if it does not fall into the other categories.',
    subcategories: [
      {
        label: 'in database',
        title: 'In database',
        description: 'Item already exists in the database and should not be a custom.',
      },
      {
        label: 'create custom',
        title: 'Create custom',
        description: 'Item should have been created as a custom but not due to poor specificity.',
      },
      {
        label: 'push question',
        title: 'Push question',
        description: 'A push question should have been sent for an unidentifiable food.',
      },
      {
        label: 'process',
        title: 'Process',
        description: 'Standard queue processes were not followed, e.g. not using smoothie base, not '
          + 'moving items to add-ons.',
      },
      {
        label: 'free text',
        title: 'Free text',
        description: 'Mandatory text if no other "Other" option is selected.',
      },
    ],
  },
];

const PostQA2QuestionnaireModal = (props: {
  open: boolean,
  onClose: () => void,
  onSubmit: (action: QueueSubmitAction, diffs?: QueueDiffWithReason[]) => void,
  submitAction: QueueSubmitAction,
}) => {
  const { queueDiffs } = useQueueItemEditor();
  const [changeReasonObjectState, setChangeReasonObjectState] = useState<{ [key: number]: QA2ChangeReason | null }>({});

  const handleReasonCategoryClick = (key: number, label: string) => {
    setChangeReasonObjectState(prevState => {
      const reason = prevState[key];
      return {
        ...prevState,
        // when selecting or deselecting a reason category, we don't need to keep
        // the subcategory/other freetext, but we do want the notes
        [key]: reason?.category === label
          ? {
            category: '',
            notes: reason?.notes,
          }
          : {
            category: label,
            notes: reason?.notes,
          },
      };
    });
  };

  const handleReasonSubcategoryClick = (key: number, label: string) => {
    setChangeReasonObjectState(prevState => {
      const reason = prevState[key];
      if (!reason) {
        return prevState;
      }
      return {
        ...prevState,
        // when selecting or deselecting a reason subcategory, we don't need
        // to keep the other freetext
        [key]: {
          category: reason?.category,
          subcategory: reason?.subcategory === label ? undefined : label,
          notes: reason?.notes,
        },
      };
    });
  };

  const handleOtherReasonChange = (key: number, input: string) => {
    setChangeReasonObjectState(prevState => {
      const reason = prevState[key];
      if (!reason) {
        return prevState;
      }
      return {
        ...prevState,
        [key]: {
          ...reason,
          other_free_text: input,
        },
      };
    });
  };

  const handleNotesChange = (key: number, input: string) => {
    setChangeReasonObjectState(prevState => {
      const reason = prevState[key];
      if (!reason) {
        return prevState;
      }
      return {
        ...prevState,
        [key]: {
          ...reason,
          notes: input,
        },
      };
    });
  };

  const handleSubmit = () => {
    const diffsWithReasons = queueDiffs?.map((diff, idx) => {
      if (!changeReasonObjectState[idx]) {
        return { ...diff, reason: { category: 'sizing' } };
      }
      return { ...diff, reason: changeReasonObjectState[idx] };
    });
    props.onSubmit(props.submitAction, diffsWithReasons);
    setChangeReasonObjectState({});
  };

  const handleClose = () => {
    props.onClose();
    setChangeReasonObjectState({});
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>
        <Typography variant="h3">Review Questionnaire</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: 2 }}>
          Edits were made to this queue. Please provide an explanation for each edit to be provided as feedback to the
          reviewer.
        </DialogContentText>
        <ThemeProvider theme={EDITOR_BUTTON_THEME}>
          <Grid
            container
            spacing={1}
          >
            {queueDiffs?.map((itemDiff, idx) => (
              <PostQA2QuestionnaireDiffLine
                key={`itemDiff${idx}`}
                diff={itemDiff}
                index={idx}
                changeReasonState={changeReasonObjectState}
                onReasonCategoryClick={handleReasonCategoryClick}
                onReasonSubcategoryClick={handleReasonSubcategoryClick}
                onOtherReasonChange={handleOtherReasonChange}
                onNotesChange={handleNotesChange}
              />
            ))}
          </Grid>
        </ThemeProvider>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={queueDiffs?.some((diff, idx) => {
            // don't need a reason for a meal item with only addon changes
            // also don't need a reason for serving changes
            if (diff.changeType === 'updateAddonsOnly' || diff.changeType === 'updateSizing') {
              return false;
            }
            const changeReason = changeReasonObjectState[idx];
            // make sure there is a category selected
            if (changeReason && !changeReason.category) {
              return true;
            }
            // other+free text must have an entered explanation
            if (
              changeReason && changeReason.category === 'other'
              && changeReason.subcategory === 'free text' && !changeReason.other_free_text
            ) {
              return true;
            }
            // if category has subcategories, a subcategory must be selected
            return !changeReason
              || (QUEUE_DIFF_REASONS.find(r => r.label === changeReason.category)?.subcategories !== undefined
                && !changeReason.subcategory);
          })}
          sx={{ textTransform: 'capitalize' }}
        >
          {props.submitAction}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PostQA2QuestionnaireDiffLine = (props: {
  diff: QueueDiff,
  index: number,
  changeReasonState: { [key: string]: QA2ChangeReason | null },
  onReasonCategoryClick: (key: number, label: string) => void,
  onReasonSubcategoryClick: (key: number, label: string) => void,
  onOtherReasonChange: (key: number, input: string) => void,
  onNotesChange: (key: number, input: string) => void,
}) => {
  if (props.diff.changeType === 'updateSizing') {
    return null;
  }
  // If meal item only has addon changes, don't show reason options for the meal item itself
  const showReasonOptions = props.diff.changeType !== 'updateAddonsOnly';
  const changeReason = props.changeReasonState[props.index];
  const subcategories = QUEUE_DIFF_REASONS.find(r => r.label === changeReason?.category)?.subcategories;
  return (
    <Grid item xs={12}>
      <Typography
        sx={{
          paddingLeft: props.diff.itemType === 'addon' ? '28px' : '0',
        }}
      >
        {props.diff.itemType === 'addon' && '↳'}
        {props.diff.changeType === 'create'
          ? (
            <>
              <Tooltip title="Item added">
                <AddCircle color="success" />
              </Tooltip>{' '}
              {props.diff.after?.food_name} {props.diff.after && formatMealItemSizing(props.diff.after)}
            </>
          )
          : props.diff.changeType === 'delete'
          ? (
            <>
              <Tooltip title="Item removed">
                <RemoveCircle color="error" />
              </Tooltip>{' '}
              {props.diff.before?.food_name} {props.diff.before && formatMealItemSizing(props.diff.before)}
            </>
          )
          : (
            <>
              <Tooltip title="Item updated">
                <ChangeCircle color="secondary" />
              </Tooltip>{' '}
              {props.diff.after?.food_name} {props.diff.after && formatMealItemSizing(props.diff.after)}
              {props.diff.changeType !== 'updateAddonsOnly' && (
                <Typography style={{ paddingLeft: '28px' }}>
                  {props.diff.before?.food_name} {props.diff.before && formatMealItemSizing(props.diff.before)} →{' '}
                  {props.diff.after?.food_name} {props.diff.after && formatMealItemSizing(props.diff.after)}
                </Typography>
              )}
            </>
          )}
        {showReasonOptions && (
          <Grid
            container
            spacing={1}
            sx={{ margin: `8px 0 16px 0`, paddingLeft: props.diff.itemType === 'addon' ? '36px' : '20px' }}
          >
            <>
              {QUEUE_DIFF_REASONS.map(reason => (
                <Grid item key={`reason ${props.index} ${reason.label}`} xs={2}>
                  <Button
                    variant={changeReason?.category === reason.label ? 'contained' : 'outlined'}
                    color={changeReason?.category === reason.label ? 'success' : 'primary'}
                    onClick={() => props.onReasonCategoryClick(props.index, reason.label)}
                    disableElevation
                    disableRipple
                    startIcon={changeReason?.category === reason.label
                      ? <CheckBox />
                      : <CheckBoxOutlineBlank />}
                    fullWidth
                    sx={{ opacity: changeReason?.category && changeReason?.category !== reason.label ? '0.5' : 1 }}
                  >
                    {reason.label}{' '}
                    <Tooltip title={`${reason.title}: ${reason.description}`}>
                      <Info fontSize="small" sx={{ opacity: '50%', fontSize: 'medium', marginLeft: '8px' }} />
                    </Tooltip>
                  </Button>
                </Grid>
              ))}

              <Grid item xs={4} mt="-21px" sx={{ paddingLeft: '20px !important', paddingRight: '20px' }}>
                <TextField
                  margin="dense"
                  label="Notes (optional)"
                  type="text"
                  fullWidth
                  variant="standard"
                  onChange={(e) => props.onNotesChange(props.index, e.target.value)}
                />
              </Grid>

              {changeReason?.category && (
                <Grid container item xs={12} spacing={1}>
                  {subcategories && <Typography sx={{ paddingTop: '16px', paddingLeft: '16px' }} />}
                  {subcategories?.map(subcategory => {
                    return subcategory.label
                      ? (
                        <Grid
                          item
                          key={`subreason ${props.index} ${subcategory.label}`}
                          xs={2}
                        >
                          <Button
                            variant={changeReason?.subcategory === subcategory.label ? 'contained' : 'outlined'}
                            color={changeReason?.subcategory === subcategory.label ? 'success' : 'primary'}
                            onClick={() => props.onReasonSubcategoryClick(props.index, subcategory.label)}
                            disableElevation
                            disableRipple
                            startIcon={changeReason?.subcategory === subcategory.label
                              ? <CheckBox />
                              : <CheckBoxOutlineBlank />}
                            fullWidth
                          >
                            {subcategory.label}{' '}
                            <Tooltip title={`${subcategory.title}: ${subcategory.description}`}>
                              <Info fontSize="small" sx={{ opacity: '50%', fontSize: 'medium', marginLeft: '8px' }} />
                            </Tooltip>
                          </Button>
                        </Grid>
                      )
                      : <Grid item key={`subreason ${props.index} separator`} xs={1} />;
                  })}
                </Grid>
              )}

              {changeReason?.category === 'other' && changeReason?.subcategory === 'free text' && (
                <>
                  <Grid item xs={6} />
                  <Grid item xs={6}>
                    <TextField
                      margin="dense"
                      label="Other reason (mandatory)"
                      type="text"
                      fullWidth
                      variant="standard"
                      onChange={(e) => props.onOtherReasonChange(props.index, e.target.value)}
                    />
                  </Grid>
                </>
              )}
            </>
          </Grid>
        )}
      </Typography>
    </Grid>
  );
};

const PostTrainingFeedbackModal = (props: {
  open: boolean,
  onClose: () => void,
  onSubmit: () => void,
}) => {
  const handleSubmit = () => {
    props.onSubmit();
  };

  const handleClose = () => {
    props.onClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>
        <Typography variant="h3">Training Comparison</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: 2 }}>
          Review your work against the reference items. If you have questions about your work, share the training queue
          link with your training instructor for additional feedback.
        </DialogContentText>
        <TrainingComparison />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
        >
          Done reviewing
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TrainingComparison = () => {
  const { draftItems, queueItem, trainingReferenceMealItems } = useQueueItemEditor();
  const patientContext = usePatientContext(queueItem);
  const relevantNutrients = useRelevantNutrients({
    context: 'summary',
    patientContext: patientContext,
  });
  return (
    <Grid container spacing={1}>
      <Grid item xs={5.5}>
        <Typography variant="h4">Training Changes</Typography>
        <SummaryTable
          draftItems={draftItems}
          relevantNutrients={relevantNutrients}
          disableSizingColWrap
        />
      </Grid>
      <Grid item xs={6.5}>
        <Typography variant="h4">Reference Items</Typography>
        <SummaryTable
          draftItems={trainingReferenceMealItems}
          relevantNutrients={relevantNutrients}
          disableSizingColWrap
        />
      </Grid>
    </Grid>
  );
};

const QueueItemEditActionsBar = (props: {
  onEditCallback?: () => void,
  onSubmitCallback: (action?: QueueSubmitAction) => void,
  onEscalate: () => void,
}) => {
  const editor = useQueueItemEditor();
  const foodDrawer = useFoodDrawerState();
  const qaSession = useUserQualityAssuranceSession();
  const { authInfo } = useAuth();
  const [escalateDetailsIsOpen, setEscalateDetailsIsOpen] = useState<boolean>(false);
  const { qaLogs } = useMealQALogs(editor.queueItem);

  const hasBeenEscalated = qaLogs.reduce((acc, qa) => {
    return acc || (qa.user_id !== 1 && qa.is_escalated);
  }, false);

  const handleHelpRequest = (queueItem: MealQueueItem) => {
    mixpanel.track('clicked requestAssistance:open');
    if (queueItem && authInfo) {
      const helpMessage = window.prompt('Enter help message:');
      console.log(helpMessage);
      if (helpMessage) {
        sendHelpRequest(
          queueItem.data_reviewer_group_id,
          authInfo.reviewer_id,
          queueItem.id,
          authInfo.access_token,
          helpMessage,
        );
        mixpanel.track('clicked requestAssistance:sent');
      }
    }
  };

  const handleAddNewMealItem = () => {
    editor.selectedItemAddNewItem({
      id: null,
      item: getEmptyMealItem(),
      searchItem: null,
      queryText: null,
      foodMatchDetails: null,
    });
    foodDrawer.show({
      initialSearchText: '',
    });
    if (props.onEditCallback) {
      props.onEditCallback();
    }
  };

  if (qaSession.isQA2ReviewMode && qaSession.session) {
    return (
      <Box sx={{ mt: 1.5, display: 'flex', justifyContent: 'space-between' }}>
        <Button disabled={editor.mealDeleted} onClick={handleAddNewMealItem} variant="contained" startIcon={<Add />}>
          Add a meal item
        </Button>
        <Stack direction="row" spacing={1}>
          <Tooltip title={!editor.isAllItemsMarkedDoneByUser() ? 'Check all meal items as done to enable' : ''} arrow>
            <span style={{ display: 'flex' }}>
              <Button
                disabled={editor.mealDeleted || !editor.isAllItemsMarkedDoneByUser() /**|| saveRes.isPending */}
                onClick={() => {
                  props.onSubmitCallback('approve');
                }}
                variant="contained"
                color="success"
                size="small"
                startIcon={<Check />}
              >
                Approve
              </Button>
            </span>
          </Tooltip>
          <Tooltip
            title={hasBeenEscalated && !editor.mealDeleted
              ? 'Has already been escalated, approval mandatory'
              : 'Flag the meal for review by a more senior reviewer'}
            arrow
          >
            <span style={{ display: 'flex' }}>
              <Button
                /** Allow escalate for meals that get deleted while reviewer is assigned to it */
                disabled={hasBeenEscalated && !editor.mealDeleted /**|| saveRes.isPending */}
                onClick={() => {
                  props.onSubmitCallback('escalate');
                }}
                variant="contained"
                color="error"
                size="small"
                startIcon={<Report />}
              >
                Escalate
              </Button>
            </span>
          </Tooltip>
        </Stack>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 1.5, display: 'flex', justifyContent: 'space-between' }}>
      {editor.selectedItem
        ? (
          <Button
            variant="outlined"
            onClick={() => handleHelpRequest(editor.queueItem)}
            sx={{ marginLeft: 'auto' }}
            startIcon={<Help />}
          >
            Request assistance
          </Button>
        )
        : (
          <MealBuilderActions
            onCreateNew={handleAddNewMealItem}
            onRequestAssistance={() => handleHelpRequest(editor.queueItem)}
            onEscalate={() => setEscalateDetailsIsOpen(true)}
            onPaste={() => {/* XXX */}}
            queueItem={editor.queueItem}
            copiedItem={editor.copiedItem}
            mealDeleted={editor.mealDeleted}
          />
        )}
      <EscalationDetailsDialog
        open={!!escalateDetailsIsOpen}
        onClose={() => setEscalateDetailsIsOpen(false)}
        onSubmit={props.onEscalate}
      />
    </Box>
  );
};

const POST_PROC_QUESTIONNAIRE_PROC_TIME_MINUTES = 5;
const POST_PROC_QUESTIONNAIRE_PROC_TIME_MINUTES_L1 = 3;
const POST_PROC_QUESTIONNAIRE_ACCESS_TIME_MINUTES = 1.5;

const QueueItemView = (props: {
  onQueueComplete?: () => void,
}) => {
  const { authInfo, hasAuth } = useAuth();
  const editor = useQueueItemEditor();
  const pushQuestionService = usePushQuestions({
    patientId: editor.queueItem.patient_id,
    mealId: editor.queueItem.created_meal_id,
  });
  const navigate = useNavigate();
  const patientContext = usePatientContext(editor.queueItem);
  const [changesSaved, setChangesSaved] = useState(true);
  const qaSession = useUserQualityAssuranceSession();
  usePromptBeforePageUnload({ show: !changesSaved });
  const [queueSubmitResponse, setQueueSubmitResponse] = useState(null as null | SubmitMealResult);
  const parsedMealNote = useNlpPhraseParser(editor.queueItem.patient_note, editor.queueItem.id);
  const [isConfirmingNutrients, setIsConfirmingNutrients] = useState(null as Record<string, any> | null);
  const mealNutrientValues = useMealNutrientValues({ draftItems: editor.draftItems });
  const [queueQA2QuestionnaireIsOpen, setQueueQA2QuestionnaireIsOpen] = useState<boolean>(false);
  const [queueQA2Action, setQueueQA2Action] = useState<QueueSubmitAction>('submit');
  const [trainingFeedbackModalIsOpen, setTrainingFeedbackModalIsOpen] = useState<boolean>(false);

  const handleMealNoteSelect = (item: MealNoteSelection) => {
    if (!item) {
      return;
    }

    editor.handleMatchItemSelect({
      matchText: item.food_name,
      mealItem: item.match.meal_item,
      relatedFoodResponse: item.relatedFoodResponse,
      mixpanelSource: 'Meal notes',
      foodMatchDetails: {
        user_type: 'internal',
        source_name: 'nlp_note',
        source_details: {
          nlp_selection: item.match,
        },
      } satisfies MealItemFoodMatchDetailsNLP,
    });
  };

  // removeDraftItem() is already called in QueueMealItems component

  const relevantNutrients = useRelevantNutrients({
    context: 'item',
    patientContext: patientContext,
  });

  const saveRes = useAsyncResult<void>();

  const missingSearchItems = useFoodResponses(editor.draftItems.filter(i => !i.searchItem).map(i => i.item.food_name));
  const allSearchItemQueryDone = Object.values(missingSearchItems).every(i => i.query.isSuccess);

  useEffect(() => {
    if (!allSearchItemQueryDone) {
      return;
    }
    editor.draftItems.forEach((draftItem) => {
      if (editor.queueItem.is_processed && draftItem?.item.food_name && !draftItem.searchItem) {
        editor.updateDraftItem(draftItem, { searchItem: missingSearchItems[draftItem.item.food_name].query.data });
      }
    });
  }, [allSearchItemQueryDone]);

  useEffect(() => {
    if (qaSession.isQA2ReviewMode && qaSession.query.isFetched && !qaSession.session) {
      navigate(`/queue-item/${editor.queueItem.id}?utm_source=invalid-qa-session`);
    }
  }, [qaSession.isQA2ReviewMode, qaSession.query.isFetched, qaSession.session]);

  const validateServingUnits = () => {
    for (const draftItem of editor.draftItems) {
      if (!draftItem.searchItem) {
        continue;
      }

      const servingUnit = draftItem.searchItem?.serving_units
        ? draftItem.searchItem?.serving_units.filter(su =>
          su.label == draftItem.item.serving_unit_label && su.amount == draftItem.item.serving_unit_amount
        )
        : [];

      if (servingUnit.length == 0) {
        return false;
      }
    }

    return true;
  };

  const [saveHandled, setSaveHandled] = useState(false);
  useEffect(() => {
    if (changesSaved && saveHandled) {
      // Currently reloading to update editor existing items
      // for queueDiffs to be accurate on any additional changes
      window.location.reload();
    }
  }, [changesSaved, saveHandled]);
  const handleSave = async (action: QueueSubmitAction, diffs?: QueueDiffWithReason[]) => {
    setQueueQA2QuestionnaireIsOpen(false);
    try {
      await editor.saveChanges(
        diffs,
        qaSession.isQA2ReviewMode && qaSession.session?.review_id ? qaSession.session?.review_id : undefined,
      );
    } catch (err) {
      logTrackedError({
        sourceName: `QueueItemView.handleSubmitClick`,
        origin: err as any,
        stackError: new Error(),
        context: { queueId: editor.queueItem.id, submitAction: action },
        userMessage: 'Unexpected error saving changes.',
      });
      return;
    }

    if (action !== 'submit') {
      await qaSession.saveQA2Log({
        isApproved: action === 'approve',
        isEscalated: action === 'escalate',
      });
      if (authInfo?.reviewer_id) {
        try {
          const updatedSession = await qaSession.getNextQA2SessionQueue();
          if (
            updatedSession && updatedSession.status !== 'no_available_queues'
            && updatedSession.current_queue?.id
          ) {
            navigate(
              `/qa/session/${updatedSession.id}/queue/${updatedSession.current_queue.id}?utm_source=session-get-next`,
            );
            return;
          }
        } catch (err) {
          // not critical, just return to initial qa page
        }
        navigate(`/qa/initial-qa?utm_source=session-no-available-queues`);
      }
      return;
    }

    window.alert('Changes Saved!');
    setSaveHandled(true);
    setChangesSaved(true);
    setQueueQA2Action('submit');
  };

  const handleSubmitClick = (action?: QueueSubmitAction) => {
    setQueueQA2Action(action || 'submit');

    const outOfThresholdNutrients: { [key: string]: number } = {};
    Object.entries(NUTRIENT_WARNING_THRESHOLDS).forEach(([nutrient, threshold]) => {
      const value = nutrient == 'weight_g'
        ? getMealTotalWeight(editor.draftItems)
        : mealNutrientValues.getMealTotal(nutrient as any);
      if (value && value > threshold) {
        outOfThresholdNutrients[nutrient] = value;
      }
    });

    editor.draftItems.forEach(item => {
      const itemWeight = getItemWeightWithoutAddons(item.item);
      if (itemWeight == NUTRIENT_WARNING_WEIGHT_1G) {
        outOfThresholdNutrients['weight_g'] = itemWeight;
      }
    });

    const hasNutrientWarnings = Object.keys(outOfThresholdNutrients).length > 0;

    const hasPendingSystemQuestions = pushQuestionService.mealQuestions.some(q => {
      if (q.question_status != MealPushQuestionStatusEnum.Pendingsystem) {
        return false;
      }
      const draftItem = editor.draftItems.find(i => i.item.id == q.meal_item_id);
      if (!draftItem) {
        return false;
      }
      return (
        !draftItem.pushQuestionUpdate
        || draftItem.pushQuestionUpdate.question_status == MealPushQuestionStatusEnum.Pendingsystem
      );
    });

    if (hasPendingSystemQuestions) {
      const res = window.prompt(
        'This meal has pending questions. Please either:\n'
          + '- Resolve them by clicking the red icon then "resolve", or\n'
          + '- Enter "ignore" to submit anyway.',
      );
      if (res != 'ignore') {
        return;
      }
    }

    const shouldConfirm = (!isProcessed && relevantNutrients.length > 0)
      || (action === 'approve' && hasNutrientWarnings);

    if (!shouldConfirm) {
      saveRes.bind(processSubmit(action));
      return;
    }

    mixpanel.track('Meal nutrient confirmation: displayed');
    setIsConfirmingNutrients({
      Reason: hasNutrientWarnings ? 'Nutrient threshold warnings' : 'User real-time nutrients',
      ...outOfThresholdNutrients,
    });
  };

  const processSubmit = async (action?: QueueSubmitAction) => {
    setQueueSubmitResponse(null);
    setIsConfirmingNutrients(null);

    if (editor.queueItem.is_processed) {
      if (!validateServingUnits() && action === 'approve') {
        window.alert('Fix serving units before saving');
        return;
      }

      const queueDiffsNoSizingChanges = editor.queueDiffs?.filter(diff => diff.changeType !== 'updateSizing');
      if (queueDiffsNoSizingChanges?.length) {
        setQueueQA2QuestionnaireIsOpen(true);
        return;
      }

      // This is to capture edits with only serving changes as they don't trigger the qa2 form.
      handleSave(
        action || 'submit',
        editor.queueDiffs?.map((diff) => {
          return { ...diff, reason: { category: 'sizing' } };
        }),
      );
      return;
    }

    const SUSPICIOUS_WEIGHT_THRESHOLD = 750;

    const suspiciousWeightItems = editor.draftItems.filter(i => (
      i.item.serving_unit_amount * i.item.servings > SUSPICIOUS_WEIGHT_THRESHOLD
    ));

    if (suspiciousWeightItems.length > 0) {
      if (
        !window.confirm(
          `The following meal items are over ${SUSPICIOUS_WEIGHT_THRESHOLD}g.\n`
            + suspiciousWeightItems
              .map(item =>
                `- ${item.item.food_name} (${formatNumber(item.item.serving_unit_amount * item.item.servings)}g)`
              )
              .join('\n')
            + `\nAre you sure you want to submit?\n`,
        )
      ) {
        return;
      }
    }

    if (editor.queueItem.queue_type == 'custom') {
      if (!window.confirm('This meal was manually created by the patient, are you sure you want to submit?')) {
        return;
      }
    }

    let totalItemCount = 0;
    editor.draftItems.forEach(item => {
      totalItemCount += 1;
      totalItemCount += item.item.custom_addons?.length || 0;
    });
    if (totalItemCount < parsedMealNote.result.length) {
      const res = window.prompt(
        `WARNING: more items in meal note than meal\n`
          + `Meal note foods: ${parsedMealNote.result.length}\n`
          + `Items + addons: ${totalItemCount}\n`
          + `\n`
          + `Type 'yes' to submit anyway.`,
      );
      mixpanel.track('Suspicious queue submit', {
        Reason: 'Fewer items than NLP',
        'NLP item count': parsedMealNote.result.length,
        'Item and addon count': totalItemCount,
        'Response': res,
      });
      if (res != 'yes') {
        return;
      }
    }

    if (editor.draftItems.length == 0) {
      const res = window.prompt('Meal is empty. Enter "empty" to submit anyway.');
      if (res != 'empty') {
        return;
      }
    } else if (!window.confirm('Confirm Submission')) {
      return;
    }

    const submitResponse = await editor.submitMeal();
    setQueueSubmitResponse(submitResponse);

    mixpanel.track('clicked submit', { success: submitResponse.success });
    if (!submitResponse.success) {
      return;
    }

    if (submitResponse.processingTime && !editor.queueItem.is_processed) {
      alert(
        'Submission successful. Access time: ' + submitResponse.accessTime?.toFixed(2) + 'min; Processing time: '
          + submitResponse.processingTime.toFixed(2) + 'min',
      );

      if (editor.queueItem.queue_metadata?.is_training) {
        setTrainingFeedbackModalIsOpen(true);
        return;
      }

      if (
        ((submitResponse.accessTime ?? 0) > POST_PROC_QUESTIONNAIRE_ACCESS_TIME_MINUTES)
        || ((submitResponse.processingTime ?? 0) > POST_PROC_QUESTIONNAIRE_PROC_TIME_MINUTES)
        || (editor.queueItem.queue_metadata?.review_effective_level === 1
          && (submitResponse.processingTime ?? 0) > POST_PROC_QUESTIONNAIRE_PROC_TIME_MINUTES_L1)
      ) {
        return;
      }
    }
    onQueueComplete();
  };

  const onQueueComplete = () => {
    props.onQueueComplete?.();
  };

  const isEditing = !!editor.selectedItem;
  const isProcessed = editor.queueItem.is_processed;

  const isInvalid = React.useMemo(() => {
    return editor.draftItems.some(i => {
      return draftItemGetWarnings({
        draftItem: i,
        relevantNutrients,
      })?.isFatal;
    });
  }, [editor.draftItems, relevantNutrients]);

  const [overrideClaimQueue, setOverrideClaimQueue] = useState(false);
  const shouldShowClaimQueue = overrideClaimQueue ? false : (
    !isProcessed
    && editor.queueItem.first_reviewer_user_id != authInfo?.reviewer_id
    && hasAuth(Capabilities.mpqItemLabel)
  );
  const shouldShowQueueProcessing = isProcessed ? false : !hasAuth(Capabilities.mpqItemEdit);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Grid container spacing={1}>
      <PostProcessingQuestionnairesModal
        queueItem={editor.queueItem}
        queueSubmitResponse={queueSubmitResponse}
        onSubmit={onQueueComplete}
      />
      <PostQA2QuestionnaireModal
        open={queueQA2QuestionnaireIsOpen}
        onClose={() => setQueueQA2QuestionnaireIsOpen(false)}
        onSubmit={handleSave}
        submitAction={queueQA2Action}
      />
      <PostTrainingFeedbackModal
        open={trainingFeedbackModalIsOpen}
        onClose={() => setTrainingFeedbackModalIsOpen(false)}
        onSubmit={onQueueComplete}
      />

      {qaSession.isQA2ReviewMode && <QueueReviewSessionOverlay />}
      {qaSession.isQA2ReviewMode && <QueueReviewSessionHeader />}

      <QueueItemHeaderCondensed
        shouldShowClaimQueue={shouldShowClaimQueue}
        shouldShowQueueProcessing={shouldShowQueueProcessing}
        setOverrideClaimQueue={setOverrideClaimQueue}
        onGoBack={handleGoBack}
        onMealNoteSelect={handleMealNoteSelect}
      />

      <Grid container item xs={12} rowGap={2}>
        <Grid container spacing={1}>
          <Grid item xs={5.5}>
            <Stack spacing={1} style={{ position: 'sticky', top: '4.5rem' }}>
              <MealPhoto
                queueItem={editor.queueItem}
              />
            </Stack>
          </Grid>
          <Grid item xs={6.5}>
            <Stack spacing={1}>
              {shouldShowClaimQueue
                ? (
                  <InteractiveMealTable
                    onEditCallback={() => isProcessed && setChangesSaved(false)}
                    onDeleteCallback={(item: DraftItem) => isProcessed && setChangesSaved(false)}
                    isViewOnly
                  />
                )
                : shouldShowQueueProcessing
                ? (
                  <InteractiveMealTable
                    onEditCallback={() => isProcessed && setChangesSaved(false)}
                    onDeleteCallback={(item: DraftItem) => isProcessed && setChangesSaved(false)}
                    isViewOnly
                  />
                )
                : (
                  <>
                    <InteractiveMealTable
                      onEditCallback={() => isProcessed && setChangesSaved(false)}
                      onDeleteCallback={(item: DraftItem) => isProcessed && setChangesSaved(false)}
                    />
                    {!(editor.queueItem.is_processed && editor.queueItem.queue_metadata?.is_training) && (
                      <Stack direction="column" spacing={0.5}>
                        {!(shouldShowClaimQueue || shouldShowQueueProcessing) && (
                          <QueueItemEditActionsBar
                            onEscalate={() => props.onQueueComplete?.()}
                            onEditCallback={() =>
                              isProcessed && setChangesSaved(false)}
                            onSubmitCallback={handleSubmitClick}
                          />
                        )}
                        {!qaSession.isQA2ReviewMode && (
                          <Tooltip
                            title={(isInvalid)
                              ? 'Clear errors to enable'
                              : !editor.isAllItemsMarkedDoneByUser()
                              ? 'Mark all items as done to enable'
                              : ''}
                            arrow
                          >
                            <span>
                              <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                                disabled={isEditing || saveRes.isPending || isInvalid || editor.mealDeleted
                                  || !editor.isAllItemsMarkedDoneByUser()}
                                onClick={() => handleSubmitClick()}
                              >
                                {isProcessed ? 'Save Edits' : 'Submit'}
                              </Button>
                            </span>
                          </Tooltip>
                        )}
                      </Stack>
                    )}
                    <MealConfirmNutrientsDialog
                      open={!!isConfirmingNutrients}
                      mixpanelProps={isConfirmingNutrients as Record<string, any>}
                      onClose={() => setIsConfirmingNutrients(null)}
                      onSubmit={() => saveRes.bind(processSubmit(queueQA2Action))}
                    />
                    {editor.queueItem.is_processed && !editor.queueItem.queue_metadata?.is_training && (
                      <MealChangeLogs
                        queueItem={editor.queueItem}
                        draftItems={editor.draftItems}
                      />
                    )}
                  </>
                )}
            </Stack>
          </Grid>
        </Grid>
        {editor.queueItem.is_processed && !editor.queueItem.queue_metadata?.is_training
          && (hasAuth(Capabilities.mpqQAView) || hasAuth(Capabilities.patientReportView)) && (
          <Grid container spacing={1}>
            <Grid item xs={5.5}>
              <MealQALogs />
              <div style={{ margin: '20px 0' }}>
                <Button variant="outlined" color="primary" onClick={handleGoBack}>
                  <LeftOutlined /> Back
                </Button>
              </div>
            </Grid>
            <Grid item xs={6.5}>
              <Stack spacing={1}>
                <MealQoSDetails item={editor.queueItem} />
              </Stack>
            </Grid>
          </Grid>
        )}
        {editor.queueItem.is_processed && editor.queueItem.queue_metadata?.is_training
          && <TrainingComparison />}
      </Grid>
      {!shouldShowClaimQueue && <PatientRecentSimilarMeals />}
    </Grid>
  );
};

const PatientRecentSimilarMeals = () => {
  const editor = useQueueItemEditor();

  const [mealSearchText, setMealSearchText] = React.useState('');
  const unfilteredPatientMealHistory = usePatientMealHistory(
    editor.queueItem.id,
  );
  const patientMealHistory = usePatientMealHistory(
    editor.queueItem.id,
    mealSearchText,
  );

  const recentMealsByDate = React.useMemo(() => {
    return _(patientMealHistory.recentMeals)
      .orderBy(['meal_date', 'meal_time'], ['desc', 'desc'])
      .value();
  }, [patientMealHistory.recentMeals]);

  const handleRecommendedMealClick = (mealResponse: MealResponse, index: number, source?: string) => {
    mixpanel.track('Meal item selected', {
      Type: 'Regular item',
      Source: source,
      'Item count': mealResponse.meal_items.length,
      Index: index,
    });
    editor.addBulkDraftItems({
      replaceSelected: true,
      items: mealResponse.meal_items.map(mealHistoryItemToMealItem).map((item) => ({
        id: null,
        item,
        searchItem: null,
        queryText: null,
        foodMatchDetails: null,
      } satisfies DraftItem)),
    });
  };

  return (unfilteredPatientMealHistory.recentMeals.length > 0
    ? (
      (
        <MainCard
          sx={{
            marginTop: '1rem',
            marginLeft: '8px',
            padding: 0,
            position: 'sticky',
            transition: 'bottom 0.5s',
            bottom: -150,
            zIndex: 10,
            width: '100%',
            backgroundColor: 'white',
            ':hover': {
              bottom: 0,
            },
            ':hover .recent-meals-search': {
              display: 'inline-flex',
            },
          }}
        >
          <Stack spacing={1} sx={{ margin: '-8px' }}>
            <OutlinedInput
              autoComplete="off"
              className="recent-meals-search"
              placeholder="Search recent meals for patient"
              onChange={(evt) => {
                setMealSearchText(evt.target.value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    color="secondary"
                    edge="end"
                  >
                    <SearchOutlined />
                  </IconButton>
                </InputAdornment>
              }
              sx={{
                maxWidth: 450,
                display: 'none',
              }}
            />
            {patientMealHistory.recentMeals.length > 0
              ? (
                <RecommendedMeals
                  mealHistory={recentMealsByDate}
                  addItemSource="Recent meals footer"
                  handleClick={handleRecommendedMealClick}
                  size="small"
                  showItemMenu
                />
              )
              : (
                <MainCard
                  sx={{
                    maxWidth: 150,
                    padding: 0,
                  }}
                  contentSX={{
                    padding: 1,
                    paddingBottom: '10px !important',
                  }}
                >
                  <Typography sx={{ height: 150 }}>
                    <em>No meals found</em>
                  </Typography>
                </MainCard>
              )}
          </Stack>
        </MainCard>
      )
    )
    : null);
};

export const MealOrQueueItemPage = () => {
  const { itemId, mealId } = useParams();
  const isMeal = !!mealId;

  const queueIdQuery = useQuery(['queue-id-for-meal', mealId], async () => {
    if (!mealId) {
      return '';
    }

    // Returns 404 for meals without an associated photo queue
    const response = await dataReviewApi.appApiDataReviewerGetMealPhotoQueueId({
      meal_id: +mealId,
    }).then(res => res.data);

    return response.queueId;
  }, { enabled: isMeal });

  return (
    <>
      {isMeal && queueIdQuery.isError && <div>Error: {'' + queueIdQuery.error}</div>}
      {isMeal && queueIdQuery.isLoading && <div>Loading...</div>}
      {isMeal && queueIdQuery.isSuccess && <QueueItemPage itemId={queueIdQuery.data} />}
      {!isMeal && <QueueItemPage itemId={itemId} />}
    </>
  );
};

export const QueueItemEditorProvider = (props: {
  queueItemId: string | undefined,
  children: React.ReactNode,
}) => {
  const pageState = useNewQueueItemEditor({
    queueItemId: props.queueItemId,
  });

  return (
    <QueueItemEditorStateCtx.Provider value={pageState}>
      <FoodDrawerProvider>
        {props.children}
      </FoodDrawerProvider>
    </QueueItemEditorStateCtx.Provider>
  );
};

export const QueueItemPage = (props: { itemId: string | undefined }) => {
  const { itemId } = props;
  useSetPageTitle('queue #', itemId);

  return (
    <QueueItemEditorProvider queueItemId={itemId}>
      <QueueItemPageInner />
    </QueueItemEditorProvider>
  );
};

export const QueueItemPageInner = () => {
  const navigate = useNavigate();
  const qpState = useQueueItemEditor();
  const { queueItem } = qpState;

  if (qpState.query.isError) {
    return <div>Load error: {'' + qpState.query.error}</div>;
  }

  if (!queueItem || !qpState.query.isSuccess) {
    return <div>Loading...</div>;
  }

  const handleQueueComplete = () => {
    navigate('/');
  };

  return <QueueItemView onQueueComplete={handleQueueComplete} />;
};

const QueueReviewSessionOverlay = () => {
  const qaSession = useUserQualityAssuranceSession();
  const isPaused = React.useMemo(() => {
    return qaSession.isQA2ReviewMode && !!qaSession.session
      && qaSession.session.status === QualityAssuranceSessionResponseStatusEnum.Paused;
  }, [qaSession.isQA2ReviewMode, qaSession.session]);
  return (
    <Modal
      open={isPaused}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            backdropFilter: 'blur(10px)',
          },
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          flexDirection: 'column',
          color: 'white',
        }}
      >
        <Typography variant="h2" sx={{ zIndex: 1 }}>Paused</Typography>
        <Button
          variant="contained"
          startIcon={<PlayArrow />}
          onClick={() => {
            qaSession.resumeQA2Session();
          }}
        >
          Resume
        </Button>
      </Box>
    </Modal>
  );
};

const QueueItemHeaderCondensed = (
  props: {
    shouldShowClaimQueue: boolean,
    shouldShowQueueProcessing: boolean,
    setOverrideClaimQueue: React.Dispatch<React.SetStateAction<boolean>>,
    onGoBack: () => void,
    onMealNoteSelect: (item: MealNoteSelection) => void,
  },
) => {
  const { authInfo, hasAuth } = useAuth();
  const editor = useQueueItemEditor();
  const patientContext = usePatientContext(editor.queueItem);
  const canGoBack = window.history.length > 1;
  const dietRestrictions = editor.queueItem.queue_metadata?.patient_context?.diet_restrictions
    ?? patientContext.context?.diet_restrictions
    ?? [];
  const qa1Label = `QA1${editor.queueItem.queue_metadata?.is_training ? ' training' : ''}`;
  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={5.5}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Stack direction="row" alignItems="center" spacing={1.5}>
              {canGoBack && (
                <IconButton variant="outlined" color="primary" onClick={props.onGoBack}>
                  <LeftOutlined />
                </IconButton>
              )}
              <Stack direction="column">
                <Stack direction="row" spacing={1} divider={<Typography>&nbsp;</Typography>} alignItems="center">
                  <>
                    <Tooltip
                      title={
                        <>
                          <b>Meal ID:</b> {editor.queueItem.created_meal_id}
                        </>
                      }
                      arrow
                    >
                      <b>Q#{editor.queueItem.id}</b>
                    </Tooltip>
                    <QueuePriorityIcon queue={editor.queueItem} />
                    {editor.mealDeleted
                      ? (
                        <Tooltip title="Deleted" arrow>
                          <Block color="error" fontSize="small" />
                        </Tooltip>
                      )
                      : editor.queueItem.is_processed
                      ? (
                        <Tooltip title="Processed" arrow>
                          <CheckCircleOutline color="success" fontSize="small" />
                        </Tooltip>
                      )
                      : (
                        <Tooltip title="New" arrow>
                          <NewReleases color="primary" fontSize="small" />
                        </Tooltip>
                      )}
                  </>
                  {editor.queueItem.preparation_method && (
                    <Typography>
                      <Tooltip title={editor.queueItem.preparation_method} arrow>
                        {editor.queueItem.preparation_method === 'homemade'
                          ? <Cottage fontSize="small" />
                          : editor.queueItem.preparation_method === 'restaurant'
                          ? <Fastfood fontSize="small" />
                          : editor.queueItem.preparation_method === 'cpg'
                          ? <Pages fontSize="small" />
                          : editor.queueItem.preparation_method === 'store_bought'
                          ? <LocalGroceryStore fontSize="small" />
                          : <SoupKitchen fontSize="small" />}
                      </Tooltip>
                    </Typography>
                  )}
                  <MealName queueItem={editor.queueItem} />
                  <MealTimeCondensed queueItem={editor.queueItem} />
                  {!editor.queueItem.is_processed && <QueueTimerChip queueItem={editor.queueItem} size="small" />}
                  {editor.queueItem.is_processed
                    ? (
                      <Chip
                        variant="combined"
                        color="warning"
                        label={`${qa1Label}: ${editor.queueItem.first_reviewer_user_id}`}
                        size="small"
                      />
                    )
                    : authInfo?.reviewer_id === editor.queueItem.first_reviewer_user_id
                    ? <Chip variant="combined" color="info" label={`${qa1Label}: you`} size="small" />
                    : editor.queueItem.first_reviewer_user_id
                    ? (
                      <Chip
                        variant="combined"
                        color="error"
                        label={`${qa1Label}: ${editor.queueItem.first_reviewer_user_id}`}
                        size="small"
                      />
                    )
                    : ''}
                </Stack>
                <Stack direction="row" spacing={1} divider={<Typography>&nbsp;</Typography>} alignItems="center">
                  <PatientIDCondensed
                    userId={editor.queueItem.patient_id}
                    isPriority={!!editor.queueItem.is_priority_patient}
                  />
                  <Tooltip
                    title={
                      <span>
                        Patient is {!patientContext.is_legal_alcohol_age && 'not'} of legal drinking (alcohol) age
                      </span>
                    }
                    arrow
                  >
                    <Typography>
                      <PatientAgeValue patientContext={patientContext} />
                    </Typography>
                  </Tooltip>
                  <Typography>
                    {patientContext.query.isLoading
                      ? <span style={{ fontStyle: 'italic' }}>Loading…</span>
                      : patientContext.context?.country ?? 'unknown'}
                  </Typography>
                  <Typography>
                    {patientContext.query.isLoading
                      ? <span style={{ fontStyle: 'italic' }}>Loading…</span>
                      : getMaskedClinicType(patientContext.context?.clinics?.[0]?.clinic_type)}
                  </Typography>
                </Stack>
                <PatientDiet dietRestrictions={dietRestrictions} />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6.5} position="relative">
        {props.shouldShowClaimQueue
          ? (
            <Typography textAlign="center">
              {!editor.queueItem.first_reviewer_user_id
                ? (
                  <Stack>
                    <Typography>
                      <b>Queue Unclaimed</b>
                    </Typography>
                    <Typography>
                      This queue has not been claimed yet. Would you like to claim it to start labeling?
                    </Typography>
                    {editor.claimQueueRes.isError && (
                      <Alert severity="error">
                        {'' + editor.claimQueueRes.error}
                      </Alert>
                    )}
                    <Button
                      variant="contained"
                      color={editor.claimQueueRes.isError ? 'warning' : 'primary'}
                      size="large"
                      fullWidth
                      disabled={editor.claimQueueRes.isPending}
                      onClick={() => {
                        if (editor.claimQueueRes.isError) {
                          props.setOverrideClaimQueue(true);
                          return;
                        }
                        editor.claimQueue();
                      }}
                    >
                      {editor.claimQueueRes.isError ? 'Edit queue anyway' : 'Claim queue and start labeling'}
                    </Button>
                  </Stack>
                )
                : (
                  <Stack>
                    <Typography>
                      <b>Queue Claimed by {editor.queueItem.first_reviewer_user_id}</b>
                    </Typography>
                    <Typography>
                      This queue has been claimed{' '}
                      <em>
                        <HumanTime value={editor.queueItem.first_reviewer_access_time} />
                      </em>{' '}
                      by <em>{editor.queueItem.first_reviewer_user_id}</em>.
                    </Typography>
                    <Button
                      variant="contained"
                      color="warning"
                      size="large"
                      fullWidth
                      onClick={() => props.setOverrideClaimQueue(true)}
                    >
                      Edit queue anyway
                    </Button>
                  </Stack>
                )}
            </Typography>
          )
          : props.shouldShowQueueProcessing
          ? (
            <Typography textAlign="center">
              <Stack>
                <Typography>
                  <b>Queue Processing</b>
                </Typography>
                <Typography>
                  This queue is currently being processed. Please check again later.
                </Typography>
              </Stack>
            </Typography>
          )
          : (
            <Grid container>
              <Typography variant="h5">
                <b>Meal note:</b> {editor.queueItem.patient_note
                  ? (
                    <MealNoteView
                      value={editor.queueItem.patient_note}
                      onClick={props.onMealNoteSelect}
                      mealItems={editor.draftItems}
                      queueId={editor.queueItem.id}
                    />
                  )
                  : <span className="text-secondary">no patient note provided</span>}
              </Typography>
              {!!editor.queueItem.patient_note_translations && !!('en' in editor.queueItem.patient_note_translations)
                && (
                  <Typography>
                    <b>Translated meal note:</b>{' '}
                    <MealNoteView
                      value={editor.queueItem.patient_note_translations.en as string}
                      onClick={props.onMealNoteSelect}
                      mealItems={editor.draftItems}
                      queueId={editor.queueItem.id}
                    />
                  </Typography>
                )}
            </Grid>
          )}
        {
          /* only show the dump viewer for queues which have been processed by a
          reviewer (ie, that aren't log-from-recents) */
        }
        {editor.queueItem.is_processed && !!editor.queueItem.data_reviewer_id
          && hasAuth(Capabilities.commonDataReviewerAdmin) && (
          <div style={{ position: 'absolute', top: 10, right: 14 }}>
            <GenerateQueueDumpButton mpqId={editor.queueItem.id} />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

const GenerateQueueDumpButton = (props: { mpqId: number }) => {
  const mutation = useMutation({
    mutationFn: async () => {
      const res = await dataReviewApi.appApiDataReviewerGenerateMealPhotoQueueDump({
        meal_photo_queue_id: props.mpqId,
      });
      if (res.data.dump_url) {
        window.open(res.data.dump_url, '_blank');
        return { dump_url: res.data.dump_url };
      }
      const err = res.data?.error ?? `Unknown error (status: ${res.status})`;
      alert(err);
      throw new Error(err);
    },
  });
  return (
    <IconButton
      color={mutation.isSuccess
        ? 'success'
        : 'secondary'}
      edge="end"
      title="Generate queue dump"
      disabled={mutation.isLoading}
      onClick={() => {
        if (mutation.isLoading) {
          return;
        }
        if (mutation.isSuccess) {
          const url = mutation.data.dump_url;
          window.open(url, '_blank');
        }
        mutation.mutate();
      }}
    >
      {mutation.isLoading
        ? <CircularProgress size={24} />
        : <Biotech />}
    </IconButton>
  );
};
