import { Chip } from '@mui/material';
import * as _ from 'lodash';
import moment from 'moment';

import { QA_CONFIDENCE_LABELS, QA_QUESTIONNAIRE_QUESTIONS } from 'common/qa';
import { FilterSortTable } from 'components/FilterSortTable';
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'App.css';
import { useQueueItemEditor } from 'pages/QueueItem/services/QueueItemEditorService';
import { useMealQALogs } from './useMealQALogs';

export const MealQALogs = () => {
  const editor = useQueueItemEditor();
  const mealQALogs = useMealQALogs(editor.queueItem);
  const columns = [
    {
      Header: 'Time',
      accessor: 'updated_time',
    },
    {
      Header: 'Reviewer',
      accessor: 'user_id',
    },
    {
      Header: 'Flags',
      accessor: 'flags',
      Cell: ({ value }: any) => (
        <>
          {value.is_approved && <Chip color="success" label="Approved" size="small" variant="light" />}
          {value.is_reviewed && <Chip color="info" label="Reviewed" size="small" variant="light" />}
          {value.is_escalated && <Chip color="error" label="Escalated" size="small" variant="light" />}
        </>
      ),
    },
    {
      Header: 'Difficulty',
      accessor: 'level_of_difficulty',
    },
    {
      Header: 'Questionnaire',
      accessor: 'questionnaire',
      Cell: ({ value }: any) => <QAQuestionnaireView questionnaire={value} />,
    },
    {
      Header: 'Notes',
      accessor: 'note',
      Cell: ({ value }: any) => {
        return <div style={{ whiteSpace: 'pre-line' }}>{value}</div>;
      },
    },
  ];
  const data = mealQALogs.qaLogs.map(qa => {
    const { is_approved, is_reviewed, is_escalated } = qa;

    return {
      updated_time: moment(qa.updated_time).format('YYYY-MM-DD HH:mm:ss'),
      user_id: qa.user_id,
      flags: { is_approved, is_reviewed, is_escalated },
      level_of_difficulty: confidenceNumberToText(qa.level_of_difficulty),
      questionnaire: qa.questionnaire,
      note: qa.note,
    };
  });

  return (
    <MainCard title="QA Logs" content={false} style={{ maxHeight: 300, overflow: 'auto' }}>
      <ScrollX>
        <FilterSortTable columns={columns} data={data} striped={true} />
      </ScrollX>
    </MainCard>
  );
};

export const confidenceNumberToText = (confidence: number | null | undefined) => {
  return QA_CONFIDENCE_LABELS.find((c) => c.value === confidence)?.label || `Unknown (${confidence})`;
};

export const QAQuestionnaireView = (props: {
  questionnaire: object | null | undefined,
}) => {
  const { questionnaire } = props;
  if (!questionnaire) {
    return (
      <div>
        <i>(no questionnaire)</i>
      </div>
    );
  }

  return (
    <ul style={{ paddingLeft: '1rem' }}>
      {QA_QUESTIONNAIRE_QUESTIONS.map(q => {
        const answer = (questionnaire as any)[q.id];
        if (!answer) {
          return null;
        }
        return (
          <li key={q.id}>
            {q.description}
          </li>
        );
      })}
    </ul>
  );
};
