import { useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect } from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import { Link, useLocation, useSearchParams } from 'react-router-dom';

import { getQueuesForQa } from 'apiClients/mpq';
import MainCard from 'components/MainCard';
import { AppCtx, useAuth } from 'context/appContext';

import type { MealPhotoQueueQualityAssuranceResponse, MealPhotoQueueResponse } from 'api/generated/MNT';
import { Capabilities } from 'auth-capabilities';
import { PatientID } from 'components/PatientID';
import { QueueSummaryIcons } from 'components/QueueSummaryIcons';
import { HumanTime } from 'food-editor/components/HumanTime';
import moment from 'moment';
import { getMaskedClinicType } from 'utils';

export const QAPage = () => {
  return (
    <MainCard>
      <QA />
    </MainCard>
  );
};

export const QA = () => {
  const { authInfo, hasAuth } = useContext(AppCtx);
  const hasMpqChangeLogFullAttribution = hasAuth(Capabilities.mpqChangeLogsFullAttributionView);
  const isDRA = hasAuth(Capabilities.commonDataReviewerAdmin);
  const isInternal = hasAuth(Capabilities.commonInternal);
  const location = useLocation();
  const [_q, setQ] = useSearchParams();
  const defaults = {
    'qa-date-oldest': moment().subtract(14, 'days').format('YYYY-MM-DD'),
    'qa-is-approved': 'false',
    'qa-is-escalated': 'false',
  };
  const q = {
    ...defaults,
    ...Object.fromEntries(_q.entries()) as any,
  };

  const qaItemListQuery = useQuery(['qa-item-list', q], async () => {
    return getQueuesForQa(authInfo!.access_token, {
      data_reviewer_id: authInfo!.reviewer_id,
      date_oldest: q['qa-date-oldest'],
      date_newest: q['qa-date-newest'],
      is_reviewed: q['qa-is-reviewed'],
      is_approved: q['qa-is-approved'],
      is_escalated: q['qa-is-escalated'],
      is_auto_logged: q['qa-is-auto-logged'],
      is_priority: q['qa-is-priority'],
      has_custom_items: q['qa-has-custom-items'],
      patient_id: q['qa-patient-id'],
      is_from_recent: 'false',
    });
  });

  const qaItems = qaItemListQuery.data!;

  const shouldHighlight = (item: MealPhotoQueueQualityAssuranceResponse) => {
    if (!hasAuth(Capabilities.commonDataReviewerAdmin)) {
      return false;
    }
    const first = (item.qa_logs || [])[0];
    return first && first.is_escalated;
  };

  const inputChanged = (e: React.ChangeEvent<any>, value: string) => {
    setQ({
      ...q,
      [e.target.id]: value,
    });
  };

  const input = (label: string, name: string, type: 'date' | 'number' | 'optional_boolean') => {
    return (
      <Form.Group as={Col} controlId={name}>
        <Form.Label>{label}</Form.Label>
        {type == 'date' && <Form.Control type="date" value={q[name]} onChange={e => inputChanged(e, e.target.value)} />}
        {type == 'number' && (
          <Form.Control
            type={type}
            value={q[name]}
            onChange={e => inputChanged(e, e.target.value)}
          />
        )}
        {type == 'optional_boolean' && (
          <Form.Control
            as="select"
            value={q[name]}
            onChange={e => inputChanged(e, e.target.value)}
          >
            <option value="">(not filtered)</option>
            <option value="true">true</option>
            <option value="false">false</option>
          </Form.Control>
        )}
      </Form.Group>
    );
  };

  if (authInfo == null) {
    return (null);
  }

  return (
    <div style={{ marginBottom: 20 }}>
      <Row style={{ marginBottom: 10 }}>
        {input('Date (oldest)', 'qa-date-oldest', 'date')}
        {input('Date (newest)', 'qa-date-newest', 'date')}
        {isInternal && input('Is reviewed?', 'qa-is-reviewed', 'optional_boolean')}
        {isInternal && input('Is approved?', 'qa-is-approved', 'optional_boolean')}
        {isInternal && input('Is escalated?', 'qa-is-escalated', 'optional_boolean')}
        {isInternal && input('Is auto-logged?', 'qa-is-auto-logged', 'optional_boolean')}
        {isInternal && input('Is priority?', 'qa-is-priority', 'optional_boolean')}
        {isInternal && input('Has customs?', 'qa-has-custom-items', 'optional_boolean')}
        {isInternal && input('Patient ID', 'qa-patient-id', 'number')}
      </Row>

      {qaItemListQuery.isError && <div style={{ marginBottom: 20 }}>Error: {'' + qaItemListQuery.error}</div>}

      {!qaItemListQuery.isSuccess && (
        <div style={{ marginBottom: 20 }}>
          <i>Loading...</i>
        </div>
      )}

      {qaItems && !qaItems.length && (
        <div>
          <i>No items to QA.</i>
        </div>
      )}

      {qaItems && qaItems.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Patient</th>
              <th>Meal</th>
              <th>Classif</th>
              <th>Clinic Type</th>
              {hasMpqChangeLogFullAttribution && <th>Flags</th>}
              {(hasMpqChangeLogFullAttribution && isDRA) && <th>Reviewer ID</th>}
              <th>Created Time</th>
              {isInternal && <th>QA Reviews</th>}
              {hasMpqChangeLogFullAttribution && <th>Days Until Report</th>}
            </tr>
          </thead>
          <tbody>
            {qaItemListQuery.data?.map(qa_response_row => {
              const item = qa_response_row.queue_item!;
              const qa_flags = qa_response_row.qa_flags!;
              const mi_flags = qa_flags.nutrient_flags;
              const misc_flags = qa_flags.miscellaneous_flags;
              const shouldHighlightEscalation = shouldHighlight(qa_response_row);
              return (
                <tr
                  key={item.id}
                  style={{
                    backgroundColor: shouldHighlightEscalation ? '#ffc0cb' : '',
                  }}
                >
                  <td>
                    <Link
                      to={`/queue-item/${item.id}`}
                      state={{
                        queueItem: item,
                        prevUrl: location.pathname + location.search,
                      }}
                    >
                      {item.id}
                    </Link>
                  </td>
                  <td>
                    <PatientID userId={item.patient_id} hideOffline isPriority={!!item.is_priority_patient} />
                  </td>
                  <td>
                    {item.created_meal_id}
                  </td>
                  <td>
                    <QueueSummaryIcons queue={item} showCustomIcon={qa_response_row.has_custom_items} />
                  </td>
                  <td>{getMaskedClinicType(item.clinic_type)}</td>
                  {isInternal && (
                    <td style={{ fontSize: 12 }}>
                      {mi_flags?.map(flag => {
                        return '(' + flag.meal_item_id + ': ' + flag.nutrient + '/' + Math.round(flag.value! * 10) / 10
                          + '); ';
                      })}
                      {misc_flags?.map(flag => {
                        return '(' + flag.meal_item_id + ': ' + flag.description + '); ';
                      })}
                    </td>
                  )}
                  {(hasMpqChangeLogFullAttribution && isDRA) && <td>{item.data_reviewer_id}</td>}
                  <td>
                    <HumanTime value={item.created_time} hideSeconds />
                  </td>
                  {isInternal && (
                    <td>
                      {item.qa_logs?.map((qa, idx) => (
                        <span key={idx}>
                          {qa.user_id}
                          <span
                            style={{
                              color: shouldHighlightEscalation ? 'red' : '',
                            }}
                          >
                            {(() => {
                              const flags = [
                                qa.is_approved && 'approved',
                                qa.is_escalated && idx == 0 && 'escalated',
                                qa.is_escalated && idx > 0 && 'escalated; resolved',
                              ].filter(x => !!x);
                              if (!flags.length) {
                                return '';
                              }
                              return ' (' + flags.join('; ') + ')';
                            })()}
                          </span>
                          {idx < item.qa_logs!.length - 1 ? ', ' : ''}
                        </span>
                      ))}
                    </td>
                  )}
                  {hasMpqChangeLogFullAttribution && <td>{qa_response_row.n_days_until_report}</td>}
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
};
