/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppApiFoodFoodDetailsGetFoodDetailsEstimates200Response } from '../models';
// @ts-ignore
import { AppApiFoodFoodSearchGetClientSearchIndex200Response } from '../models';
// @ts-ignore
import { AppApiFoodFoodSearchGetFoodDatabaseTable200Response } from '../models';
// @ts-ignore
import { AppApiFoodFoodSearchGetMealPhotoUploadUrl200Response } from '../models';
// @ts-ignore
import { CalculateCompositeFoodDetailsRequest } from '../models';
// @ts-ignore
import { CalculateCompositeFoodDetailsResponse } from '../models';
// @ts-ignore
import { CognitiveSearchAutocompleteResponse } from '../models';
// @ts-ignore
import { CognitiveSearchResponse } from '../models';
// @ts-ignore
import { CognitiveSearchSuggestResponse } from '../models';
// @ts-ignore
import { CreateFavoriteRequest } from '../models';
// @ts-ignore
import { CreateFoodScrapingRequest } from '../models';
// @ts-ignore
import { CreateMealItemRequest } from '../models';
// @ts-ignore
import { CreateSearchByTextNlpQueueRequest } from '../models';
// @ts-ignore
import { FavoriteFoodResponse } from '../models';
// @ts-ignore
import { FavoriteItemResponse } from '../models';
// @ts-ignore
import { FoodAddonResponse } from '../models';
// @ts-ignore
import { FoodAssignmentRequest } from '../models';
// @ts-ignore
import { FoodAssignmentResponse } from '../models';
// @ts-ignore
import { FoodChangeLogResponse } from '../models';
// @ts-ignore
import { FoodDetailsResponse } from '../models';
// @ts-ignore
import { FoodDetailsUpdateRequest } from '../models';
// @ts-ignore
import { FoodOntologyVTO } from '../models';
// @ts-ignore
import { FoodOptionCollection } from '../models';
// @ts-ignore
import { FoodResponse } from '../models';
// @ts-ignore
import { FoodSearchByAudioResponse } from '../models';
// @ts-ignore
import { FoodSearchByNlpPhraseResponse } from '../models';
// @ts-ignore
import { FoodSearchByPhotoResponse } from '../models';
// @ts-ignore
import { FoodSearchByTextResponse } from '../models';
// @ts-ignore
import { FoodSearchV2Response } from '../models';
// @ts-ignore
import { FoodWhiteboardCreateUpdateRequest } from '../models';
// @ts-ignore
import { FoodWhiteboardItemResponse } from '../models';
// @ts-ignore
import { MealPhotoQueueResponse } from '../models';
// @ts-ignore
import { MealResponse } from '../models';
// @ts-ignore
import { PhotoUploadResponse } from '../models';
// @ts-ignore
import { PreparationMethodEnum } from '../models';
// @ts-ignore
import { RecentAddonResponse } from '../models';
// @ts-ignore
import { RecentFoodResponse } from '../models';
// @ts-ignore
import { RecentMealItemResponse } from '../models';
// @ts-ignore
import { UpdateFavoriteRequest } from '../models';
// @ts-ignore
import { UsdaApiNutritionResponse } from '../models';
// @ts-ignore
import { UsdaApiSearchResponse } from '../models';
/**
 * FoodApi - axios parameter creator
 * @export
 */
export const FoodApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Export all addons assigned for all foods
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodAddonsGetFoodResponseAddons: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/foods/addons/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [DEPRECATED] Validates Nutrients and returns warnings
         * @param {string} food_name 
         * @param {FoodDetailsUpdateRequest} [FoodDetailsUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsApplyWarnings: async (food_name: string, FoodDetailsUpdateRequest?: FoodDetailsUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodDetailsApplyWarnings', 'food_name', food_name)
            const localVarPath = `/foods/{food_name}/apply_warnings`
                .replace(`{${"food_name"}}`, encodeURIComponent(String(food_name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(FoodDetailsUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validates Nutrients and returns warnings
         * @param {string} food_name 
         * @param {FoodDetailsUpdateRequest} [FoodDetailsUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsApplyWarningsQuery: async (food_name: string, FoodDetailsUpdateRequest?: FoodDetailsUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodDetailsApplyWarningsQuery', 'food_name', food_name)
            const localVarPath = `/food/apply_warnings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (food_name !== undefined) {
                localVarQueryParameter['food_name'] = food_name;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(FoodDetailsUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets calculated food details for a list of food components
         * @param {CalculateCompositeFoodDetailsRequest} [CalculateCompositeFoodDetailsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsCalculateFoodCompositeDetails: async (CalculateCompositeFoodDetailsRequest?: CalculateCompositeFoodDetailsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/food/details/calculate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CalculateCompositeFoodDetailsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [DEPRECATED] Gets complete food details
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsGetFoodDetails: async (food_name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodDetailsGetFoodDetails', 'food_name', food_name)
            const localVarPath = `/foods/{food_name}/details`
                .replace(`{${"food_name"}}`, encodeURIComponent(String(food_name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [DEPRECATED] Gets change logs for a food
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsGetFoodDetailsChangeLogs: async (food_name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodDetailsGetFoodDetailsChangeLogs', 'food_name', food_name)
            const localVarPath = `/foods/{food_name}/change-logs`
                .replace(`{${"food_name"}}`, encodeURIComponent(String(food_name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets change logs for a food
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsGetFoodDetailsChangeLogsQuery: async (food_name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodDetailsGetFoodDetailsChangeLogsQuery', 'food_name', food_name)
            const localVarPath = `/food/change-logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (food_name !== undefined) {
                localVarQueryParameter['food_name'] = food_name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [DEPRECATED] Gets food details estimates
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsGetFoodDetailsEstimates: async (food_name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodDetailsGetFoodDetailsEstimates', 'food_name', food_name)
            const localVarPath = `/foods/{food_name}/details-estimates`
                .replace(`{${"food_name"}}`, encodeURIComponent(String(food_name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets food details estimates
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsGetFoodDetailsEstimatesQuery: async (food_name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodDetailsGetFoodDetailsEstimatesQuery', 'food_name', food_name)
            const localVarPath = `/food/details-estimates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (food_name !== undefined) {
                localVarQueryParameter['food_name'] = food_name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets complete food details
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsGetFoodDetailsQuery: async (food_name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodDetailsGetFoodDetailsQuery', 'food_name', food_name)
            const localVarPath = `/food/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (food_name !== undefined) {
                localVarQueryParameter['food_name'] = food_name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the options collection for a specific scope
         * @param {string} scope 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsGetOptionsCollection: async (scope: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scope' is not null or undefined
            assertParamExists('appApiFoodFoodDetailsGetOptionsCollection', 'scope', scope)
            const localVarPath = `/options/options_collection/{scope}`
                .replace(`{${"scope"}}`, encodeURIComponent(String(scope)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets complete food details
         * @param {number} limit 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsGetRecentFoods: async (limit: number, status?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('appApiFoodFoodDetailsGetRecentFoods', 'limit', limit)
            const localVarPath = `/foods/recent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [DEPRECATED] Update a food\'s associated user ids based on assignment
         * @param {string} food_name 
         * @param {FoodAssignmentRequest} [FoodAssignmentRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsPatchFoodAssignTask: async (food_name: string, FoodAssignmentRequest?: FoodAssignmentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodDetailsPatchFoodAssignTask', 'food_name', food_name)
            const localVarPath = `/foods/{food_name}/assign_task`
                .replace(`{${"food_name"}}`, encodeURIComponent(String(food_name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(FoodAssignmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a food\'s associated user ids based on assignment
         * @param {string} food_name 
         * @param {FoodAssignmentRequest} [FoodAssignmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsPatchFoodAssignTaskQuery: async (food_name: string, FoodAssignmentRequest?: FoodAssignmentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodDetailsPatchFoodAssignTaskQuery', 'food_name', food_name)
            const localVarPath = `/food/assign_task`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (food_name !== undefined) {
                localVarQueryParameter['food_name'] = food_name;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(FoodAssignmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [DEPRECATED] Updates food details
         * @param {string} food_name 
         * @param {FoodDetailsUpdateRequest} [FoodDetailsUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsPutFoodDetails: async (food_name: string, FoodDetailsUpdateRequest?: FoodDetailsUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodDetailsPutFoodDetails', 'food_name', food_name)
            const localVarPath = `/foods/{food_name}/details`
                .replace(`{${"food_name"}}`, encodeURIComponent(String(food_name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(FoodDetailsUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates food details
         * @param {string} food_name 
         * @param {FoodDetailsUpdateRequest} [FoodDetailsUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsPutFoodDetailsQuery: async (food_name: string, FoodDetailsUpdateRequest?: FoodDetailsUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodDetailsPutFoodDetailsQuery', 'food_name', food_name)
            const localVarPath = `/food/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (food_name !== undefined) {
                localVarQueryParameter['food_name'] = food_name;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(FoodDetailsUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unset favorite food of the patient
         * @param {number} patient_id 
         * @param {string} [food_name] 
         * @param {number} [favorite_id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodFavoriteDeleteFavorite: async (patient_id: number, food_name?: string, favorite_id?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiFoodFoodFavoriteDeleteFavorite', 'patient_id', patient_id)
            const localVarPath = `/patients/{patient_id}/foods/favorite`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (food_name !== undefined) {
                localVarQueryParameter['food_name'] = food_name;
            }

            if (favorite_id !== undefined) {
                localVarQueryParameter['favorite_id'] = favorite_id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get favorite food of the patient from the server
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodFavoriteGetFavorite: async (patient_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiFoodFoodFavoriteGetFavorite', 'patient_id', patient_id)
            const localVarPath = `/patients/{patient_id}/foods/favorite`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get favorite food of the patient from the server
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodFavoriteGetFavorites: async (patient_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiFoodFoodFavoriteGetFavorites', 'patient_id', patient_id)
            const localVarPath = `/patients/{patient_id}/foods/favorites`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new favorite
         * @param {number} patient_id 
         * @param {CreateFavoriteRequest} CreateFavoriteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodFavoritePostFavorite: async (patient_id: number, CreateFavoriteRequest: CreateFavoriteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiFoodFoodFavoritePostFavorite', 'patient_id', patient_id)
            // verify required parameter 'CreateFavoriteRequest' is not null or undefined
            assertParamExists('appApiFoodFoodFavoritePostFavorite', 'CreateFavoriteRequest', CreateFavoriteRequest)
            const localVarPath = `/patients/{patient_id}/foods/favorite`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateFavoriteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set favorite food of the patient
         * @param {number} patient_id 
         * @param {string} [food_name] 
         * @param {UpdateFavoriteRequest} [UpdateFavoriteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodFavoritePutFavorite: async (patient_id: number, food_name?: string, UpdateFavoriteRequest?: UpdateFavoriteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiFoodFoodFavoritePutFavorite', 'patient_id', patient_id)
            const localVarPath = `/patients/{patient_id}/foods/favorite`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (food_name !== undefined) {
                localVarQueryParameter['food_name'] = food_name;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateFavoriteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [DEPRECATED] Get pre-uploaded food image by food name
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodLookupGetFoodPhoto: async (food_name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodLookupGetFoodPhoto', 'food_name', food_name)
            const localVarPath = `/foods/{food_name}/photo`
                .replace(`{${"food_name"}}`, encodeURIComponent(String(food_name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pre-uploaded food image by food name
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodLookupGetFoodPhotoQuery: async (food_name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodLookupGetFoodPhotoQuery', 'food_name', food_name)
            const localVarPath = `/food/photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (food_name !== undefined) {
                localVarQueryParameter['food_name'] = food_name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the recent food of the patient from the server
         * @param {number} patient_id 
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodLookupGetPatientFoodSuggestedAddons: async (patient_id: number, food_name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiFoodFoodLookupGetPatientFoodSuggestedAddons', 'patient_id', patient_id)
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodLookupGetPatientFoodSuggestedAddons', 'food_name', food_name)
            const localVarPath = `/patients/{patient_id}/food/suggested_addons`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (food_name !== undefined) {
                localVarQueryParameter['food_name'] = food_name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the food information from the server by ndbno
         * @param {string} ndbno 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodNdbnoGetFoodNdbno: async (ndbno: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ndbno' is not null or undefined
            assertParamExists('appApiFoodFoodNdbnoGetFoodNdbno', 'ndbno', ndbno)
            const localVarPath = `/foods/ndbno/{ndbno}`
                .replace(`{${"ndbno"}}`, encodeURIComponent(String(ndbno)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the recent addons of the patient from the server. Includes regular and custom.
         * @param {number} patient_id 
         * @param {string} [time] time in format hh:mm:ss
         * @param {string} [food_name] food name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodRecentGetPatientRecentAddons: async (patient_id: number, time?: string, food_name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiFoodFoodRecentGetPatientRecentAddons', 'patient_id', patient_id)
            const localVarPath = `/patients/{patient_id}/foods/recent_addons`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (time !== undefined) {
                localVarQueryParameter['time'] = time;
            }

            if (food_name !== undefined) {
                localVarQueryParameter['food_name'] = food_name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the recent food of the patient from the server
         * @param {number} patient_id 
         * @param {string} time time in format hh:mm:ss
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodRecentGetRecent: async (patient_id: number, time: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiFoodFoodRecentGetRecent', 'patient_id', patient_id)
            // verify required parameter 'time' is not null or undefined
            assertParamExists('appApiFoodFoodRecentGetRecent', 'time', time)
            const localVarPath = `/patients/{patient_id}/foods/recent`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (time !== undefined) {
                localVarQueryParameter['time'] = time;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the recent meal items of the patient from the server
         * @param {number} patient_id 
         * @param {string} [time] time in format hh:mm:ss
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodRecentGetRecentMealItems: async (patient_id: number, time?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiFoodFoodRecentGetRecentMealItems', 'patient_id', patient_id)
            const localVarPath = `/patients/{patient_id}/foods/recent_meal_items`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (time !== undefined) {
                localVarQueryParameter['time'] = time;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request a new food item for the database
         * @param {number} user_id 
         * @param {CreateFoodScrapingRequest} CreateFoodScrapingRequest Food Scraping Request Object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodScrapingPostFood: async (user_id: number, CreateFoodScrapingRequest: CreateFoodScrapingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user_id' is not null or undefined
            assertParamExists('appApiFoodFoodScrapingPostFood', 'user_id', user_id)
            // verify required parameter 'CreateFoodScrapingRequest' is not null or undefined
            assertParamExists('appApiFoodFoodScrapingPostFood', 'CreateFoodScrapingRequest', CreateFoodScrapingRequest)
            const localVarPath = `/foods/food_scraping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (user_id !== undefined) {
                localVarQueryParameter['user_id'] = user_id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateFoodScrapingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search foods by audio file
         * @param {any} audio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchAudioSearchByAudio: async (audio: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'audio' is not null or undefined
            assertParamExists('appApiFoodFoodSearchAudioSearchByAudio', 'audio', audio)
            const localVarPath = `/foods/search_by_audio`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (audio !== undefined) { 
                localVarFormParams.append('audio', audio as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search foods by audio file with results analyzed and queued for review (if required)
         * @param {any} audio 
         * @param {string} [meal_name] 
         * @param {string} [note] 
         * @param {string} [meal_nlp_phrase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchAudioSearchByAudioQueue: async (audio: any, meal_name?: string, note?: string, meal_nlp_phrase?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'audio' is not null or undefined
            assertParamExists('appApiFoodFoodSearchAudioSearchByAudioQueue', 'audio', audio)
            const localVarPath = `/foods/search_by_audio_queue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (audio !== undefined) { 
                localVarFormParams.append('audio', audio as any);
            }
    
            if (meal_name !== undefined) { 
                localVarFormParams.append('meal_name', meal_name as any);
            }
    
            if (note !== undefined) { 
                localVarFormParams.append('note', note as any);
            }
    
            if (meal_nlp_phrase !== undefined) { 
                localVarFormParams.append('meal_nlp_phrase', meal_nlp_phrase as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return complete food terms by autocomplete
         * @param {string} partial_term 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchAzureAutocomplete: async (partial_term: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partial_term' is not null or undefined
            assertParamExists('appApiFoodFoodSearchAzureAutocomplete', 'partial_term', partial_term)
            const localVarPath = `/foods/autocomplete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (partial_term !== undefined) {
                localVarQueryParameter['partial_term'] = partial_term;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search foods by brand names. Return maximum of 20 items
         * @param {string} search_text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchAzureSearchBrand: async (search_text: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search_text' is not null or undefined
            assertParamExists('appApiFoodFoodSearchAzureSearchBrand', 'search_text', search_text)
            const localVarPath = `/foods/search_by_brand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search_text !== undefined) {
                localVarQueryParameter['search_text'] = search_text;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search foods by manufacturer names. Return maximum of 20 items
         * @param {string} search_text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchAzureSearchManufacturer: async (search_text: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search_text' is not null or undefined
            assertParamExists('appApiFoodFoodSearchAzureSearchManufacturer', 'search_text', search_text)
            const localVarPath = `/foods/search_by_manufacturer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search_text !== undefined) {
                localVarQueryParameter['search_text'] = search_text;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return complete food terms by suggest
         * @param {string} partial_term 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchAzureSuggest: async (partial_term: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partial_term' is not null or undefined
            assertParamExists('appApiFoodFoodSearchAzureSuggest', 'partial_term', partial_term)
            const localVarPath = `/foods/suggest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (partial_term !== undefined) {
                localVarQueryParameter['partial_term'] = partial_term;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update document on Azure by food name
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchAzureUpdateDocument: async (food_name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodSearchAzureUpdateDocument', 'food_name', food_name)
            const localVarPath = `/foods/update_document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (food_name !== undefined) {
                localVarQueryParameter['food_name'] = food_name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of food items used for client-side search indexing
         * @param {string} [cursor] combination of \&quot;{updated_time}:{id}\&quot;
         * @param {number} [limit] maximum number of items to return (default: 1000)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchGetClientSearchIndex: async (cursor?: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/foods/client-search-index`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [DEPRECATED] Get the food information from the server
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchGetFood: async (food_name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodSearchGetFood', 'food_name', food_name)
            const localVarPath = `/foods/{food_name}`
                .replace(`{${"food_name"}}`, encodeURIComponent(String(food_name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return a data table of all foods and custom items.
         * @param {string} [filter] 
         * @param {number} [limit] 
         * @param {string} [offset] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchGetFoodDatabaseTable: async (filter?: string, limit?: number, offset?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/food/database-entries/table`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the food information from the server
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchGetFoodQuery: async (food_name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodSearchGetFoodQuery', 'food_name', food_name)
            const localVarPath = `/food`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (food_name !== undefined) {
                localVarQueryParameter['food_name'] = food_name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search foods by text. Return maximum of 20 items
         * @param {string} q 
         * @param {string} [context_lang] 
         * @param {number} [context_user_id] 
         * @param {string} [context_meal_name] 
         * @param {string} [context_food_names] 
         * @param {string} [context_addon_of] 
         * @param {string} [context_index] 
         * @param {boolean} [context_semantic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchGetFoodSearchV2Interactive: async (q: string, context_lang?: string, context_user_id?: number, context_meal_name?: string, context_food_names?: string, context_addon_of?: string, context_index?: string, context_semantic?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('appApiFoodFoodSearchGetFoodSearchV2Interactive', 'q', q)
            const localVarPath = `/foods/search/v2/interactive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (context_lang !== undefined) {
                localVarQueryParameter['context_lang'] = context_lang;
            }

            if (context_user_id !== undefined) {
                localVarQueryParameter['context_user_id'] = context_user_id;
            }

            if (context_meal_name !== undefined) {
                localVarQueryParameter['context_meal_name'] = context_meal_name;
            }

            if (context_food_names !== undefined) {
                localVarQueryParameter['context_food_names'] = context_food_names;
            }

            if (context_addon_of !== undefined) {
                localVarQueryParameter['context_addon_of'] = context_addon_of;
            }

            if (context_index !== undefined) {
                localVarQueryParameter['context_index'] = context_index;
            }

            if (context_semantic !== undefined) {
                localVarQueryParameter['context_semantic'] = context_semantic;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a URL for uploading a meal photo (likely signed S3 URL)
         * @param {string} ext image file extension
         * @param {string} key image batch key
         * @param {number} idx image index in batch
         * @param {string} [suffix] optional suffix for the file (ex, &#x60;\&quot;depth-map\&quot;&#x60;)
         * @param {string} [metadata] optional JSON-encoded metadata to include in the x-goog-meta-rx-meta header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchGetMealPhotoUploadUrl: async (ext: string, key: string, idx: number, suffix?: string, metadata?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ext' is not null or undefined
            assertParamExists('appApiFoodFoodSearchGetMealPhotoUploadUrl', 'ext', ext)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('appApiFoodFoodSearchGetMealPhotoUploadUrl', 'key', key)
            // verify required parameter 'idx' is not null or undefined
            assertParamExists('appApiFoodFoodSearchGetMealPhotoUploadUrl', 'idx', idx)
            const localVarPath = `/foods/get_meal_photo_upload_url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ext !== undefined) {
                localVarQueryParameter['ext'] = ext;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (idx !== undefined) {
                localVarQueryParameter['idx'] = idx;
            }

            if (suffix !== undefined) {
                localVarQueryParameter['suffix'] = suffix;
            }

            if (metadata !== undefined) {
                localVarQueryParameter['metadata'] = metadata;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [DEPRECATED] Get the recent food of the patient from the server
         * @param {number} patient_id 
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchGetPatientFood: async (patient_id: number, food_name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiFoodFoodSearchGetPatientFood', 'patient_id', patient_id)
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodSearchGetPatientFood', 'food_name', food_name)
            const localVarPath = `/patients/{patient_id}/foods/{food_name}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"food_name"}}`, encodeURIComponent(String(food_name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the recent food of the patient from the server
         * @param {number} patient_id 
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchGetPatientFoodQuery: async (patient_id: number, food_name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiFoodFoodSearchGetPatientFoodQuery', 'patient_id', patient_id)
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodSearchGetPatientFoodQuery', 'food_name', food_name)
            const localVarPath = `/patients/{patient_id}/food`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (food_name !== undefined) {
                localVarQueryParameter['food_name'] = food_name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Log a meal with descriptive words with results analyzed and queued for review (if required)
         * @param {string} search_text 
         * @param {CreateSearchByTextNlpQueueRequest} CreateSearchByTextNlpQueueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchNlpPostSearchByTextNlpQueue: async (search_text: string, CreateSearchByTextNlpQueueRequest: CreateSearchByTextNlpQueueRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search_text' is not null or undefined
            assertParamExists('appApiFoodFoodSearchNlpPostSearchByTextNlpQueue', 'search_text', search_text)
            // verify required parameter 'CreateSearchByTextNlpQueueRequest' is not null or undefined
            assertParamExists('appApiFoodFoodSearchNlpPostSearchByTextNlpQueue', 'CreateSearchByTextNlpQueueRequest', CreateSearchByTextNlpQueueRequest)
            const localVarPath = `/foods/search_by_text_nlp_queue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search_text !== undefined) {
                localVarQueryParameter['search_text'] = search_text;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateSearchByTextNlpQueueRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search foods by sentence and analyze using an NLP algorithm. Return maximum of 20 items
         * @param {string} search_text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchNlpSearchByTextNlp: async (search_text: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search_text' is not null or undefined
            assertParamExists('appApiFoodFoodSearchNlpSearchByTextNlp', 'search_text', search_text)
            const localVarPath = `/foods/search_by_text_nlp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search_text !== undefined) {
                localVarQueryParameter['search_text'] = search_text;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Log a meal with descriptive words with results analyzed and queued for review (if required)
         * @param {string} search_text 
         * @param {string} [meal_date] 
         * @param {string} [meal_time] 
         * @param {'breakfast' | 'lunch' | 'dinner' | 'snack'} [meal_name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchNlpSearchByTextNlpQueue: async (search_text: string, meal_date?: string, meal_time?: string, meal_name?: 'breakfast' | 'lunch' | 'dinner' | 'snack', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search_text' is not null or undefined
            assertParamExists('appApiFoodFoodSearchNlpSearchByTextNlpQueue', 'search_text', search_text)
            const localVarPath = `/foods/search_by_text_nlp_queue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search_text !== undefined) {
                localVarQueryParameter['search_text'] = search_text;
            }

            if (meal_date !== undefined) {
                localVarQueryParameter['meal_date'] = meal_date;
            }

            if (meal_time !== undefined) {
                localVarQueryParameter['meal_time'] = meal_time;
            }

            if (meal_name !== undefined) {
                localVarQueryParameter['meal_name'] = meal_name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search foods by text. Return maximum of 20 items
         * @param {string} search_text 
         * @param {string} [context_user_id] 
         * @param {boolean} [extended_query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchSearchByCognitive: async (search_text: string, context_user_id?: string, extended_query?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search_text' is not null or undefined
            assertParamExists('appApiFoodFoodSearchSearchByCognitive', 'search_text', search_text)
            const localVarPath = `/foods/search_by_cognitive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search_text !== undefined) {
                localVarQueryParameter['search_text'] = search_text;
            }

            if (context_user_id !== undefined) {
                localVarQueryParameter['context_user_id'] = context_user_id;
            }

            if (extended_query !== undefined) {
                localVarQueryParameter['extended_query'] = extended_query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for foods by NLP phrase.
         * @param {string} search_text 
         * @param {number} queue_id 
         * @param {boolean} [use_cache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchSearchByNlpPhrase: async (search_text: string, queue_id: number, use_cache?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search_text' is not null or undefined
            assertParamExists('appApiFoodFoodSearchSearchByNlpPhrase', 'search_text', search_text)
            // verify required parameter 'queue_id' is not null or undefined
            assertParamExists('appApiFoodFoodSearchSearchByNlpPhrase', 'queue_id', queue_id)
            const localVarPath = `/foods/search/nlp_phrase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search_text !== undefined) {
                localVarQueryParameter['search_text'] = search_text;
            }

            if (queue_id !== undefined) {
                localVarQueryParameter['queue_id'] = queue_id;
            }

            if (use_cache !== undefined) {
                localVarQueryParameter['use_cache'] = use_cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search foods by photo
         * @param {any} photo 
         * @param {any} [photo2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchSearchByPhoto: async (photo: any, photo2?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'photo' is not null or undefined
            assertParamExists('appApiFoodFoodSearchSearchByPhoto', 'photo', photo)
            const localVarPath = `/foods/search_by_photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (photo !== undefined) { 
                localVarFormParams.append('photo', photo as any);
            }
    
            if (photo2 !== undefined) { 
                localVarFormParams.append('photo2', photo2 as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search foods by photo with fast food entry sorting algorithm applied
         * @param {any} photo 
         * @param {any} [photo2] 
         * @param {number} [num_items] 
         * @param {string} [sizing_input] 
         * @param {string} [meal_name] 
         * @param {string} [note] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchSearchByPhotoFfe: async (photo: any, photo2?: any, num_items?: number, sizing_input?: string, meal_name?: string, note?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'photo' is not null or undefined
            assertParamExists('appApiFoodFoodSearchSearchByPhotoFfe', 'photo', photo)
            const localVarPath = `/foods/search_by_photo_ffe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (photo !== undefined) { 
                localVarFormParams.append('photo', photo as any);
            }
    
            if (photo2 !== undefined) { 
                localVarFormParams.append('photo2', photo2 as any);
            }
    
            if (num_items !== undefined) { 
                localVarFormParams.append('num_items', num_items as any);
            }
    
            if (sizing_input !== undefined) { 
                localVarFormParams.append('sizing_input', sizing_input as any);
            }
    
            if (meal_name !== undefined) { 
                localVarFormParams.append('meal_name', meal_name as any);
            }
    
            if (note !== undefined) { 
                localVarFormParams.append('note', note as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search foods by photo with FFE algorithm applied and results put in a queue for backend review
         * @param {any} [photo] 
         * @param {any} [photo2] 
         * @param {Array<string>} [photo_urls] 
         * @param {number} [num_items] 
         * @param {string} [sizing_input] 
         * @param {string} [meal_name] 
         * @param {string} [meal_time] time in format hh:mm:ss
         * @param {string} [meal_date] calendar date in format YYYY-MM-DD
         * @param {string} [note] 
         * @param {string} [photo_id] 
         * @param {string} [photo_timings_encoded] 
         * @param {PreparationMethodEnum} [preparation_method] 
         * @param {string} [queue_metadata] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchSearchByPhotoFfeQueue: async (photo?: any, photo2?: any, photo_urls?: Array<string>, num_items?: number, sizing_input?: string, meal_name?: string, meal_time?: string, meal_date?: string, note?: string, photo_id?: string, photo_timings_encoded?: string, preparation_method?: PreparationMethodEnum, queue_metadata?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/foods/search_by_photo_ffe_queue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (photo !== undefined) { 
                localVarFormParams.append('photo', photo as any);
            }
    
            if (photo2 !== undefined) { 
                localVarFormParams.append('photo2', photo2 as any);
            }
                if (photo_urls) {
                localVarFormParams.append('photo_urls', photo_urls.join(COLLECTION_FORMATS.csv));
            }

    
            if (num_items !== undefined) { 
                localVarFormParams.append('num_items', num_items as any);
            }
    
            if (sizing_input !== undefined) { 
                localVarFormParams.append('sizing_input', sizing_input as any);
            }
    
            if (meal_name !== undefined) { 
                localVarFormParams.append('meal_name', meal_name as any);
            }
    
            if (meal_time !== undefined) { 
                localVarFormParams.append('meal_time', meal_time as any);
            }
    
            if (meal_date !== undefined) { 
                localVarFormParams.append('meal_date', meal_date as any);
            }
    
            if (note !== undefined) { 
                localVarFormParams.append('note', note as any);
            }
    
            if (photo_id !== undefined) { 
                localVarFormParams.append('photo_id', photo_id as any);
            }
    
            if (photo_timings_encoded !== undefined) { 
                localVarFormParams.append('photo_timings_encoded', photo_timings_encoded as any);
            }
    
            if (preparation_method !== undefined) { 
                localVarFormParams.append('preparation_method', new Blob([JSON.stringify(preparation_method)], { type: "application/json", }));
            }
    
            if (queue_metadata !== undefined) { 
                localVarFormParams.append('queue_metadata', queue_metadata as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary OCR a photo to return a list of words found
         * @param {any} photo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchSearchByPhotoOcr: async (photo: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'photo' is not null or undefined
            assertParamExists('appApiFoodFoodSearchSearchByPhotoOcr', 'photo', photo)
            const localVarPath = `/foods/search_by_photo_ocr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (photo !== undefined) { 
                localVarFormParams.append('photo', photo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search foods by text. Return maximum of 20 items
         * @param {string} search_text 
         * @param {string} [context_user_id] 
         * @param {boolean} [extended_query] 
         * @param {boolean} [food_name_only] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchSearchByText: async (search_text: string, context_user_id?: string, extended_query?: boolean, food_name_only?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search_text' is not null or undefined
            assertParamExists('appApiFoodFoodSearchSearchByText', 'search_text', search_text)
            const localVarPath = `/foods/search_by_text`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search_text !== undefined) {
                localVarQueryParameter['search_text'] = search_text;
            }

            if (context_user_id !== undefined) {
                localVarQueryParameter['context_user_id'] = context_user_id;
            }

            if (extended_query !== undefined) {
                localVarQueryParameter['extended_query'] = extended_query;
            }

            if (food_name_only !== undefined) {
                localVarQueryParameter['food_name_only'] = food_name_only;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search foods with diet restriction
         * @param {string} search_text 
         * @param {string} diet_restriction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchSearchDietRestrictions: async (search_text: string, diet_restriction: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search_text' is not null or undefined
            assertParamExists('appApiFoodFoodSearchSearchDietRestrictions', 'search_text', search_text)
            // verify required parameter 'diet_restriction' is not null or undefined
            assertParamExists('appApiFoodFoodSearchSearchDietRestrictions', 'diet_restriction', diet_restriction)
            const localVarPath = `/foods/search_diet_restrictions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search_text !== undefined) {
                localVarQueryParameter['search_text'] = search_text;
            }

            if (diet_restriction !== undefined) {
                localVarQueryParameter['diet_restriction'] = diet_restriction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a meal photo
         * @param {any} photo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchUploadPhoto: async (photo: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'photo' is not null or undefined
            assertParamExists('appApiFoodFoodSearchUploadPhoto', 'photo', photo)
            const localVarPath = `/foods/upload_photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (photo !== undefined) { 
                localVarFormParams.append('photo', photo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get USDA food by fdcId
         * @param {string} fdcid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodUsdaGetUsdaFood: async (fdcid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fdcid' is not null or undefined
            assertParamExists('appApiFoodFoodUsdaGetUsdaFood', 'fdcid', fdcid)
            const localVarPath = `/foods/usda_database_api/food/{fdcid}`
                .replace(`{${"fdcid"}}`, encodeURIComponent(String(fdcid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get USDA food by fdcId
         * @param {string} fdcid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodUsdaGetUsdaFoodData: async (fdcid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fdcid' is not null or undefined
            assertParamExists('appApiFoodFoodUsdaGetUsdaFoodData', 'fdcid', fdcid)
            const localVarPath = `/foods/usda_database_api/food/{fdcid}/data`
                .replace(`{${"fdcid"}}`, encodeURIComponent(String(fdcid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search foods by text. Return maximum of 20 items
         * @param {string} search_term 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodUsdaSearchUsdaDatabaseApi: async (search_term: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search_term' is not null or undefined
            assertParamExists('appApiFoodFoodUsdaSearchUsdaDatabaseApi', 'search_term', search_term)
            const localVarPath = `/foods/usda_database_api/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search_term !== undefined) {
                localVarQueryParameter['search_term'] = search_term;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary email csv from usda food data by fdcId
         * @param {string} fdcid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodUsdaSendData: async (fdcid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fdcid' is not null or undefined
            assertParamExists('appApiFoodFoodUsdaSendData', 'fdcid', fdcid)
            const localVarPath = `/foods/usda_database_api/food/{fdcid}/send_data`
                .replace(`{${"fdcid"}}`, encodeURIComponent(String(fdcid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [DEPRECATED] Delete a whiteboard note for a food from the food editor
         * @param {string} food_name 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodWhiteboardDeleteWhiteboardItem: async (food_name: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodWhiteboardDeleteWhiteboardItem', 'food_name', food_name)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appApiFoodFoodWhiteboardDeleteWhiteboardItem', 'id', id)
            const localVarPath = `/food/{food_name}/whiteboard-item/{id}`
                .replace(`{${"food_name"}}`, encodeURIComponent(String(food_name)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a whiteboard note for a food from the food editor
         * @param {string} food_name 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodWhiteboardDeleteWhiteboardItemQuery: async (food_name: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodWhiteboardDeleteWhiteboardItemQuery', 'food_name', food_name)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appApiFoodFoodWhiteboardDeleteWhiteboardItemQuery', 'id', id)
            const localVarPath = `/food/whiteboard-item/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (food_name !== undefined) {
                localVarQueryParameter['food_name'] = food_name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [DEPRECATED] Get the whiteboard notes for a food in the food editor
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodWhiteboardGetRelevantQueues: async (food_name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodWhiteboardGetRelevantQueues', 'food_name', food_name)
            const localVarPath = `/food/{food_name}/relevant_queues`
                .replace(`{${"food_name"}}`, encodeURIComponent(String(food_name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the whiteboard notes for a food in the food editor
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodWhiteboardGetRelevantQueuesQuery: async (food_name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodWhiteboardGetRelevantQueuesQuery', 'food_name', food_name)
            const localVarPath = `/food/relevant_queues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (food_name !== undefined) {
                localVarQueryParameter['food_name'] = food_name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [DEPRECATED] Get the whiteboard notes for a food in the food editor
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodWhiteboardGetWhiteboardItems: async (food_name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodWhiteboardGetWhiteboardItems', 'food_name', food_name)
            const localVarPath = `/food/{food_name}/whiteboard-items`
                .replace(`{${"food_name"}}`, encodeURIComponent(String(food_name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the whiteboard notes for a food in the food editor
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodWhiteboardGetWhiteboardItemsQuery: async (food_name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodWhiteboardGetWhiteboardItemsQuery', 'food_name', food_name)
            const localVarPath = `/food/whiteboard-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (food_name !== undefined) {
                localVarQueryParameter['food_name'] = food_name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [DEPRECATED] Create a new whiteboard note for a food in the food editor
         * @param {string} food_name 
         * @param {FoodWhiteboardCreateUpdateRequest} FoodWhiteboardCreateUpdateRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodWhiteboardPostWhiteboardItem: async (food_name: string, FoodWhiteboardCreateUpdateRequest: FoodWhiteboardCreateUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodWhiteboardPostWhiteboardItem', 'food_name', food_name)
            // verify required parameter 'FoodWhiteboardCreateUpdateRequest' is not null or undefined
            assertParamExists('appApiFoodFoodWhiteboardPostWhiteboardItem', 'FoodWhiteboardCreateUpdateRequest', FoodWhiteboardCreateUpdateRequest)
            const localVarPath = `/food/{food_name}/whiteboard-items`
                .replace(`{${"food_name"}}`, encodeURIComponent(String(food_name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(FoodWhiteboardCreateUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new whiteboard note for a food in the food editor
         * @param {string} food_name 
         * @param {FoodWhiteboardCreateUpdateRequest} FoodWhiteboardCreateUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodWhiteboardPostWhiteboardItemQuery: async (food_name: string, FoodWhiteboardCreateUpdateRequest: FoodWhiteboardCreateUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodWhiteboardPostWhiteboardItemQuery', 'food_name', food_name)
            // verify required parameter 'FoodWhiteboardCreateUpdateRequest' is not null or undefined
            assertParamExists('appApiFoodFoodWhiteboardPostWhiteboardItemQuery', 'FoodWhiteboardCreateUpdateRequest', FoodWhiteboardCreateUpdateRequest)
            const localVarPath = `/food/whiteboard-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (food_name !== undefined) {
                localVarQueryParameter['food_name'] = food_name;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(FoodWhiteboardCreateUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [DEPRECATED] Update an existing whiteboard note for a food in the food editor
         * @param {string} food_name 
         * @param {number} id 
         * @param {FoodWhiteboardCreateUpdateRequest} FoodWhiteboardCreateUpdateRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodWhiteboardPutWhiteboardItem: async (food_name: string, id: number, FoodWhiteboardCreateUpdateRequest: FoodWhiteboardCreateUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodWhiteboardPutWhiteboardItem', 'food_name', food_name)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appApiFoodFoodWhiteboardPutWhiteboardItem', 'id', id)
            // verify required parameter 'FoodWhiteboardCreateUpdateRequest' is not null or undefined
            assertParamExists('appApiFoodFoodWhiteboardPutWhiteboardItem', 'FoodWhiteboardCreateUpdateRequest', FoodWhiteboardCreateUpdateRequest)
            const localVarPath = `/food/{food_name}/whiteboard-item/{id}`
                .replace(`{${"food_name"}}`, encodeURIComponent(String(food_name)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(FoodWhiteboardCreateUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing whiteboard note for a food in the food editor
         * @param {string} food_name 
         * @param {number} id 
         * @param {FoodWhiteboardCreateUpdateRequest} FoodWhiteboardCreateUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodWhiteboardPutWhiteboardItemQuery: async (food_name: string, id: number, FoodWhiteboardCreateUpdateRequest: FoodWhiteboardCreateUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiFoodFoodWhiteboardPutWhiteboardItemQuery', 'food_name', food_name)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('appApiFoodFoodWhiteboardPutWhiteboardItemQuery', 'id', id)
            // verify required parameter 'FoodWhiteboardCreateUpdateRequest' is not null or undefined
            assertParamExists('appApiFoodFoodWhiteboardPutWhiteboardItemQuery', 'FoodWhiteboardCreateUpdateRequest', FoodWhiteboardCreateUpdateRequest)
            const localVarPath = `/food/whiteboard-item/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (food_name !== undefined) {
                localVarQueryParameter['food_name'] = food_name;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(FoodWhiteboardCreateUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the ontologies for the food editor
         * @param {boolean} [include_archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodOntologyGetAllOntologies: async (include_archived?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/food/ontologies/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (include_archived !== undefined) {
                localVarQueryParameter['include_archived'] = include_archived;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [DEPRECATED] get a calibrated meal item request object
         * @param {string} food_name 
         * @param {string} target_unit 
         * @param {number} target_value 
         * @param {string} [food_replacement_name] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiMealGetCalibratedCreateMealItemRequest: async (food_name: string, target_unit: string, target_value: number, food_replacement_name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiMealGetCalibratedCreateMealItemRequest', 'food_name', food_name)
            // verify required parameter 'target_unit' is not null or undefined
            assertParamExists('appApiMealGetCalibratedCreateMealItemRequest', 'target_unit', target_unit)
            // verify required parameter 'target_value' is not null or undefined
            assertParamExists('appApiMealGetCalibratedCreateMealItemRequest', 'target_value', target_value)
            const localVarPath = `/foods/calibrated_create_meal_item_request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (food_name !== undefined) {
                localVarQueryParameter['food_name'] = food_name;
            }

            if (food_replacement_name !== undefined) {
                localVarQueryParameter['food_replacement_name'] = food_replacement_name;
            }

            if (target_unit !== undefined) {
                localVarQueryParameter['target_unit'] = target_unit;
            }

            if (target_value !== undefined) {
                localVarQueryParameter['target_value'] = target_value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Testing endpoint
         * @param {string} search_text 
         * @param {string} [user_id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealQueueGetNlpAutoLogResults: async (search_text: string, user_id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search_text' is not null or undefined
            assertParamExists('appApiMealQueueGetNlpAutoLogResults', 'search_text', search_text)
            const localVarPath = `/foods/search_by_text_nlp_queue/results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (user_id !== undefined) {
                localVarQueryParameter['user_id'] = user_id;
            }

            if (search_text !== undefined) {
                localVarQueryParameter['search_text'] = search_text;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Testing endpoint
         * @param {string} search_text 
         * @param {string} [user_id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealQueueGetNlpAutoLogResultsSpacy: async (search_text: string, user_id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search_text' is not null or undefined
            assertParamExists('appApiMealQueueGetNlpAutoLogResultsSpacy', 'search_text', search_text)
            const localVarPath = `/foods/search_by_text_nlp_queue/results_spacy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (user_id !== undefined) {
                localVarQueryParameter['user_id'] = user_id;
            }

            if (search_text !== undefined) {
                localVarQueryParameter['search_text'] = search_text;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets food error flags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiNutritionGetErrorFlags: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/foods/error_flags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FoodApi - functional programming interface
 * @export
 */
export const FoodApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FoodApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Export all addons assigned for all foods
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodAddonsGetFoodResponseAddons(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodAddonsGetFoodResponseAddons(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [DEPRECATED] Validates Nutrients and returns warnings
         * @param {string} food_name 
         * @param {FoodDetailsUpdateRequest} [FoodDetailsUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async appApiFoodFoodDetailsApplyWarnings(food_name: string, FoodDetailsUpdateRequest?: FoodDetailsUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodDetailsApplyWarnings(food_name, FoodDetailsUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validates Nutrients and returns warnings
         * @param {string} food_name 
         * @param {FoodDetailsUpdateRequest} [FoodDetailsUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodDetailsApplyWarningsQuery(food_name: string, FoodDetailsUpdateRequest?: FoodDetailsUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodDetailsApplyWarningsQuery(food_name, FoodDetailsUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets calculated food details for a list of food components
         * @param {CalculateCompositeFoodDetailsRequest} [CalculateCompositeFoodDetailsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodDetailsCalculateFoodCompositeDetails(CalculateCompositeFoodDetailsRequest?: CalculateCompositeFoodDetailsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalculateCompositeFoodDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodDetailsCalculateFoodCompositeDetails(CalculateCompositeFoodDetailsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [DEPRECATED] Gets complete food details
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async appApiFoodFoodDetailsGetFoodDetails(food_name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodDetailsGetFoodDetails(food_name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [DEPRECATED] Gets change logs for a food
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async appApiFoodFoodDetailsGetFoodDetailsChangeLogs(food_name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodChangeLogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodDetailsGetFoodDetailsChangeLogs(food_name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets change logs for a food
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodDetailsGetFoodDetailsChangeLogsQuery(food_name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodChangeLogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodDetailsGetFoodDetailsChangeLogsQuery(food_name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [DEPRECATED] Gets food details estimates
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async appApiFoodFoodDetailsGetFoodDetailsEstimates(food_name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppApiFoodFoodDetailsGetFoodDetailsEstimates200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodDetailsGetFoodDetailsEstimates(food_name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets food details estimates
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodDetailsGetFoodDetailsEstimatesQuery(food_name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppApiFoodFoodDetailsGetFoodDetailsEstimates200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodDetailsGetFoodDetailsEstimatesQuery(food_name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets complete food details
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodDetailsGetFoodDetailsQuery(food_name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodDetailsGetFoodDetailsQuery(food_name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the options collection for a specific scope
         * @param {string} scope 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodDetailsGetOptionsCollection(scope: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodOptionCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodDetailsGetOptionsCollection(scope, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets complete food details
         * @param {number} limit 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodDetailsGetRecentFoods(limit: number, status?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FoodDetailsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodDetailsGetRecentFoods(limit, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [DEPRECATED] Update a food\'s associated user ids based on assignment
         * @param {string} food_name 
         * @param {FoodAssignmentRequest} [FoodAssignmentRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async appApiFoodFoodDetailsPatchFoodAssignTask(food_name: string, FoodAssignmentRequest?: FoodAssignmentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodAssignmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodDetailsPatchFoodAssignTask(food_name, FoodAssignmentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a food\'s associated user ids based on assignment
         * @param {string} food_name 
         * @param {FoodAssignmentRequest} [FoodAssignmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodDetailsPatchFoodAssignTaskQuery(food_name: string, FoodAssignmentRequest?: FoodAssignmentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodAssignmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodDetailsPatchFoodAssignTaskQuery(food_name, FoodAssignmentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [DEPRECATED] Updates food details
         * @param {string} food_name 
         * @param {FoodDetailsUpdateRequest} [FoodDetailsUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async appApiFoodFoodDetailsPutFoodDetails(food_name: string, FoodDetailsUpdateRequest?: FoodDetailsUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodDetailsPutFoodDetails(food_name, FoodDetailsUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates food details
         * @param {string} food_name 
         * @param {FoodDetailsUpdateRequest} [FoodDetailsUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodDetailsPutFoodDetailsQuery(food_name: string, FoodDetailsUpdateRequest?: FoodDetailsUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodDetailsPutFoodDetailsQuery(food_name, FoodDetailsUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unset favorite food of the patient
         * @param {number} patient_id 
         * @param {string} [food_name] 
         * @param {number} [favorite_id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodFavoriteDeleteFavorite(patient_id: number, food_name?: string, favorite_id?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodFavoriteDeleteFavorite(patient_id, food_name, favorite_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get favorite food of the patient from the server
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodFavoriteGetFavorite(patient_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FavoriteFoodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodFavoriteGetFavorite(patient_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get favorite food of the patient from the server
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodFavoriteGetFavorites(patient_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FavoriteItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodFavoriteGetFavorites(patient_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new favorite
         * @param {number} patient_id 
         * @param {CreateFavoriteRequest} CreateFavoriteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodFavoritePostFavorite(patient_id: number, CreateFavoriteRequest: CreateFavoriteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FavoriteItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodFavoritePostFavorite(patient_id, CreateFavoriteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set favorite food of the patient
         * @param {number} patient_id 
         * @param {string} [food_name] 
         * @param {UpdateFavoriteRequest} [UpdateFavoriteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodFavoritePutFavorite(patient_id: number, food_name?: string, UpdateFavoriteRequest?: UpdateFavoriteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodFavoritePutFavorite(patient_id, food_name, UpdateFavoriteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [DEPRECATED] Get pre-uploaded food image by food name
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async appApiFoodFoodLookupGetFoodPhoto(food_name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodLookupGetFoodPhoto(food_name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get pre-uploaded food image by food name
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodLookupGetFoodPhotoQuery(food_name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodLookupGetFoodPhotoQuery(food_name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the recent food of the patient from the server
         * @param {number} patient_id 
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodLookupGetPatientFoodSuggestedAddons(patient_id: number, food_name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FoodAddonResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodLookupGetPatientFoodSuggestedAddons(patient_id, food_name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the food information from the server by ndbno
         * @param {string} ndbno 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodNdbnoGetFoodNdbno(ndbno: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodNdbnoGetFoodNdbno(ndbno, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the recent addons of the patient from the server. Includes regular and custom.
         * @param {number} patient_id 
         * @param {string} [time] time in format hh:mm:ss
         * @param {string} [food_name] food name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodRecentGetPatientRecentAddons(patient_id: number, time?: string, food_name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecentAddonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodRecentGetPatientRecentAddons(patient_id, time, food_name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the recent food of the patient from the server
         * @param {number} patient_id 
         * @param {string} time time in format hh:mm:ss
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodRecentGetRecent(patient_id: number, time: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecentFoodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodRecentGetRecent(patient_id, time, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the recent meal items of the patient from the server
         * @param {number} patient_id 
         * @param {string} [time] time in format hh:mm:ss
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodRecentGetRecentMealItems(patient_id: number, time?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecentMealItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodRecentGetRecentMealItems(patient_id, time, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Request a new food item for the database
         * @param {number} user_id 
         * @param {CreateFoodScrapingRequest} CreateFoodScrapingRequest Food Scraping Request Object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodScrapingPostFood(user_id: number, CreateFoodScrapingRequest: CreateFoodScrapingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodScrapingPostFood(user_id, CreateFoodScrapingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search foods by audio file
         * @param {any} audio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchAudioSearchByAudio(audio: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodSearchByAudioResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchAudioSearchByAudio(audio, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search foods by audio file with results analyzed and queued for review (if required)
         * @param {any} audio 
         * @param {string} [meal_name] 
         * @param {string} [note] 
         * @param {string} [meal_nlp_phrase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchAudioSearchByAudioQueue(audio: any, meal_name?: string, note?: string, meal_nlp_phrase?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchAudioSearchByAudioQueue(audio, meal_name, note, meal_nlp_phrase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return complete food terms by autocomplete
         * @param {string} partial_term 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchAzureAutocomplete(partial_term: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CognitiveSearchAutocompleteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchAzureAutocomplete(partial_term, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search foods by brand names. Return maximum of 20 items
         * @param {string} search_text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchAzureSearchBrand(search_text: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CognitiveSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchAzureSearchBrand(search_text, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search foods by manufacturer names. Return maximum of 20 items
         * @param {string} search_text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchAzureSearchManufacturer(search_text: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CognitiveSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchAzureSearchManufacturer(search_text, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return complete food terms by suggest
         * @param {string} partial_term 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchAzureSuggest(partial_term: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CognitiveSearchSuggestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchAzureSuggest(partial_term, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update document on Azure by food name
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchAzureUpdateDocument(food_name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchAzureUpdateDocument(food_name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a list of food items used for client-side search indexing
         * @param {string} [cursor] combination of \&quot;{updated_time}:{id}\&quot;
         * @param {number} [limit] maximum number of items to return (default: 1000)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchGetClientSearchIndex(cursor?: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppApiFoodFoodSearchGetClientSearchIndex200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchGetClientSearchIndex(cursor, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [DEPRECATED] Get the food information from the server
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchGetFood(food_name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchGetFood(food_name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return a data table of all foods and custom items.
         * @param {string} [filter] 
         * @param {number} [limit] 
         * @param {string} [offset] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchGetFoodDatabaseTable(filter?: string, limit?: number, offset?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppApiFoodFoodSearchGetFoodDatabaseTable200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchGetFoodDatabaseTable(filter, limit, offset, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the food information from the server
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchGetFoodQuery(food_name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchGetFoodQuery(food_name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search foods by text. Return maximum of 20 items
         * @param {string} q 
         * @param {string} [context_lang] 
         * @param {number} [context_user_id] 
         * @param {string} [context_meal_name] 
         * @param {string} [context_food_names] 
         * @param {string} [context_addon_of] 
         * @param {string} [context_index] 
         * @param {boolean} [context_semantic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchGetFoodSearchV2Interactive(q: string, context_lang?: string, context_user_id?: number, context_meal_name?: string, context_food_names?: string, context_addon_of?: string, context_index?: string, context_semantic?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodSearchV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchGetFoodSearchV2Interactive(q, context_lang, context_user_id, context_meal_name, context_food_names, context_addon_of, context_index, context_semantic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a URL for uploading a meal photo (likely signed S3 URL)
         * @param {string} ext image file extension
         * @param {string} key image batch key
         * @param {number} idx image index in batch
         * @param {string} [suffix] optional suffix for the file (ex, &#x60;\&quot;depth-map\&quot;&#x60;)
         * @param {string} [metadata] optional JSON-encoded metadata to include in the x-goog-meta-rx-meta header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchGetMealPhotoUploadUrl(ext: string, key: string, idx: number, suffix?: string, metadata?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppApiFoodFoodSearchGetMealPhotoUploadUrl200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchGetMealPhotoUploadUrl(ext, key, idx, suffix, metadata, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [DEPRECATED] Get the recent food of the patient from the server
         * @param {number} patient_id 
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchGetPatientFood(patient_id: number, food_name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchGetPatientFood(patient_id, food_name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the recent food of the patient from the server
         * @param {number} patient_id 
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchGetPatientFoodQuery(patient_id: number, food_name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchGetPatientFoodQuery(patient_id, food_name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Log a meal with descriptive words with results analyzed and queued for review (if required)
         * @param {string} search_text 
         * @param {CreateSearchByTextNlpQueueRequest} CreateSearchByTextNlpQueueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchNlpPostSearchByTextNlpQueue(search_text: string, CreateSearchByTextNlpQueueRequest: CreateSearchByTextNlpQueueRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchNlpPostSearchByTextNlpQueue(search_text, CreateSearchByTextNlpQueueRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search foods by sentence and analyze using an NLP algorithm. Return maximum of 20 items
         * @param {string} search_text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchNlpSearchByTextNlp(search_text: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodSearchByTextResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchNlpSearchByTextNlp(search_text, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Log a meal with descriptive words with results analyzed and queued for review (if required)
         * @param {string} search_text 
         * @param {string} [meal_date] 
         * @param {string} [meal_time] 
         * @param {'breakfast' | 'lunch' | 'dinner' | 'snack'} [meal_name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchNlpSearchByTextNlpQueue(search_text: string, meal_date?: string, meal_time?: string, meal_name?: 'breakfast' | 'lunch' | 'dinner' | 'snack', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchNlpSearchByTextNlpQueue(search_text, meal_date, meal_time, meal_name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search foods by text. Return maximum of 20 items
         * @param {string} search_text 
         * @param {string} [context_user_id] 
         * @param {boolean} [extended_query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchSearchByCognitive(search_text: string, context_user_id?: string, extended_query?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodSearchByTextResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchSearchByCognitive(search_text, context_user_id, extended_query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search for foods by NLP phrase.
         * @param {string} search_text 
         * @param {number} queue_id 
         * @param {boolean} [use_cache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchSearchByNlpPhrase(search_text: string, queue_id: number, use_cache?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodSearchByNlpPhraseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchSearchByNlpPhrase(search_text, queue_id, use_cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search foods by photo
         * @param {any} photo 
         * @param {any} [photo2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchSearchByPhoto(photo: any, photo2?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodSearchByPhotoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchSearchByPhoto(photo, photo2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search foods by photo with fast food entry sorting algorithm applied
         * @param {any} photo 
         * @param {any} [photo2] 
         * @param {number} [num_items] 
         * @param {string} [sizing_input] 
         * @param {string} [meal_name] 
         * @param {string} [note] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchSearchByPhotoFfe(photo: any, photo2?: any, num_items?: number, sizing_input?: string, meal_name?: string, note?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodSearchByPhotoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchSearchByPhotoFfe(photo, photo2, num_items, sizing_input, meal_name, note, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search foods by photo with FFE algorithm applied and results put in a queue for backend review
         * @param {any} [photo] 
         * @param {any} [photo2] 
         * @param {Array<string>} [photo_urls] 
         * @param {number} [num_items] 
         * @param {string} [sizing_input] 
         * @param {string} [meal_name] 
         * @param {string} [meal_time] time in format hh:mm:ss
         * @param {string} [meal_date] calendar date in format YYYY-MM-DD
         * @param {string} [note] 
         * @param {string} [photo_id] 
         * @param {string} [photo_timings_encoded] 
         * @param {PreparationMethodEnum} [preparation_method] 
         * @param {string} [queue_metadata] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchSearchByPhotoFfeQueue(photo?: any, photo2?: any, photo_urls?: Array<string>, num_items?: number, sizing_input?: string, meal_name?: string, meal_time?: string, meal_date?: string, note?: string, photo_id?: string, photo_timings_encoded?: string, preparation_method?: PreparationMethodEnum, queue_metadata?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchSearchByPhotoFfeQueue(photo, photo2, photo_urls, num_items, sizing_input, meal_name, meal_time, meal_date, note, photo_id, photo_timings_encoded, preparation_method, queue_metadata, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary OCR a photo to return a list of words found
         * @param {any} photo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchSearchByPhotoOcr(photo: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodSearchByPhotoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchSearchByPhotoOcr(photo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search foods by text. Return maximum of 20 items
         * @param {string} search_text 
         * @param {string} [context_user_id] 
         * @param {boolean} [extended_query] 
         * @param {boolean} [food_name_only] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchSearchByText(search_text: string, context_user_id?: string, extended_query?: boolean, food_name_only?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodSearchByTextResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchSearchByText(search_text, context_user_id, extended_query, food_name_only, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search foods with diet restriction
         * @param {string} search_text 
         * @param {string} diet_restriction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchSearchDietRestrictions(search_text: string, diet_restriction: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateMealItemRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchSearchDietRestrictions(search_text, diet_restriction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload a meal photo
         * @param {any} photo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodSearchUploadPhoto(photo: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhotoUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodSearchUploadPhoto(photo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get USDA food by fdcId
         * @param {string} fdcid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodUsdaGetUsdaFood(fdcid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsdaApiNutritionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodUsdaGetUsdaFood(fdcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get USDA food by fdcId
         * @param {string} fdcid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodUsdaGetUsdaFoodData(fdcid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodUsdaGetUsdaFoodData(fdcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search foods by text. Return maximum of 20 items
         * @param {string} search_term 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodUsdaSearchUsdaDatabaseApi(search_term: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsdaApiSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodUsdaSearchUsdaDatabaseApi(search_term, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary email csv from usda food data by fdcId
         * @param {string} fdcid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodUsdaSendData(fdcid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodUsdaSendData(fdcid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [DEPRECATED] Delete a whiteboard note for a food from the food editor
         * @param {string} food_name 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async appApiFoodFoodWhiteboardDeleteWhiteboardItem(food_name: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodWhiteboardDeleteWhiteboardItem(food_name, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a whiteboard note for a food from the food editor
         * @param {string} food_name 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodWhiteboardDeleteWhiteboardItemQuery(food_name: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodWhiteboardDeleteWhiteboardItemQuery(food_name, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [DEPRECATED] Get the whiteboard notes for a food in the food editor
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async appApiFoodFoodWhiteboardGetRelevantQueues(food_name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MealPhotoQueueResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodWhiteboardGetRelevantQueues(food_name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the whiteboard notes for a food in the food editor
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodWhiteboardGetRelevantQueuesQuery(food_name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MealPhotoQueueResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodWhiteboardGetRelevantQueuesQuery(food_name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [DEPRECATED] Get the whiteboard notes for a food in the food editor
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async appApiFoodFoodWhiteboardGetWhiteboardItems(food_name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FoodWhiteboardItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodWhiteboardGetWhiteboardItems(food_name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the whiteboard notes for a food in the food editor
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodWhiteboardGetWhiteboardItemsQuery(food_name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FoodWhiteboardItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodWhiteboardGetWhiteboardItemsQuery(food_name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [DEPRECATED] Create a new whiteboard note for a food in the food editor
         * @param {string} food_name 
         * @param {FoodWhiteboardCreateUpdateRequest} FoodWhiteboardCreateUpdateRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async appApiFoodFoodWhiteboardPostWhiteboardItem(food_name: string, FoodWhiteboardCreateUpdateRequest: FoodWhiteboardCreateUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodWhiteboardItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodWhiteboardPostWhiteboardItem(food_name, FoodWhiteboardCreateUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new whiteboard note for a food in the food editor
         * @param {string} food_name 
         * @param {FoodWhiteboardCreateUpdateRequest} FoodWhiteboardCreateUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodWhiteboardPostWhiteboardItemQuery(food_name: string, FoodWhiteboardCreateUpdateRequest: FoodWhiteboardCreateUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodWhiteboardItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodWhiteboardPostWhiteboardItemQuery(food_name, FoodWhiteboardCreateUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [DEPRECATED] Update an existing whiteboard note for a food in the food editor
         * @param {string} food_name 
         * @param {number} id 
         * @param {FoodWhiteboardCreateUpdateRequest} FoodWhiteboardCreateUpdateRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async appApiFoodFoodWhiteboardPutWhiteboardItem(food_name: string, id: number, FoodWhiteboardCreateUpdateRequest: FoodWhiteboardCreateUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodWhiteboardItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodWhiteboardPutWhiteboardItem(food_name, id, FoodWhiteboardCreateUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing whiteboard note for a food in the food editor
         * @param {string} food_name 
         * @param {number} id 
         * @param {FoodWhiteboardCreateUpdateRequest} FoodWhiteboardCreateUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodFoodWhiteboardPutWhiteboardItemQuery(food_name: string, id: number, FoodWhiteboardCreateUpdateRequest: FoodWhiteboardCreateUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FoodWhiteboardItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodFoodWhiteboardPutWhiteboardItemQuery(food_name, id, FoodWhiteboardCreateUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the ontologies for the food editor
         * @param {boolean} [include_archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiFoodOntologyGetAllOntologies(include_archived?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FoodOntologyVTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiFoodOntologyGetAllOntologies(include_archived, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [DEPRECATED] get a calibrated meal item request object
         * @param {string} food_name 
         * @param {string} target_unit 
         * @param {number} target_value 
         * @param {string} [food_replacement_name] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async appApiMealGetCalibratedCreateMealItemRequest(food_name: string, target_unit: string, target_value: number, food_replacement_name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateMealItemRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealGetCalibratedCreateMealItemRequest(food_name, target_unit, target_value, food_replacement_name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Testing endpoint
         * @param {string} search_text 
         * @param {string} [user_id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealQueueGetNlpAutoLogResults(search_text: string, user_id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreateMealItemRequest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealQueueGetNlpAutoLogResults(search_text, user_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Testing endpoint
         * @param {string} search_text 
         * @param {string} [user_id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealQueueGetNlpAutoLogResultsSpacy(search_text: string, user_id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreateMealItemRequest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealQueueGetNlpAutoLogResultsSpacy(search_text, user_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets food error flags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiNutritionGetErrorFlags(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiNutritionGetErrorFlags(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FoodApi - factory interface
 * @export
 */
export const FoodApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FoodApiFp(configuration)
    return {
        /**
         * 
         * @summary Export all addons assigned for all foods
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodAddonsGetFoodResponseAddons(options?: any): AxiosPromise<string> {
            return localVarFp.appApiFoodFoodAddonsGetFoodResponseAddons(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [DEPRECATED] Validates Nutrients and returns warnings
         * @param {string} food_name 
         * @param {FoodDetailsUpdateRequest} [FoodDetailsUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsApplyWarnings(food_name: string, FoodDetailsUpdateRequest?: FoodDetailsUpdateRequest, options?: any): AxiosPromise<FoodDetailsResponse> {
            return localVarFp.appApiFoodFoodDetailsApplyWarnings(food_name, FoodDetailsUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validates Nutrients and returns warnings
         * @param {string} food_name 
         * @param {FoodDetailsUpdateRequest} [FoodDetailsUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsApplyWarningsQuery(food_name: string, FoodDetailsUpdateRequest?: FoodDetailsUpdateRequest, options?: any): AxiosPromise<FoodDetailsResponse> {
            return localVarFp.appApiFoodFoodDetailsApplyWarningsQuery(food_name, FoodDetailsUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets calculated food details for a list of food components
         * @param {CalculateCompositeFoodDetailsRequest} [CalculateCompositeFoodDetailsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsCalculateFoodCompositeDetails(CalculateCompositeFoodDetailsRequest?: CalculateCompositeFoodDetailsRequest, options?: any): AxiosPromise<CalculateCompositeFoodDetailsResponse> {
            return localVarFp.appApiFoodFoodDetailsCalculateFoodCompositeDetails(CalculateCompositeFoodDetailsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [DEPRECATED] Gets complete food details
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsGetFoodDetails(food_name: string, options?: any): AxiosPromise<FoodDetailsResponse> {
            return localVarFp.appApiFoodFoodDetailsGetFoodDetails(food_name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [DEPRECATED] Gets change logs for a food
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsGetFoodDetailsChangeLogs(food_name: string, options?: any): AxiosPromise<FoodChangeLogResponse> {
            return localVarFp.appApiFoodFoodDetailsGetFoodDetailsChangeLogs(food_name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets change logs for a food
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsGetFoodDetailsChangeLogsQuery(food_name: string, options?: any): AxiosPromise<FoodChangeLogResponse> {
            return localVarFp.appApiFoodFoodDetailsGetFoodDetailsChangeLogsQuery(food_name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [DEPRECATED] Gets food details estimates
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsGetFoodDetailsEstimates(food_name: string, options?: any): AxiosPromise<AppApiFoodFoodDetailsGetFoodDetailsEstimates200Response> {
            return localVarFp.appApiFoodFoodDetailsGetFoodDetailsEstimates(food_name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets food details estimates
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsGetFoodDetailsEstimatesQuery(food_name: string, options?: any): AxiosPromise<AppApiFoodFoodDetailsGetFoodDetailsEstimates200Response> {
            return localVarFp.appApiFoodFoodDetailsGetFoodDetailsEstimatesQuery(food_name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets complete food details
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsGetFoodDetailsQuery(food_name: string, options?: any): AxiosPromise<FoodDetailsResponse> {
            return localVarFp.appApiFoodFoodDetailsGetFoodDetailsQuery(food_name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the options collection for a specific scope
         * @param {string} scope 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsGetOptionsCollection(scope: string, options?: any): AxiosPromise<FoodOptionCollection> {
            return localVarFp.appApiFoodFoodDetailsGetOptionsCollection(scope, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets complete food details
         * @param {number} limit 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsGetRecentFoods(limit: number, status?: string, options?: any): AxiosPromise<Array<FoodDetailsResponse>> {
            return localVarFp.appApiFoodFoodDetailsGetRecentFoods(limit, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [DEPRECATED] Update a food\'s associated user ids based on assignment
         * @param {string} food_name 
         * @param {FoodAssignmentRequest} [FoodAssignmentRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsPatchFoodAssignTask(food_name: string, FoodAssignmentRequest?: FoodAssignmentRequest, options?: any): AxiosPromise<FoodAssignmentResponse> {
            return localVarFp.appApiFoodFoodDetailsPatchFoodAssignTask(food_name, FoodAssignmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a food\'s associated user ids based on assignment
         * @param {string} food_name 
         * @param {FoodAssignmentRequest} [FoodAssignmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsPatchFoodAssignTaskQuery(food_name: string, FoodAssignmentRequest?: FoodAssignmentRequest, options?: any): AxiosPromise<FoodAssignmentResponse> {
            return localVarFp.appApiFoodFoodDetailsPatchFoodAssignTaskQuery(food_name, FoodAssignmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [DEPRECATED] Updates food details
         * @param {string} food_name 
         * @param {FoodDetailsUpdateRequest} [FoodDetailsUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsPutFoodDetails(food_name: string, FoodDetailsUpdateRequest?: FoodDetailsUpdateRequest, options?: any): AxiosPromise<FoodDetailsResponse> {
            return localVarFp.appApiFoodFoodDetailsPutFoodDetails(food_name, FoodDetailsUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates food details
         * @param {string} food_name 
         * @param {FoodDetailsUpdateRequest} [FoodDetailsUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodDetailsPutFoodDetailsQuery(food_name: string, FoodDetailsUpdateRequest?: FoodDetailsUpdateRequest, options?: any): AxiosPromise<FoodDetailsResponse> {
            return localVarFp.appApiFoodFoodDetailsPutFoodDetailsQuery(food_name, FoodDetailsUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unset favorite food of the patient
         * @param {number} patient_id 
         * @param {string} [food_name] 
         * @param {number} [favorite_id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodFavoriteDeleteFavorite(patient_id: number, food_name?: string, favorite_id?: number, options?: any): AxiosPromise<void> {
            return localVarFp.appApiFoodFoodFavoriteDeleteFavorite(patient_id, food_name, favorite_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get favorite food of the patient from the server
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodFavoriteGetFavorite(patient_id: number, options?: any): AxiosPromise<FavoriteFoodResponse> {
            return localVarFp.appApiFoodFoodFavoriteGetFavorite(patient_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get favorite food of the patient from the server
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodFavoriteGetFavorites(patient_id: number, options?: any): AxiosPromise<Array<FavoriteItemResponse>> {
            return localVarFp.appApiFoodFoodFavoriteGetFavorites(patient_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new favorite
         * @param {number} patient_id 
         * @param {CreateFavoriteRequest} CreateFavoriteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodFavoritePostFavorite(patient_id: number, CreateFavoriteRequest: CreateFavoriteRequest, options?: any): AxiosPromise<FavoriteItemResponse> {
            return localVarFp.appApiFoodFoodFavoritePostFavorite(patient_id, CreateFavoriteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set favorite food of the patient
         * @param {number} patient_id 
         * @param {string} [food_name] 
         * @param {UpdateFavoriteRequest} [UpdateFavoriteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodFavoritePutFavorite(patient_id: number, food_name?: string, UpdateFavoriteRequest?: UpdateFavoriteRequest, options?: any): AxiosPromise<void> {
            return localVarFp.appApiFoodFoodFavoritePutFavorite(patient_id, food_name, UpdateFavoriteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [DEPRECATED] Get pre-uploaded food image by food name
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodLookupGetFoodPhoto(food_name: string, options?: any): AxiosPromise<void> {
            return localVarFp.appApiFoodFoodLookupGetFoodPhoto(food_name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pre-uploaded food image by food name
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodLookupGetFoodPhotoQuery(food_name: string, options?: any): AxiosPromise<void> {
            return localVarFp.appApiFoodFoodLookupGetFoodPhotoQuery(food_name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the recent food of the patient from the server
         * @param {number} patient_id 
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodLookupGetPatientFoodSuggestedAddons(patient_id: number, food_name: string, options?: any): AxiosPromise<Array<FoodAddonResponse>> {
            return localVarFp.appApiFoodFoodLookupGetPatientFoodSuggestedAddons(patient_id, food_name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the food information from the server by ndbno
         * @param {string} ndbno 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodNdbnoGetFoodNdbno(ndbno: string, options?: any): AxiosPromise<FoodResponse> {
            return localVarFp.appApiFoodFoodNdbnoGetFoodNdbno(ndbno, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the recent addons of the patient from the server. Includes regular and custom.
         * @param {number} patient_id 
         * @param {string} [time] time in format hh:mm:ss
         * @param {string} [food_name] food name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodRecentGetPatientRecentAddons(patient_id: number, time?: string, food_name?: string, options?: any): AxiosPromise<RecentAddonResponse> {
            return localVarFp.appApiFoodFoodRecentGetPatientRecentAddons(patient_id, time, food_name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the recent food of the patient from the server
         * @param {number} patient_id 
         * @param {string} time time in format hh:mm:ss
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodRecentGetRecent(patient_id: number, time: string, options?: any): AxiosPromise<RecentFoodResponse> {
            return localVarFp.appApiFoodFoodRecentGetRecent(patient_id, time, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the recent meal items of the patient from the server
         * @param {number} patient_id 
         * @param {string} [time] time in format hh:mm:ss
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodRecentGetRecentMealItems(patient_id: number, time?: string, options?: any): AxiosPromise<RecentMealItemResponse> {
            return localVarFp.appApiFoodFoodRecentGetRecentMealItems(patient_id, time, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request a new food item for the database
         * @param {number} user_id 
         * @param {CreateFoodScrapingRequest} CreateFoodScrapingRequest Food Scraping Request Object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodScrapingPostFood(user_id: number, CreateFoodScrapingRequest: CreateFoodScrapingRequest, options?: any): AxiosPromise<void> {
            return localVarFp.appApiFoodFoodScrapingPostFood(user_id, CreateFoodScrapingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search foods by audio file
         * @param {any} audio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchAudioSearchByAudio(audio: any, options?: any): AxiosPromise<FoodSearchByAudioResponse> {
            return localVarFp.appApiFoodFoodSearchAudioSearchByAudio(audio, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search foods by audio file with results analyzed and queued for review (if required)
         * @param {any} audio 
         * @param {string} [meal_name] 
         * @param {string} [note] 
         * @param {string} [meal_nlp_phrase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchAudioSearchByAudioQueue(audio: any, meal_name?: string, note?: string, meal_nlp_phrase?: string, options?: any): AxiosPromise<MealResponse> {
            return localVarFp.appApiFoodFoodSearchAudioSearchByAudioQueue(audio, meal_name, note, meal_nlp_phrase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return complete food terms by autocomplete
         * @param {string} partial_term 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchAzureAutocomplete(partial_term: string, options?: any): AxiosPromise<CognitiveSearchAutocompleteResponse> {
            return localVarFp.appApiFoodFoodSearchAzureAutocomplete(partial_term, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search foods by brand names. Return maximum of 20 items
         * @param {string} search_text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchAzureSearchBrand(search_text: string, options?: any): AxiosPromise<CognitiveSearchResponse> {
            return localVarFp.appApiFoodFoodSearchAzureSearchBrand(search_text, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search foods by manufacturer names. Return maximum of 20 items
         * @param {string} search_text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchAzureSearchManufacturer(search_text: string, options?: any): AxiosPromise<CognitiveSearchResponse> {
            return localVarFp.appApiFoodFoodSearchAzureSearchManufacturer(search_text, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return complete food terms by suggest
         * @param {string} partial_term 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchAzureSuggest(partial_term: string, options?: any): AxiosPromise<CognitiveSearchSuggestResponse> {
            return localVarFp.appApiFoodFoodSearchAzureSuggest(partial_term, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update document on Azure by food name
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchAzureUpdateDocument(food_name: string, options?: any): AxiosPromise<void> {
            return localVarFp.appApiFoodFoodSearchAzureUpdateDocument(food_name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of food items used for client-side search indexing
         * @param {string} [cursor] combination of \&quot;{updated_time}:{id}\&quot;
         * @param {number} [limit] maximum number of items to return (default: 1000)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchGetClientSearchIndex(cursor?: string, limit?: number, options?: any): AxiosPromise<AppApiFoodFoodSearchGetClientSearchIndex200Response> {
            return localVarFp.appApiFoodFoodSearchGetClientSearchIndex(cursor, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [DEPRECATED] Get the food information from the server
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchGetFood(food_name: string, options?: any): AxiosPromise<FoodResponse> {
            return localVarFp.appApiFoodFoodSearchGetFood(food_name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return a data table of all foods and custom items.
         * @param {string} [filter] 
         * @param {number} [limit] 
         * @param {string} [offset] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchGetFoodDatabaseTable(filter?: string, limit?: number, offset?: string, order?: string, options?: any): AxiosPromise<AppApiFoodFoodSearchGetFoodDatabaseTable200Response> {
            return localVarFp.appApiFoodFoodSearchGetFoodDatabaseTable(filter, limit, offset, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the food information from the server
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchGetFoodQuery(food_name: string, options?: any): AxiosPromise<FoodResponse> {
            return localVarFp.appApiFoodFoodSearchGetFoodQuery(food_name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search foods by text. Return maximum of 20 items
         * @param {string} q 
         * @param {string} [context_lang] 
         * @param {number} [context_user_id] 
         * @param {string} [context_meal_name] 
         * @param {string} [context_food_names] 
         * @param {string} [context_addon_of] 
         * @param {string} [context_index] 
         * @param {boolean} [context_semantic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchGetFoodSearchV2Interactive(q: string, context_lang?: string, context_user_id?: number, context_meal_name?: string, context_food_names?: string, context_addon_of?: string, context_index?: string, context_semantic?: boolean, options?: any): AxiosPromise<FoodSearchV2Response> {
            return localVarFp.appApiFoodFoodSearchGetFoodSearchV2Interactive(q, context_lang, context_user_id, context_meal_name, context_food_names, context_addon_of, context_index, context_semantic, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a URL for uploading a meal photo (likely signed S3 URL)
         * @param {string} ext image file extension
         * @param {string} key image batch key
         * @param {number} idx image index in batch
         * @param {string} [suffix] optional suffix for the file (ex, &#x60;\&quot;depth-map\&quot;&#x60;)
         * @param {string} [metadata] optional JSON-encoded metadata to include in the x-goog-meta-rx-meta header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchGetMealPhotoUploadUrl(ext: string, key: string, idx: number, suffix?: string, metadata?: string, options?: any): AxiosPromise<AppApiFoodFoodSearchGetMealPhotoUploadUrl200Response> {
            return localVarFp.appApiFoodFoodSearchGetMealPhotoUploadUrl(ext, key, idx, suffix, metadata, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [DEPRECATED] Get the recent food of the patient from the server
         * @param {number} patient_id 
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchGetPatientFood(patient_id: number, food_name: string, options?: any): AxiosPromise<FoodResponse> {
            return localVarFp.appApiFoodFoodSearchGetPatientFood(patient_id, food_name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the recent food of the patient from the server
         * @param {number} patient_id 
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchGetPatientFoodQuery(patient_id: number, food_name: string, options?: any): AxiosPromise<FoodResponse> {
            return localVarFp.appApiFoodFoodSearchGetPatientFoodQuery(patient_id, food_name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Log a meal with descriptive words with results analyzed and queued for review (if required)
         * @param {string} search_text 
         * @param {CreateSearchByTextNlpQueueRequest} CreateSearchByTextNlpQueueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchNlpPostSearchByTextNlpQueue(search_text: string, CreateSearchByTextNlpQueueRequest: CreateSearchByTextNlpQueueRequest, options?: any): AxiosPromise<MealResponse> {
            return localVarFp.appApiFoodFoodSearchNlpPostSearchByTextNlpQueue(search_text, CreateSearchByTextNlpQueueRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search foods by sentence and analyze using an NLP algorithm. Return maximum of 20 items
         * @param {string} search_text 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchNlpSearchByTextNlp(search_text: string, options?: any): AxiosPromise<FoodSearchByTextResponse> {
            return localVarFp.appApiFoodFoodSearchNlpSearchByTextNlp(search_text, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Log a meal with descriptive words with results analyzed and queued for review (if required)
         * @param {string} search_text 
         * @param {string} [meal_date] 
         * @param {string} [meal_time] 
         * @param {'breakfast' | 'lunch' | 'dinner' | 'snack'} [meal_name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchNlpSearchByTextNlpQueue(search_text: string, meal_date?: string, meal_time?: string, meal_name?: 'breakfast' | 'lunch' | 'dinner' | 'snack', options?: any): AxiosPromise<MealResponse> {
            return localVarFp.appApiFoodFoodSearchNlpSearchByTextNlpQueue(search_text, meal_date, meal_time, meal_name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search foods by text. Return maximum of 20 items
         * @param {string} search_text 
         * @param {string} [context_user_id] 
         * @param {boolean} [extended_query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchSearchByCognitive(search_text: string, context_user_id?: string, extended_query?: boolean, options?: any): AxiosPromise<FoodSearchByTextResponse> {
            return localVarFp.appApiFoodFoodSearchSearchByCognitive(search_text, context_user_id, extended_query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search for foods by NLP phrase.
         * @param {string} search_text 
         * @param {number} queue_id 
         * @param {boolean} [use_cache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchSearchByNlpPhrase(search_text: string, queue_id: number, use_cache?: boolean, options?: any): AxiosPromise<FoodSearchByNlpPhraseResponse> {
            return localVarFp.appApiFoodFoodSearchSearchByNlpPhrase(search_text, queue_id, use_cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search foods by photo
         * @param {any} photo 
         * @param {any} [photo2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchSearchByPhoto(photo: any, photo2?: any, options?: any): AxiosPromise<FoodSearchByPhotoResponse> {
            return localVarFp.appApiFoodFoodSearchSearchByPhoto(photo, photo2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search foods by photo with fast food entry sorting algorithm applied
         * @param {any} photo 
         * @param {any} [photo2] 
         * @param {number} [num_items] 
         * @param {string} [sizing_input] 
         * @param {string} [meal_name] 
         * @param {string} [note] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchSearchByPhotoFfe(photo: any, photo2?: any, num_items?: number, sizing_input?: string, meal_name?: string, note?: string, options?: any): AxiosPromise<FoodSearchByPhotoResponse> {
            return localVarFp.appApiFoodFoodSearchSearchByPhotoFfe(photo, photo2, num_items, sizing_input, meal_name, note, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search foods by photo with FFE algorithm applied and results put in a queue for backend review
         * @param {any} [photo] 
         * @param {any} [photo2] 
         * @param {Array<string>} [photo_urls] 
         * @param {number} [num_items] 
         * @param {string} [sizing_input] 
         * @param {string} [meal_name] 
         * @param {string} [meal_time] time in format hh:mm:ss
         * @param {string} [meal_date] calendar date in format YYYY-MM-DD
         * @param {string} [note] 
         * @param {string} [photo_id] 
         * @param {string} [photo_timings_encoded] 
         * @param {PreparationMethodEnum} [preparation_method] 
         * @param {string} [queue_metadata] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchSearchByPhotoFfeQueue(photo?: any, photo2?: any, photo_urls?: Array<string>, num_items?: number, sizing_input?: string, meal_name?: string, meal_time?: string, meal_date?: string, note?: string, photo_id?: string, photo_timings_encoded?: string, preparation_method?: PreparationMethodEnum, queue_metadata?: string, options?: any): AxiosPromise<MealResponse> {
            return localVarFp.appApiFoodFoodSearchSearchByPhotoFfeQueue(photo, photo2, photo_urls, num_items, sizing_input, meal_name, meal_time, meal_date, note, photo_id, photo_timings_encoded, preparation_method, queue_metadata, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary OCR a photo to return a list of words found
         * @param {any} photo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchSearchByPhotoOcr(photo: any, options?: any): AxiosPromise<FoodSearchByPhotoResponse> {
            return localVarFp.appApiFoodFoodSearchSearchByPhotoOcr(photo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search foods by text. Return maximum of 20 items
         * @param {string} search_text 
         * @param {string} [context_user_id] 
         * @param {boolean} [extended_query] 
         * @param {boolean} [food_name_only] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchSearchByText(search_text: string, context_user_id?: string, extended_query?: boolean, food_name_only?: boolean, options?: any): AxiosPromise<FoodSearchByTextResponse> {
            return localVarFp.appApiFoodFoodSearchSearchByText(search_text, context_user_id, extended_query, food_name_only, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search foods with diet restriction
         * @param {string} search_text 
         * @param {string} diet_restriction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchSearchDietRestrictions(search_text: string, diet_restriction: string, options?: any): AxiosPromise<CreateMealItemRequest> {
            return localVarFp.appApiFoodFoodSearchSearchDietRestrictions(search_text, diet_restriction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a meal photo
         * @param {any} photo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodSearchUploadPhoto(photo: any, options?: any): AxiosPromise<PhotoUploadResponse> {
            return localVarFp.appApiFoodFoodSearchUploadPhoto(photo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get USDA food by fdcId
         * @param {string} fdcid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodUsdaGetUsdaFood(fdcid: string, options?: any): AxiosPromise<UsdaApiNutritionResponse> {
            return localVarFp.appApiFoodFoodUsdaGetUsdaFood(fdcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get USDA food by fdcId
         * @param {string} fdcid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodUsdaGetUsdaFoodData(fdcid: string, options?: any): AxiosPromise<string> {
            return localVarFp.appApiFoodFoodUsdaGetUsdaFoodData(fdcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search foods by text. Return maximum of 20 items
         * @param {string} search_term 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodUsdaSearchUsdaDatabaseApi(search_term: string, options?: any): AxiosPromise<UsdaApiSearchResponse> {
            return localVarFp.appApiFoodFoodUsdaSearchUsdaDatabaseApi(search_term, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary email csv from usda food data by fdcId
         * @param {string} fdcid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodUsdaSendData(fdcid: string, options?: any): AxiosPromise<void> {
            return localVarFp.appApiFoodFoodUsdaSendData(fdcid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [DEPRECATED] Delete a whiteboard note for a food from the food editor
         * @param {string} food_name 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodWhiteboardDeleteWhiteboardItem(food_name: string, id: number, options?: any): AxiosPromise<void> {
            return localVarFp.appApiFoodFoodWhiteboardDeleteWhiteboardItem(food_name, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a whiteboard note for a food from the food editor
         * @param {string} food_name 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodWhiteboardDeleteWhiteboardItemQuery(food_name: string, id: number, options?: any): AxiosPromise<void> {
            return localVarFp.appApiFoodFoodWhiteboardDeleteWhiteboardItemQuery(food_name, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [DEPRECATED] Get the whiteboard notes for a food in the food editor
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodWhiteboardGetRelevantQueues(food_name: string, options?: any): AxiosPromise<Array<MealPhotoQueueResponse>> {
            return localVarFp.appApiFoodFoodWhiteboardGetRelevantQueues(food_name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the whiteboard notes for a food in the food editor
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodWhiteboardGetRelevantQueuesQuery(food_name: string, options?: any): AxiosPromise<Array<MealPhotoQueueResponse>> {
            return localVarFp.appApiFoodFoodWhiteboardGetRelevantQueuesQuery(food_name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [DEPRECATED] Get the whiteboard notes for a food in the food editor
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodWhiteboardGetWhiteboardItems(food_name: string, options?: any): AxiosPromise<Array<FoodWhiteboardItemResponse>> {
            return localVarFp.appApiFoodFoodWhiteboardGetWhiteboardItems(food_name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the whiteboard notes for a food in the food editor
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodWhiteboardGetWhiteboardItemsQuery(food_name: string, options?: any): AxiosPromise<Array<FoodWhiteboardItemResponse>> {
            return localVarFp.appApiFoodFoodWhiteboardGetWhiteboardItemsQuery(food_name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [DEPRECATED] Create a new whiteboard note for a food in the food editor
         * @param {string} food_name 
         * @param {FoodWhiteboardCreateUpdateRequest} FoodWhiteboardCreateUpdateRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodWhiteboardPostWhiteboardItem(food_name: string, FoodWhiteboardCreateUpdateRequest: FoodWhiteboardCreateUpdateRequest, options?: any): AxiosPromise<FoodWhiteboardItemResponse> {
            return localVarFp.appApiFoodFoodWhiteboardPostWhiteboardItem(food_name, FoodWhiteboardCreateUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new whiteboard note for a food in the food editor
         * @param {string} food_name 
         * @param {FoodWhiteboardCreateUpdateRequest} FoodWhiteboardCreateUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodWhiteboardPostWhiteboardItemQuery(food_name: string, FoodWhiteboardCreateUpdateRequest: FoodWhiteboardCreateUpdateRequest, options?: any): AxiosPromise<FoodWhiteboardItemResponse> {
            return localVarFp.appApiFoodFoodWhiteboardPostWhiteboardItemQuery(food_name, FoodWhiteboardCreateUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [DEPRECATED] Update an existing whiteboard note for a food in the food editor
         * @param {string} food_name 
         * @param {number} id 
         * @param {FoodWhiteboardCreateUpdateRequest} FoodWhiteboardCreateUpdateRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiFoodFoodWhiteboardPutWhiteboardItem(food_name: string, id: number, FoodWhiteboardCreateUpdateRequest: FoodWhiteboardCreateUpdateRequest, options?: any): AxiosPromise<FoodWhiteboardItemResponse> {
            return localVarFp.appApiFoodFoodWhiteboardPutWhiteboardItem(food_name, id, FoodWhiteboardCreateUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing whiteboard note for a food in the food editor
         * @param {string} food_name 
         * @param {number} id 
         * @param {FoodWhiteboardCreateUpdateRequest} FoodWhiteboardCreateUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodFoodWhiteboardPutWhiteboardItemQuery(food_name: string, id: number, FoodWhiteboardCreateUpdateRequest: FoodWhiteboardCreateUpdateRequest, options?: any): AxiosPromise<FoodWhiteboardItemResponse> {
            return localVarFp.appApiFoodFoodWhiteboardPutWhiteboardItemQuery(food_name, id, FoodWhiteboardCreateUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the ontologies for the food editor
         * @param {boolean} [include_archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiFoodOntologyGetAllOntologies(include_archived?: boolean, options?: any): AxiosPromise<Array<FoodOntologyVTO>> {
            return localVarFp.appApiFoodOntologyGetAllOntologies(include_archived, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [DEPRECATED] get a calibrated meal item request object
         * @param {string} food_name 
         * @param {string} target_unit 
         * @param {number} target_value 
         * @param {string} [food_replacement_name] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appApiMealGetCalibratedCreateMealItemRequest(food_name: string, target_unit: string, target_value: number, food_replacement_name?: string, options?: any): AxiosPromise<CreateMealItemRequest> {
            return localVarFp.appApiMealGetCalibratedCreateMealItemRequest(food_name, target_unit, target_value, food_replacement_name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Testing endpoint
         * @param {string} search_text 
         * @param {string} [user_id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealQueueGetNlpAutoLogResults(search_text: string, user_id?: string, options?: any): AxiosPromise<Array<CreateMealItemRequest>> {
            return localVarFp.appApiMealQueueGetNlpAutoLogResults(search_text, user_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Testing endpoint
         * @param {string} search_text 
         * @param {string} [user_id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealQueueGetNlpAutoLogResultsSpacy(search_text: string, user_id?: string, options?: any): AxiosPromise<Array<CreateMealItemRequest>> {
            return localVarFp.appApiMealQueueGetNlpAutoLogResultsSpacy(search_text, user_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets food error flags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiNutritionGetErrorFlags(options?: any): AxiosPromise<string> {
            return localVarFp.appApiNutritionGetErrorFlags(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appApiFoodFoodDetailsApplyWarnings operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodDetailsApplyWarningsRequest
 */
export interface FoodApiAppApiFoodFoodDetailsApplyWarningsRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodDetailsApplyWarnings
     */
    readonly food_name: string

    /**
     * 
     * @type {FoodDetailsUpdateRequest}
     * @memberof FoodApiAppApiFoodFoodDetailsApplyWarnings
     */
    readonly FoodDetailsUpdateRequest?: FoodDetailsUpdateRequest
}

/**
 * Request parameters for appApiFoodFoodDetailsApplyWarningsQuery operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodDetailsApplyWarningsQueryRequest
 */
export interface FoodApiAppApiFoodFoodDetailsApplyWarningsQueryRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodDetailsApplyWarningsQuery
     */
    readonly food_name: string

    /**
     * 
     * @type {FoodDetailsUpdateRequest}
     * @memberof FoodApiAppApiFoodFoodDetailsApplyWarningsQuery
     */
    readonly FoodDetailsUpdateRequest?: FoodDetailsUpdateRequest
}

/**
 * Request parameters for appApiFoodFoodDetailsCalculateFoodCompositeDetails operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodDetailsCalculateFoodCompositeDetailsRequest
 */
export interface FoodApiAppApiFoodFoodDetailsCalculateFoodCompositeDetailsRequest {
    /**
     * 
     * @type {CalculateCompositeFoodDetailsRequest}
     * @memberof FoodApiAppApiFoodFoodDetailsCalculateFoodCompositeDetails
     */
    readonly CalculateCompositeFoodDetailsRequest?: CalculateCompositeFoodDetailsRequest
}

/**
 * Request parameters for appApiFoodFoodDetailsGetFoodDetails operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodDetailsGetFoodDetailsRequest
 */
export interface FoodApiAppApiFoodFoodDetailsGetFoodDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodDetailsGetFoodDetails
     */
    readonly food_name: string
}

/**
 * Request parameters for appApiFoodFoodDetailsGetFoodDetailsChangeLogs operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodDetailsGetFoodDetailsChangeLogsRequest
 */
export interface FoodApiAppApiFoodFoodDetailsGetFoodDetailsChangeLogsRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodDetailsGetFoodDetailsChangeLogs
     */
    readonly food_name: string
}

/**
 * Request parameters for appApiFoodFoodDetailsGetFoodDetailsChangeLogsQuery operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodDetailsGetFoodDetailsChangeLogsQueryRequest
 */
export interface FoodApiAppApiFoodFoodDetailsGetFoodDetailsChangeLogsQueryRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodDetailsGetFoodDetailsChangeLogsQuery
     */
    readonly food_name: string
}

/**
 * Request parameters for appApiFoodFoodDetailsGetFoodDetailsEstimates operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodDetailsGetFoodDetailsEstimatesRequest
 */
export interface FoodApiAppApiFoodFoodDetailsGetFoodDetailsEstimatesRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodDetailsGetFoodDetailsEstimates
     */
    readonly food_name: string
}

/**
 * Request parameters for appApiFoodFoodDetailsGetFoodDetailsEstimatesQuery operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodDetailsGetFoodDetailsEstimatesQueryRequest
 */
export interface FoodApiAppApiFoodFoodDetailsGetFoodDetailsEstimatesQueryRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodDetailsGetFoodDetailsEstimatesQuery
     */
    readonly food_name: string
}

/**
 * Request parameters for appApiFoodFoodDetailsGetFoodDetailsQuery operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodDetailsGetFoodDetailsQueryRequest
 */
export interface FoodApiAppApiFoodFoodDetailsGetFoodDetailsQueryRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodDetailsGetFoodDetailsQuery
     */
    readonly food_name: string
}

/**
 * Request parameters for appApiFoodFoodDetailsGetOptionsCollection operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodDetailsGetOptionsCollectionRequest
 */
export interface FoodApiAppApiFoodFoodDetailsGetOptionsCollectionRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodDetailsGetOptionsCollection
     */
    readonly scope: string
}

/**
 * Request parameters for appApiFoodFoodDetailsGetRecentFoods operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodDetailsGetRecentFoodsRequest
 */
export interface FoodApiAppApiFoodFoodDetailsGetRecentFoodsRequest {
    /**
     * 
     * @type {number}
     * @memberof FoodApiAppApiFoodFoodDetailsGetRecentFoods
     */
    readonly limit: number

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodDetailsGetRecentFoods
     */
    readonly status?: string
}

/**
 * Request parameters for appApiFoodFoodDetailsPatchFoodAssignTask operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodDetailsPatchFoodAssignTaskRequest
 */
export interface FoodApiAppApiFoodFoodDetailsPatchFoodAssignTaskRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodDetailsPatchFoodAssignTask
     */
    readonly food_name: string

    /**
     * 
     * @type {FoodAssignmentRequest}
     * @memberof FoodApiAppApiFoodFoodDetailsPatchFoodAssignTask
     */
    readonly FoodAssignmentRequest?: FoodAssignmentRequest
}

/**
 * Request parameters for appApiFoodFoodDetailsPatchFoodAssignTaskQuery operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodDetailsPatchFoodAssignTaskQueryRequest
 */
export interface FoodApiAppApiFoodFoodDetailsPatchFoodAssignTaskQueryRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodDetailsPatchFoodAssignTaskQuery
     */
    readonly food_name: string

    /**
     * 
     * @type {FoodAssignmentRequest}
     * @memberof FoodApiAppApiFoodFoodDetailsPatchFoodAssignTaskQuery
     */
    readonly FoodAssignmentRequest?: FoodAssignmentRequest
}

/**
 * Request parameters for appApiFoodFoodDetailsPutFoodDetails operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodDetailsPutFoodDetailsRequest
 */
export interface FoodApiAppApiFoodFoodDetailsPutFoodDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodDetailsPutFoodDetails
     */
    readonly food_name: string

    /**
     * 
     * @type {FoodDetailsUpdateRequest}
     * @memberof FoodApiAppApiFoodFoodDetailsPutFoodDetails
     */
    readonly FoodDetailsUpdateRequest?: FoodDetailsUpdateRequest
}

/**
 * Request parameters for appApiFoodFoodDetailsPutFoodDetailsQuery operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodDetailsPutFoodDetailsQueryRequest
 */
export interface FoodApiAppApiFoodFoodDetailsPutFoodDetailsQueryRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodDetailsPutFoodDetailsQuery
     */
    readonly food_name: string

    /**
     * 
     * @type {FoodDetailsUpdateRequest}
     * @memberof FoodApiAppApiFoodFoodDetailsPutFoodDetailsQuery
     */
    readonly FoodDetailsUpdateRequest?: FoodDetailsUpdateRequest
}

/**
 * Request parameters for appApiFoodFoodFavoriteDeleteFavorite operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodFavoriteDeleteFavoriteRequest
 */
export interface FoodApiAppApiFoodFoodFavoriteDeleteFavoriteRequest {
    /**
     * 
     * @type {number}
     * @memberof FoodApiAppApiFoodFoodFavoriteDeleteFavorite
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodFavoriteDeleteFavorite
     */
    readonly food_name?: string

    /**
     * 
     * @type {number}
     * @memberof FoodApiAppApiFoodFoodFavoriteDeleteFavorite
     */
    readonly favorite_id?: number
}

/**
 * Request parameters for appApiFoodFoodFavoriteGetFavorite operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodFavoriteGetFavoriteRequest
 */
export interface FoodApiAppApiFoodFoodFavoriteGetFavoriteRequest {
    /**
     * 
     * @type {number}
     * @memberof FoodApiAppApiFoodFoodFavoriteGetFavorite
     */
    readonly patient_id: number
}

/**
 * Request parameters for appApiFoodFoodFavoriteGetFavorites operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodFavoriteGetFavoritesRequest
 */
export interface FoodApiAppApiFoodFoodFavoriteGetFavoritesRequest {
    /**
     * 
     * @type {number}
     * @memberof FoodApiAppApiFoodFoodFavoriteGetFavorites
     */
    readonly patient_id: number
}

/**
 * Request parameters for appApiFoodFoodFavoritePostFavorite operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodFavoritePostFavoriteRequest
 */
export interface FoodApiAppApiFoodFoodFavoritePostFavoriteRequest {
    /**
     * 
     * @type {number}
     * @memberof FoodApiAppApiFoodFoodFavoritePostFavorite
     */
    readonly patient_id: number

    /**
     * 
     * @type {CreateFavoriteRequest}
     * @memberof FoodApiAppApiFoodFoodFavoritePostFavorite
     */
    readonly CreateFavoriteRequest: CreateFavoriteRequest
}

/**
 * Request parameters for appApiFoodFoodFavoritePutFavorite operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodFavoritePutFavoriteRequest
 */
export interface FoodApiAppApiFoodFoodFavoritePutFavoriteRequest {
    /**
     * 
     * @type {number}
     * @memberof FoodApiAppApiFoodFoodFavoritePutFavorite
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodFavoritePutFavorite
     */
    readonly food_name?: string

    /**
     * 
     * @type {UpdateFavoriteRequest}
     * @memberof FoodApiAppApiFoodFoodFavoritePutFavorite
     */
    readonly UpdateFavoriteRequest?: UpdateFavoriteRequest
}

/**
 * Request parameters for appApiFoodFoodLookupGetFoodPhoto operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodLookupGetFoodPhotoRequest
 */
export interface FoodApiAppApiFoodFoodLookupGetFoodPhotoRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodLookupGetFoodPhoto
     */
    readonly food_name: string
}

/**
 * Request parameters for appApiFoodFoodLookupGetFoodPhotoQuery operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodLookupGetFoodPhotoQueryRequest
 */
export interface FoodApiAppApiFoodFoodLookupGetFoodPhotoQueryRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodLookupGetFoodPhotoQuery
     */
    readonly food_name: string
}

/**
 * Request parameters for appApiFoodFoodLookupGetPatientFoodSuggestedAddons operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodLookupGetPatientFoodSuggestedAddonsRequest
 */
export interface FoodApiAppApiFoodFoodLookupGetPatientFoodSuggestedAddonsRequest {
    /**
     * 
     * @type {number}
     * @memberof FoodApiAppApiFoodFoodLookupGetPatientFoodSuggestedAddons
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodLookupGetPatientFoodSuggestedAddons
     */
    readonly food_name: string
}

/**
 * Request parameters for appApiFoodFoodNdbnoGetFoodNdbno operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodNdbnoGetFoodNdbnoRequest
 */
export interface FoodApiAppApiFoodFoodNdbnoGetFoodNdbnoRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodNdbnoGetFoodNdbno
     */
    readonly ndbno: string
}

/**
 * Request parameters for appApiFoodFoodRecentGetPatientRecentAddons operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodRecentGetPatientRecentAddonsRequest
 */
export interface FoodApiAppApiFoodFoodRecentGetPatientRecentAddonsRequest {
    /**
     * 
     * @type {number}
     * @memberof FoodApiAppApiFoodFoodRecentGetPatientRecentAddons
     */
    readonly patient_id: number

    /**
     * time in format hh:mm:ss
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodRecentGetPatientRecentAddons
     */
    readonly time?: string

    /**
     * food name
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodRecentGetPatientRecentAddons
     */
    readonly food_name?: string
}

/**
 * Request parameters for appApiFoodFoodRecentGetRecent operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodRecentGetRecentRequest
 */
export interface FoodApiAppApiFoodFoodRecentGetRecentRequest {
    /**
     * 
     * @type {number}
     * @memberof FoodApiAppApiFoodFoodRecentGetRecent
     */
    readonly patient_id: number

    /**
     * time in format hh:mm:ss
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodRecentGetRecent
     */
    readonly time: string
}

/**
 * Request parameters for appApiFoodFoodRecentGetRecentMealItems operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodRecentGetRecentMealItemsRequest
 */
export interface FoodApiAppApiFoodFoodRecentGetRecentMealItemsRequest {
    /**
     * 
     * @type {number}
     * @memberof FoodApiAppApiFoodFoodRecentGetRecentMealItems
     */
    readonly patient_id: number

    /**
     * time in format hh:mm:ss
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodRecentGetRecentMealItems
     */
    readonly time?: string
}

/**
 * Request parameters for appApiFoodFoodScrapingPostFood operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodScrapingPostFoodRequest
 */
export interface FoodApiAppApiFoodFoodScrapingPostFoodRequest {
    /**
     * 
     * @type {number}
     * @memberof FoodApiAppApiFoodFoodScrapingPostFood
     */
    readonly user_id: number

    /**
     * Food Scraping Request Object.
     * @type {CreateFoodScrapingRequest}
     * @memberof FoodApiAppApiFoodFoodScrapingPostFood
     */
    readonly CreateFoodScrapingRequest: CreateFoodScrapingRequest
}

/**
 * Request parameters for appApiFoodFoodSearchAudioSearchByAudio operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodSearchAudioSearchByAudioRequest
 */
export interface FoodApiAppApiFoodFoodSearchAudioSearchByAudioRequest {
    /**
     * 
     * @type {any}
     * @memberof FoodApiAppApiFoodFoodSearchAudioSearchByAudio
     */
    readonly audio: any
}

/**
 * Request parameters for appApiFoodFoodSearchAudioSearchByAudioQueue operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodSearchAudioSearchByAudioQueueRequest
 */
export interface FoodApiAppApiFoodFoodSearchAudioSearchByAudioQueueRequest {
    /**
     * 
     * @type {any}
     * @memberof FoodApiAppApiFoodFoodSearchAudioSearchByAudioQueue
     */
    readonly audio: any

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchAudioSearchByAudioQueue
     */
    readonly meal_name?: string

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchAudioSearchByAudioQueue
     */
    readonly note?: string

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchAudioSearchByAudioQueue
     */
    readonly meal_nlp_phrase?: string
}

/**
 * Request parameters for appApiFoodFoodSearchAzureAutocomplete operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodSearchAzureAutocompleteRequest
 */
export interface FoodApiAppApiFoodFoodSearchAzureAutocompleteRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchAzureAutocomplete
     */
    readonly partial_term: string
}

/**
 * Request parameters for appApiFoodFoodSearchAzureSearchBrand operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodSearchAzureSearchBrandRequest
 */
export interface FoodApiAppApiFoodFoodSearchAzureSearchBrandRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchAzureSearchBrand
     */
    readonly search_text: string
}

/**
 * Request parameters for appApiFoodFoodSearchAzureSearchManufacturer operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodSearchAzureSearchManufacturerRequest
 */
export interface FoodApiAppApiFoodFoodSearchAzureSearchManufacturerRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchAzureSearchManufacturer
     */
    readonly search_text: string
}

/**
 * Request parameters for appApiFoodFoodSearchAzureSuggest operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodSearchAzureSuggestRequest
 */
export interface FoodApiAppApiFoodFoodSearchAzureSuggestRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchAzureSuggest
     */
    readonly partial_term: string
}

/**
 * Request parameters for appApiFoodFoodSearchAzureUpdateDocument operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodSearchAzureUpdateDocumentRequest
 */
export interface FoodApiAppApiFoodFoodSearchAzureUpdateDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchAzureUpdateDocument
     */
    readonly food_name: string
}

/**
 * Request parameters for appApiFoodFoodSearchGetClientSearchIndex operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodSearchGetClientSearchIndexRequest
 */
export interface FoodApiAppApiFoodFoodSearchGetClientSearchIndexRequest {
    /**
     * combination of \&quot;{updated_time}:{id}\&quot;
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchGetClientSearchIndex
     */
    readonly cursor?: string

    /**
     * maximum number of items to return (default: 1000)
     * @type {number}
     * @memberof FoodApiAppApiFoodFoodSearchGetClientSearchIndex
     */
    readonly limit?: number
}

/**
 * Request parameters for appApiFoodFoodSearchGetFood operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodSearchGetFoodRequest
 */
export interface FoodApiAppApiFoodFoodSearchGetFoodRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchGetFood
     */
    readonly food_name: string
}

/**
 * Request parameters for appApiFoodFoodSearchGetFoodDatabaseTable operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodSearchGetFoodDatabaseTableRequest
 */
export interface FoodApiAppApiFoodFoodSearchGetFoodDatabaseTableRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchGetFoodDatabaseTable
     */
    readonly filter?: string

    /**
     * 
     * @type {number}
     * @memberof FoodApiAppApiFoodFoodSearchGetFoodDatabaseTable
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchGetFoodDatabaseTable
     */
    readonly offset?: string

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchGetFoodDatabaseTable
     */
    readonly order?: string
}

/**
 * Request parameters for appApiFoodFoodSearchGetFoodQuery operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodSearchGetFoodQueryRequest
 */
export interface FoodApiAppApiFoodFoodSearchGetFoodQueryRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchGetFoodQuery
     */
    readonly food_name: string
}

/**
 * Request parameters for appApiFoodFoodSearchGetFoodSearchV2Interactive operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodSearchGetFoodSearchV2InteractiveRequest
 */
export interface FoodApiAppApiFoodFoodSearchGetFoodSearchV2InteractiveRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchGetFoodSearchV2Interactive
     */
    readonly q: string

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchGetFoodSearchV2Interactive
     */
    readonly context_lang?: string

    /**
     * 
     * @type {number}
     * @memberof FoodApiAppApiFoodFoodSearchGetFoodSearchV2Interactive
     */
    readonly context_user_id?: number

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchGetFoodSearchV2Interactive
     */
    readonly context_meal_name?: string

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchGetFoodSearchV2Interactive
     */
    readonly context_food_names?: string

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchGetFoodSearchV2Interactive
     */
    readonly context_addon_of?: string

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchGetFoodSearchV2Interactive
     */
    readonly context_index?: string

    /**
     * 
     * @type {boolean}
     * @memberof FoodApiAppApiFoodFoodSearchGetFoodSearchV2Interactive
     */
    readonly context_semantic?: boolean
}

/**
 * Request parameters for appApiFoodFoodSearchGetMealPhotoUploadUrl operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodSearchGetMealPhotoUploadUrlRequest
 */
export interface FoodApiAppApiFoodFoodSearchGetMealPhotoUploadUrlRequest {
    /**
     * image file extension
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchGetMealPhotoUploadUrl
     */
    readonly ext: string

    /**
     * image batch key
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchGetMealPhotoUploadUrl
     */
    readonly key: string

    /**
     * image index in batch
     * @type {number}
     * @memberof FoodApiAppApiFoodFoodSearchGetMealPhotoUploadUrl
     */
    readonly idx: number

    /**
     * optional suffix for the file (ex, &#x60;\&quot;depth-map\&quot;&#x60;)
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchGetMealPhotoUploadUrl
     */
    readonly suffix?: string

    /**
     * optional JSON-encoded metadata to include in the x-goog-meta-rx-meta header
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchGetMealPhotoUploadUrl
     */
    readonly metadata?: string
}

/**
 * Request parameters for appApiFoodFoodSearchGetPatientFood operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodSearchGetPatientFoodRequest
 */
export interface FoodApiAppApiFoodFoodSearchGetPatientFoodRequest {
    /**
     * 
     * @type {number}
     * @memberof FoodApiAppApiFoodFoodSearchGetPatientFood
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchGetPatientFood
     */
    readonly food_name: string
}

/**
 * Request parameters for appApiFoodFoodSearchGetPatientFoodQuery operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodSearchGetPatientFoodQueryRequest
 */
export interface FoodApiAppApiFoodFoodSearchGetPatientFoodQueryRequest {
    /**
     * 
     * @type {number}
     * @memberof FoodApiAppApiFoodFoodSearchGetPatientFoodQuery
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchGetPatientFoodQuery
     */
    readonly food_name: string
}

/**
 * Request parameters for appApiFoodFoodSearchNlpPostSearchByTextNlpQueue operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodSearchNlpPostSearchByTextNlpQueueRequest
 */
export interface FoodApiAppApiFoodFoodSearchNlpPostSearchByTextNlpQueueRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchNlpPostSearchByTextNlpQueue
     */
    readonly search_text: string

    /**
     * 
     * @type {CreateSearchByTextNlpQueueRequest}
     * @memberof FoodApiAppApiFoodFoodSearchNlpPostSearchByTextNlpQueue
     */
    readonly CreateSearchByTextNlpQueueRequest: CreateSearchByTextNlpQueueRequest
}

/**
 * Request parameters for appApiFoodFoodSearchNlpSearchByTextNlp operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodSearchNlpSearchByTextNlpRequest
 */
export interface FoodApiAppApiFoodFoodSearchNlpSearchByTextNlpRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchNlpSearchByTextNlp
     */
    readonly search_text: string
}

/**
 * Request parameters for appApiFoodFoodSearchNlpSearchByTextNlpQueue operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodSearchNlpSearchByTextNlpQueueRequest
 */
export interface FoodApiAppApiFoodFoodSearchNlpSearchByTextNlpQueueRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchNlpSearchByTextNlpQueue
     */
    readonly search_text: string

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchNlpSearchByTextNlpQueue
     */
    readonly meal_date?: string

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchNlpSearchByTextNlpQueue
     */
    readonly meal_time?: string

    /**
     * 
     * @type {'breakfast' | 'lunch' | 'dinner' | 'snack'}
     * @memberof FoodApiAppApiFoodFoodSearchNlpSearchByTextNlpQueue
     */
    readonly meal_name?: 'breakfast' | 'lunch' | 'dinner' | 'snack'
}

/**
 * Request parameters for appApiFoodFoodSearchSearchByCognitive operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodSearchSearchByCognitiveRequest
 */
export interface FoodApiAppApiFoodFoodSearchSearchByCognitiveRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByCognitive
     */
    readonly search_text: string

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByCognitive
     */
    readonly context_user_id?: string

    /**
     * 
     * @type {boolean}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByCognitive
     */
    readonly extended_query?: boolean
}

/**
 * Request parameters for appApiFoodFoodSearchSearchByNlpPhrase operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodSearchSearchByNlpPhraseRequest
 */
export interface FoodApiAppApiFoodFoodSearchSearchByNlpPhraseRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByNlpPhrase
     */
    readonly search_text: string

    /**
     * 
     * @type {number}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByNlpPhrase
     */
    readonly queue_id: number

    /**
     * 
     * @type {boolean}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByNlpPhrase
     */
    readonly use_cache?: boolean
}

/**
 * Request parameters for appApiFoodFoodSearchSearchByPhoto operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodSearchSearchByPhotoRequest
 */
export interface FoodApiAppApiFoodFoodSearchSearchByPhotoRequest {
    /**
     * 
     * @type {any}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByPhoto
     */
    readonly photo: any

    /**
     * 
     * @type {any}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByPhoto
     */
    readonly photo2?: any
}

/**
 * Request parameters for appApiFoodFoodSearchSearchByPhotoFfe operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodSearchSearchByPhotoFfeRequest
 */
export interface FoodApiAppApiFoodFoodSearchSearchByPhotoFfeRequest {
    /**
     * 
     * @type {any}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByPhotoFfe
     */
    readonly photo: any

    /**
     * 
     * @type {any}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByPhotoFfe
     */
    readonly photo2?: any

    /**
     * 
     * @type {number}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByPhotoFfe
     */
    readonly num_items?: number

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByPhotoFfe
     */
    readonly sizing_input?: string

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByPhotoFfe
     */
    readonly meal_name?: string

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByPhotoFfe
     */
    readonly note?: string
}

/**
 * Request parameters for appApiFoodFoodSearchSearchByPhotoFfeQueue operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodSearchSearchByPhotoFfeQueueRequest
 */
export interface FoodApiAppApiFoodFoodSearchSearchByPhotoFfeQueueRequest {
    /**
     * 
     * @type {any}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByPhotoFfeQueue
     */
    readonly photo?: any

    /**
     * 
     * @type {any}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByPhotoFfeQueue
     */
    readonly photo2?: any

    /**
     * 
     * @type {Array<string>}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByPhotoFfeQueue
     */
    readonly photo_urls?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByPhotoFfeQueue
     */
    readonly num_items?: number

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByPhotoFfeQueue
     */
    readonly sizing_input?: string

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByPhotoFfeQueue
     */
    readonly meal_name?: string

    /**
     * time in format hh:mm:ss
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByPhotoFfeQueue
     */
    readonly meal_time?: string

    /**
     * calendar date in format YYYY-MM-DD
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByPhotoFfeQueue
     */
    readonly meal_date?: string

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByPhotoFfeQueue
     */
    readonly note?: string

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByPhotoFfeQueue
     */
    readonly photo_id?: string

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByPhotoFfeQueue
     */
    readonly photo_timings_encoded?: string

    /**
     * 
     * @type {PreparationMethodEnum}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByPhotoFfeQueue
     */
    readonly preparation_method?: PreparationMethodEnum

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByPhotoFfeQueue
     */
    readonly queue_metadata?: string
}

/**
 * Request parameters for appApiFoodFoodSearchSearchByPhotoOcr operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodSearchSearchByPhotoOcrRequest
 */
export interface FoodApiAppApiFoodFoodSearchSearchByPhotoOcrRequest {
    /**
     * 
     * @type {any}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByPhotoOcr
     */
    readonly photo: any
}

/**
 * Request parameters for appApiFoodFoodSearchSearchByText operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodSearchSearchByTextRequest
 */
export interface FoodApiAppApiFoodFoodSearchSearchByTextRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByText
     */
    readonly search_text: string

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByText
     */
    readonly context_user_id?: string

    /**
     * 
     * @type {boolean}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByText
     */
    readonly extended_query?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof FoodApiAppApiFoodFoodSearchSearchByText
     */
    readonly food_name_only?: boolean
}

/**
 * Request parameters for appApiFoodFoodSearchSearchDietRestrictions operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodSearchSearchDietRestrictionsRequest
 */
export interface FoodApiAppApiFoodFoodSearchSearchDietRestrictionsRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchSearchDietRestrictions
     */
    readonly search_text: string

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodSearchSearchDietRestrictions
     */
    readonly diet_restriction: string
}

/**
 * Request parameters for appApiFoodFoodSearchUploadPhoto operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodSearchUploadPhotoRequest
 */
export interface FoodApiAppApiFoodFoodSearchUploadPhotoRequest {
    /**
     * 
     * @type {any}
     * @memberof FoodApiAppApiFoodFoodSearchUploadPhoto
     */
    readonly photo: any
}

/**
 * Request parameters for appApiFoodFoodUsdaGetUsdaFood operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodUsdaGetUsdaFoodRequest
 */
export interface FoodApiAppApiFoodFoodUsdaGetUsdaFoodRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodUsdaGetUsdaFood
     */
    readonly fdcid: string
}

/**
 * Request parameters for appApiFoodFoodUsdaGetUsdaFoodData operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodUsdaGetUsdaFoodDataRequest
 */
export interface FoodApiAppApiFoodFoodUsdaGetUsdaFoodDataRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodUsdaGetUsdaFoodData
     */
    readonly fdcid: string
}

/**
 * Request parameters for appApiFoodFoodUsdaSearchUsdaDatabaseApi operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodUsdaSearchUsdaDatabaseApiRequest
 */
export interface FoodApiAppApiFoodFoodUsdaSearchUsdaDatabaseApiRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodUsdaSearchUsdaDatabaseApi
     */
    readonly search_term: string
}

/**
 * Request parameters for appApiFoodFoodUsdaSendData operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodUsdaSendDataRequest
 */
export interface FoodApiAppApiFoodFoodUsdaSendDataRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodUsdaSendData
     */
    readonly fdcid: string
}

/**
 * Request parameters for appApiFoodFoodWhiteboardDeleteWhiteboardItem operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodWhiteboardDeleteWhiteboardItemRequest
 */
export interface FoodApiAppApiFoodFoodWhiteboardDeleteWhiteboardItemRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodWhiteboardDeleteWhiteboardItem
     */
    readonly food_name: string

    /**
     * 
     * @type {number}
     * @memberof FoodApiAppApiFoodFoodWhiteboardDeleteWhiteboardItem
     */
    readonly id: number
}

/**
 * Request parameters for appApiFoodFoodWhiteboardDeleteWhiteboardItemQuery operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodWhiteboardDeleteWhiteboardItemQueryRequest
 */
export interface FoodApiAppApiFoodFoodWhiteboardDeleteWhiteboardItemQueryRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodWhiteboardDeleteWhiteboardItemQuery
     */
    readonly food_name: string

    /**
     * 
     * @type {number}
     * @memberof FoodApiAppApiFoodFoodWhiteboardDeleteWhiteboardItemQuery
     */
    readonly id: number
}

/**
 * Request parameters for appApiFoodFoodWhiteboardGetRelevantQueues operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodWhiteboardGetRelevantQueuesRequest
 */
export interface FoodApiAppApiFoodFoodWhiteboardGetRelevantQueuesRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodWhiteboardGetRelevantQueues
     */
    readonly food_name: string
}

/**
 * Request parameters for appApiFoodFoodWhiteboardGetRelevantQueuesQuery operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodWhiteboardGetRelevantQueuesQueryRequest
 */
export interface FoodApiAppApiFoodFoodWhiteboardGetRelevantQueuesQueryRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodWhiteboardGetRelevantQueuesQuery
     */
    readonly food_name: string
}

/**
 * Request parameters for appApiFoodFoodWhiteboardGetWhiteboardItems operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodWhiteboardGetWhiteboardItemsRequest
 */
export interface FoodApiAppApiFoodFoodWhiteboardGetWhiteboardItemsRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodWhiteboardGetWhiteboardItems
     */
    readonly food_name: string
}

/**
 * Request parameters for appApiFoodFoodWhiteboardGetWhiteboardItemsQuery operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodWhiteboardGetWhiteboardItemsQueryRequest
 */
export interface FoodApiAppApiFoodFoodWhiteboardGetWhiteboardItemsQueryRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodWhiteboardGetWhiteboardItemsQuery
     */
    readonly food_name: string
}

/**
 * Request parameters for appApiFoodFoodWhiteboardPostWhiteboardItem operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodWhiteboardPostWhiteboardItemRequest
 */
export interface FoodApiAppApiFoodFoodWhiteboardPostWhiteboardItemRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodWhiteboardPostWhiteboardItem
     */
    readonly food_name: string

    /**
     * 
     * @type {FoodWhiteboardCreateUpdateRequest}
     * @memberof FoodApiAppApiFoodFoodWhiteboardPostWhiteboardItem
     */
    readonly FoodWhiteboardCreateUpdateRequest: FoodWhiteboardCreateUpdateRequest
}

/**
 * Request parameters for appApiFoodFoodWhiteboardPostWhiteboardItemQuery operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodWhiteboardPostWhiteboardItemQueryRequest
 */
export interface FoodApiAppApiFoodFoodWhiteboardPostWhiteboardItemQueryRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodWhiteboardPostWhiteboardItemQuery
     */
    readonly food_name: string

    /**
     * 
     * @type {FoodWhiteboardCreateUpdateRequest}
     * @memberof FoodApiAppApiFoodFoodWhiteboardPostWhiteboardItemQuery
     */
    readonly FoodWhiteboardCreateUpdateRequest: FoodWhiteboardCreateUpdateRequest
}

/**
 * Request parameters for appApiFoodFoodWhiteboardPutWhiteboardItem operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodWhiteboardPutWhiteboardItemRequest
 */
export interface FoodApiAppApiFoodFoodWhiteboardPutWhiteboardItemRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodWhiteboardPutWhiteboardItem
     */
    readonly food_name: string

    /**
     * 
     * @type {number}
     * @memberof FoodApiAppApiFoodFoodWhiteboardPutWhiteboardItem
     */
    readonly id: number

    /**
     * 
     * @type {FoodWhiteboardCreateUpdateRequest}
     * @memberof FoodApiAppApiFoodFoodWhiteboardPutWhiteboardItem
     */
    readonly FoodWhiteboardCreateUpdateRequest: FoodWhiteboardCreateUpdateRequest
}

/**
 * Request parameters for appApiFoodFoodWhiteboardPutWhiteboardItemQuery operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodFoodWhiteboardPutWhiteboardItemQueryRequest
 */
export interface FoodApiAppApiFoodFoodWhiteboardPutWhiteboardItemQueryRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiFoodFoodWhiteboardPutWhiteboardItemQuery
     */
    readonly food_name: string

    /**
     * 
     * @type {number}
     * @memberof FoodApiAppApiFoodFoodWhiteboardPutWhiteboardItemQuery
     */
    readonly id: number

    /**
     * 
     * @type {FoodWhiteboardCreateUpdateRequest}
     * @memberof FoodApiAppApiFoodFoodWhiteboardPutWhiteboardItemQuery
     */
    readonly FoodWhiteboardCreateUpdateRequest: FoodWhiteboardCreateUpdateRequest
}

/**
 * Request parameters for appApiFoodOntologyGetAllOntologies operation in FoodApi.
 * @export
 * @interface FoodApiAppApiFoodOntologyGetAllOntologiesRequest
 */
export interface FoodApiAppApiFoodOntologyGetAllOntologiesRequest {
    /**
     * 
     * @type {boolean}
     * @memberof FoodApiAppApiFoodOntologyGetAllOntologies
     */
    readonly include_archived?: boolean
}

/**
 * Request parameters for appApiMealGetCalibratedCreateMealItemRequest operation in FoodApi.
 * @export
 * @interface FoodApiAppApiMealGetCalibratedCreateMealItemRequestRequest
 */
export interface FoodApiAppApiMealGetCalibratedCreateMealItemRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiMealGetCalibratedCreateMealItemRequest
     */
    readonly food_name: string

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiMealGetCalibratedCreateMealItemRequest
     */
    readonly target_unit: string

    /**
     * 
     * @type {number}
     * @memberof FoodApiAppApiMealGetCalibratedCreateMealItemRequest
     */
    readonly target_value: number

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiMealGetCalibratedCreateMealItemRequest
     */
    readonly food_replacement_name?: string
}

/**
 * Request parameters for appApiMealQueueGetNlpAutoLogResults operation in FoodApi.
 * @export
 * @interface FoodApiAppApiMealQueueGetNlpAutoLogResultsRequest
 */
export interface FoodApiAppApiMealQueueGetNlpAutoLogResultsRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiMealQueueGetNlpAutoLogResults
     */
    readonly search_text: string

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiMealQueueGetNlpAutoLogResults
     */
    readonly user_id?: string
}

/**
 * Request parameters for appApiMealQueueGetNlpAutoLogResultsSpacy operation in FoodApi.
 * @export
 * @interface FoodApiAppApiMealQueueGetNlpAutoLogResultsSpacyRequest
 */
export interface FoodApiAppApiMealQueueGetNlpAutoLogResultsSpacyRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiMealQueueGetNlpAutoLogResultsSpacy
     */
    readonly search_text: string

    /**
     * 
     * @type {string}
     * @memberof FoodApiAppApiMealQueueGetNlpAutoLogResultsSpacy
     */
    readonly user_id?: string
}

/**
 * FoodApi - object-oriented interface
 * @export
 * @class FoodApi
 * @extends {BaseAPI}
 */
export class FoodApi extends BaseAPI {
    /**
     * 
     * @summary Export all addons assigned for all foods
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodAddonsGetFoodResponseAddons(options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodAddonsGetFoodResponseAddons(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [DEPRECATED] Validates Nutrients and returns warnings
     * @param {FoodApiAppApiFoodFoodDetailsApplyWarningsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodDetailsApplyWarnings(requestParameters: FoodApiAppApiFoodFoodDetailsApplyWarningsRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodDetailsApplyWarnings(requestParameters.food_name, requestParameters.FoodDetailsUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validates Nutrients and returns warnings
     * @param {FoodApiAppApiFoodFoodDetailsApplyWarningsQueryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodDetailsApplyWarningsQuery(requestParameters: FoodApiAppApiFoodFoodDetailsApplyWarningsQueryRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodDetailsApplyWarningsQuery(requestParameters.food_name, requestParameters.FoodDetailsUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets calculated food details for a list of food components
     * @param {FoodApiAppApiFoodFoodDetailsCalculateFoodCompositeDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodDetailsCalculateFoodCompositeDetails(requestParameters: FoodApiAppApiFoodFoodDetailsCalculateFoodCompositeDetailsRequest = {}, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodDetailsCalculateFoodCompositeDetails(requestParameters.CalculateCompositeFoodDetailsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [DEPRECATED] Gets complete food details
     * @param {FoodApiAppApiFoodFoodDetailsGetFoodDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodDetailsGetFoodDetails(requestParameters: FoodApiAppApiFoodFoodDetailsGetFoodDetailsRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodDetailsGetFoodDetails(requestParameters.food_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [DEPRECATED] Gets change logs for a food
     * @param {FoodApiAppApiFoodFoodDetailsGetFoodDetailsChangeLogsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodDetailsGetFoodDetailsChangeLogs(requestParameters: FoodApiAppApiFoodFoodDetailsGetFoodDetailsChangeLogsRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodDetailsGetFoodDetailsChangeLogs(requestParameters.food_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets change logs for a food
     * @param {FoodApiAppApiFoodFoodDetailsGetFoodDetailsChangeLogsQueryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodDetailsGetFoodDetailsChangeLogsQuery(requestParameters: FoodApiAppApiFoodFoodDetailsGetFoodDetailsChangeLogsQueryRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodDetailsGetFoodDetailsChangeLogsQuery(requestParameters.food_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [DEPRECATED] Gets food details estimates
     * @param {FoodApiAppApiFoodFoodDetailsGetFoodDetailsEstimatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodDetailsGetFoodDetailsEstimates(requestParameters: FoodApiAppApiFoodFoodDetailsGetFoodDetailsEstimatesRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodDetailsGetFoodDetailsEstimates(requestParameters.food_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets food details estimates
     * @param {FoodApiAppApiFoodFoodDetailsGetFoodDetailsEstimatesQueryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodDetailsGetFoodDetailsEstimatesQuery(requestParameters: FoodApiAppApiFoodFoodDetailsGetFoodDetailsEstimatesQueryRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodDetailsGetFoodDetailsEstimatesQuery(requestParameters.food_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets complete food details
     * @param {FoodApiAppApiFoodFoodDetailsGetFoodDetailsQueryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodDetailsGetFoodDetailsQuery(requestParameters: FoodApiAppApiFoodFoodDetailsGetFoodDetailsQueryRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodDetailsGetFoodDetailsQuery(requestParameters.food_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the options collection for a specific scope
     * @param {FoodApiAppApiFoodFoodDetailsGetOptionsCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodDetailsGetOptionsCollection(requestParameters: FoodApiAppApiFoodFoodDetailsGetOptionsCollectionRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodDetailsGetOptionsCollection(requestParameters.scope, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets complete food details
     * @param {FoodApiAppApiFoodFoodDetailsGetRecentFoodsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodDetailsGetRecentFoods(requestParameters: FoodApiAppApiFoodFoodDetailsGetRecentFoodsRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodDetailsGetRecentFoods(requestParameters.limit, requestParameters.status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [DEPRECATED] Update a food\'s associated user ids based on assignment
     * @param {FoodApiAppApiFoodFoodDetailsPatchFoodAssignTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodDetailsPatchFoodAssignTask(requestParameters: FoodApiAppApiFoodFoodDetailsPatchFoodAssignTaskRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodDetailsPatchFoodAssignTask(requestParameters.food_name, requestParameters.FoodAssignmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a food\'s associated user ids based on assignment
     * @param {FoodApiAppApiFoodFoodDetailsPatchFoodAssignTaskQueryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodDetailsPatchFoodAssignTaskQuery(requestParameters: FoodApiAppApiFoodFoodDetailsPatchFoodAssignTaskQueryRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodDetailsPatchFoodAssignTaskQuery(requestParameters.food_name, requestParameters.FoodAssignmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [DEPRECATED] Updates food details
     * @param {FoodApiAppApiFoodFoodDetailsPutFoodDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodDetailsPutFoodDetails(requestParameters: FoodApiAppApiFoodFoodDetailsPutFoodDetailsRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodDetailsPutFoodDetails(requestParameters.food_name, requestParameters.FoodDetailsUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates food details
     * @param {FoodApiAppApiFoodFoodDetailsPutFoodDetailsQueryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodDetailsPutFoodDetailsQuery(requestParameters: FoodApiAppApiFoodFoodDetailsPutFoodDetailsQueryRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodDetailsPutFoodDetailsQuery(requestParameters.food_name, requestParameters.FoodDetailsUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unset favorite food of the patient
     * @param {FoodApiAppApiFoodFoodFavoriteDeleteFavoriteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodFavoriteDeleteFavorite(requestParameters: FoodApiAppApiFoodFoodFavoriteDeleteFavoriteRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodFavoriteDeleteFavorite(requestParameters.patient_id, requestParameters.food_name, requestParameters.favorite_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get favorite food of the patient from the server
     * @param {FoodApiAppApiFoodFoodFavoriteGetFavoriteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodFavoriteGetFavorite(requestParameters: FoodApiAppApiFoodFoodFavoriteGetFavoriteRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodFavoriteGetFavorite(requestParameters.patient_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get favorite food of the patient from the server
     * @param {FoodApiAppApiFoodFoodFavoriteGetFavoritesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodFavoriteGetFavorites(requestParameters: FoodApiAppApiFoodFoodFavoriteGetFavoritesRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodFavoriteGetFavorites(requestParameters.patient_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new favorite
     * @param {FoodApiAppApiFoodFoodFavoritePostFavoriteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodFavoritePostFavorite(requestParameters: FoodApiAppApiFoodFoodFavoritePostFavoriteRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodFavoritePostFavorite(requestParameters.patient_id, requestParameters.CreateFavoriteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set favorite food of the patient
     * @param {FoodApiAppApiFoodFoodFavoritePutFavoriteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodFavoritePutFavorite(requestParameters: FoodApiAppApiFoodFoodFavoritePutFavoriteRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodFavoritePutFavorite(requestParameters.patient_id, requestParameters.food_name, requestParameters.UpdateFavoriteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [DEPRECATED] Get pre-uploaded food image by food name
     * @param {FoodApiAppApiFoodFoodLookupGetFoodPhotoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodLookupGetFoodPhoto(requestParameters: FoodApiAppApiFoodFoodLookupGetFoodPhotoRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodLookupGetFoodPhoto(requestParameters.food_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pre-uploaded food image by food name
     * @param {FoodApiAppApiFoodFoodLookupGetFoodPhotoQueryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodLookupGetFoodPhotoQuery(requestParameters: FoodApiAppApiFoodFoodLookupGetFoodPhotoQueryRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodLookupGetFoodPhotoQuery(requestParameters.food_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the recent food of the patient from the server
     * @param {FoodApiAppApiFoodFoodLookupGetPatientFoodSuggestedAddonsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodLookupGetPatientFoodSuggestedAddons(requestParameters: FoodApiAppApiFoodFoodLookupGetPatientFoodSuggestedAddonsRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodLookupGetPatientFoodSuggestedAddons(requestParameters.patient_id, requestParameters.food_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the food information from the server by ndbno
     * @param {FoodApiAppApiFoodFoodNdbnoGetFoodNdbnoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodNdbnoGetFoodNdbno(requestParameters: FoodApiAppApiFoodFoodNdbnoGetFoodNdbnoRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodNdbnoGetFoodNdbno(requestParameters.ndbno, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the recent addons of the patient from the server. Includes regular and custom.
     * @param {FoodApiAppApiFoodFoodRecentGetPatientRecentAddonsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodRecentGetPatientRecentAddons(requestParameters: FoodApiAppApiFoodFoodRecentGetPatientRecentAddonsRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodRecentGetPatientRecentAddons(requestParameters.patient_id, requestParameters.time, requestParameters.food_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the recent food of the patient from the server
     * @param {FoodApiAppApiFoodFoodRecentGetRecentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodRecentGetRecent(requestParameters: FoodApiAppApiFoodFoodRecentGetRecentRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodRecentGetRecent(requestParameters.patient_id, requestParameters.time, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the recent meal items of the patient from the server
     * @param {FoodApiAppApiFoodFoodRecentGetRecentMealItemsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodRecentGetRecentMealItems(requestParameters: FoodApiAppApiFoodFoodRecentGetRecentMealItemsRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodRecentGetRecentMealItems(requestParameters.patient_id, requestParameters.time, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request a new food item for the database
     * @param {FoodApiAppApiFoodFoodScrapingPostFoodRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodScrapingPostFood(requestParameters: FoodApiAppApiFoodFoodScrapingPostFoodRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodScrapingPostFood(requestParameters.user_id, requestParameters.CreateFoodScrapingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search foods by audio file
     * @param {FoodApiAppApiFoodFoodSearchAudioSearchByAudioRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodSearchAudioSearchByAudio(requestParameters: FoodApiAppApiFoodFoodSearchAudioSearchByAudioRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodSearchAudioSearchByAudio(requestParameters.audio, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search foods by audio file with results analyzed and queued for review (if required)
     * @param {FoodApiAppApiFoodFoodSearchAudioSearchByAudioQueueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodSearchAudioSearchByAudioQueue(requestParameters: FoodApiAppApiFoodFoodSearchAudioSearchByAudioQueueRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodSearchAudioSearchByAudioQueue(requestParameters.audio, requestParameters.meal_name, requestParameters.note, requestParameters.meal_nlp_phrase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return complete food terms by autocomplete
     * @param {FoodApiAppApiFoodFoodSearchAzureAutocompleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodSearchAzureAutocomplete(requestParameters: FoodApiAppApiFoodFoodSearchAzureAutocompleteRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodSearchAzureAutocomplete(requestParameters.partial_term, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search foods by brand names. Return maximum of 20 items
     * @param {FoodApiAppApiFoodFoodSearchAzureSearchBrandRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodSearchAzureSearchBrand(requestParameters: FoodApiAppApiFoodFoodSearchAzureSearchBrandRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodSearchAzureSearchBrand(requestParameters.search_text, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search foods by manufacturer names. Return maximum of 20 items
     * @param {FoodApiAppApiFoodFoodSearchAzureSearchManufacturerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodSearchAzureSearchManufacturer(requestParameters: FoodApiAppApiFoodFoodSearchAzureSearchManufacturerRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodSearchAzureSearchManufacturer(requestParameters.search_text, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return complete food terms by suggest
     * @param {FoodApiAppApiFoodFoodSearchAzureSuggestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodSearchAzureSuggest(requestParameters: FoodApiAppApiFoodFoodSearchAzureSuggestRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodSearchAzureSuggest(requestParameters.partial_term, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update document on Azure by food name
     * @param {FoodApiAppApiFoodFoodSearchAzureUpdateDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodSearchAzureUpdateDocument(requestParameters: FoodApiAppApiFoodFoodSearchAzureUpdateDocumentRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodSearchAzureUpdateDocument(requestParameters.food_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of food items used for client-side search indexing
     * @param {FoodApiAppApiFoodFoodSearchGetClientSearchIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodSearchGetClientSearchIndex(requestParameters: FoodApiAppApiFoodFoodSearchGetClientSearchIndexRequest = {}, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodSearchGetClientSearchIndex(requestParameters.cursor, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [DEPRECATED] Get the food information from the server
     * @param {FoodApiAppApiFoodFoodSearchGetFoodRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodSearchGetFood(requestParameters: FoodApiAppApiFoodFoodSearchGetFoodRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodSearchGetFood(requestParameters.food_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return a data table of all foods and custom items.
     * @param {FoodApiAppApiFoodFoodSearchGetFoodDatabaseTableRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodSearchGetFoodDatabaseTable(requestParameters: FoodApiAppApiFoodFoodSearchGetFoodDatabaseTableRequest = {}, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodSearchGetFoodDatabaseTable(requestParameters.filter, requestParameters.limit, requestParameters.offset, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the food information from the server
     * @param {FoodApiAppApiFoodFoodSearchGetFoodQueryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodSearchGetFoodQuery(requestParameters: FoodApiAppApiFoodFoodSearchGetFoodQueryRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodSearchGetFoodQuery(requestParameters.food_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search foods by text. Return maximum of 20 items
     * @param {FoodApiAppApiFoodFoodSearchGetFoodSearchV2InteractiveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodSearchGetFoodSearchV2Interactive(requestParameters: FoodApiAppApiFoodFoodSearchGetFoodSearchV2InteractiveRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodSearchGetFoodSearchV2Interactive(requestParameters.q, requestParameters.context_lang, requestParameters.context_user_id, requestParameters.context_meal_name, requestParameters.context_food_names, requestParameters.context_addon_of, requestParameters.context_index, requestParameters.context_semantic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a URL for uploading a meal photo (likely signed S3 URL)
     * @param {FoodApiAppApiFoodFoodSearchGetMealPhotoUploadUrlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodSearchGetMealPhotoUploadUrl(requestParameters: FoodApiAppApiFoodFoodSearchGetMealPhotoUploadUrlRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodSearchGetMealPhotoUploadUrl(requestParameters.ext, requestParameters.key, requestParameters.idx, requestParameters.suffix, requestParameters.metadata, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [DEPRECATED] Get the recent food of the patient from the server
     * @param {FoodApiAppApiFoodFoodSearchGetPatientFoodRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodSearchGetPatientFood(requestParameters: FoodApiAppApiFoodFoodSearchGetPatientFoodRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodSearchGetPatientFood(requestParameters.patient_id, requestParameters.food_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the recent food of the patient from the server
     * @param {FoodApiAppApiFoodFoodSearchGetPatientFoodQueryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodSearchGetPatientFoodQuery(requestParameters: FoodApiAppApiFoodFoodSearchGetPatientFoodQueryRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodSearchGetPatientFoodQuery(requestParameters.patient_id, requestParameters.food_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Log a meal with descriptive words with results analyzed and queued for review (if required)
     * @param {FoodApiAppApiFoodFoodSearchNlpPostSearchByTextNlpQueueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodSearchNlpPostSearchByTextNlpQueue(requestParameters: FoodApiAppApiFoodFoodSearchNlpPostSearchByTextNlpQueueRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodSearchNlpPostSearchByTextNlpQueue(requestParameters.search_text, requestParameters.CreateSearchByTextNlpQueueRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search foods by sentence and analyze using an NLP algorithm. Return maximum of 20 items
     * @param {FoodApiAppApiFoodFoodSearchNlpSearchByTextNlpRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodSearchNlpSearchByTextNlp(requestParameters: FoodApiAppApiFoodFoodSearchNlpSearchByTextNlpRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodSearchNlpSearchByTextNlp(requestParameters.search_text, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Log a meal with descriptive words with results analyzed and queued for review (if required)
     * @param {FoodApiAppApiFoodFoodSearchNlpSearchByTextNlpQueueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodSearchNlpSearchByTextNlpQueue(requestParameters: FoodApiAppApiFoodFoodSearchNlpSearchByTextNlpQueueRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodSearchNlpSearchByTextNlpQueue(requestParameters.search_text, requestParameters.meal_date, requestParameters.meal_time, requestParameters.meal_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search foods by text. Return maximum of 20 items
     * @param {FoodApiAppApiFoodFoodSearchSearchByCognitiveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodSearchSearchByCognitive(requestParameters: FoodApiAppApiFoodFoodSearchSearchByCognitiveRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodSearchSearchByCognitive(requestParameters.search_text, requestParameters.context_user_id, requestParameters.extended_query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search for foods by NLP phrase.
     * @param {FoodApiAppApiFoodFoodSearchSearchByNlpPhraseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodSearchSearchByNlpPhrase(requestParameters: FoodApiAppApiFoodFoodSearchSearchByNlpPhraseRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodSearchSearchByNlpPhrase(requestParameters.search_text, requestParameters.queue_id, requestParameters.use_cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search foods by photo
     * @param {FoodApiAppApiFoodFoodSearchSearchByPhotoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodSearchSearchByPhoto(requestParameters: FoodApiAppApiFoodFoodSearchSearchByPhotoRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodSearchSearchByPhoto(requestParameters.photo, requestParameters.photo2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search foods by photo with fast food entry sorting algorithm applied
     * @param {FoodApiAppApiFoodFoodSearchSearchByPhotoFfeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodSearchSearchByPhotoFfe(requestParameters: FoodApiAppApiFoodFoodSearchSearchByPhotoFfeRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodSearchSearchByPhotoFfe(requestParameters.photo, requestParameters.photo2, requestParameters.num_items, requestParameters.sizing_input, requestParameters.meal_name, requestParameters.note, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search foods by photo with FFE algorithm applied and results put in a queue for backend review
     * @param {FoodApiAppApiFoodFoodSearchSearchByPhotoFfeQueueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodSearchSearchByPhotoFfeQueue(requestParameters: FoodApiAppApiFoodFoodSearchSearchByPhotoFfeQueueRequest = {}, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodSearchSearchByPhotoFfeQueue(requestParameters.photo, requestParameters.photo2, requestParameters.photo_urls, requestParameters.num_items, requestParameters.sizing_input, requestParameters.meal_name, requestParameters.meal_time, requestParameters.meal_date, requestParameters.note, requestParameters.photo_id, requestParameters.photo_timings_encoded, requestParameters.preparation_method, requestParameters.queue_metadata, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary OCR a photo to return a list of words found
     * @param {FoodApiAppApiFoodFoodSearchSearchByPhotoOcrRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodSearchSearchByPhotoOcr(requestParameters: FoodApiAppApiFoodFoodSearchSearchByPhotoOcrRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodSearchSearchByPhotoOcr(requestParameters.photo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search foods by text. Return maximum of 20 items
     * @param {FoodApiAppApiFoodFoodSearchSearchByTextRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodSearchSearchByText(requestParameters: FoodApiAppApiFoodFoodSearchSearchByTextRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodSearchSearchByText(requestParameters.search_text, requestParameters.context_user_id, requestParameters.extended_query, requestParameters.food_name_only, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search foods with diet restriction
     * @param {FoodApiAppApiFoodFoodSearchSearchDietRestrictionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodSearchSearchDietRestrictions(requestParameters: FoodApiAppApiFoodFoodSearchSearchDietRestrictionsRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodSearchSearchDietRestrictions(requestParameters.search_text, requestParameters.diet_restriction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a meal photo
     * @param {FoodApiAppApiFoodFoodSearchUploadPhotoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodSearchUploadPhoto(requestParameters: FoodApiAppApiFoodFoodSearchUploadPhotoRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodSearchUploadPhoto(requestParameters.photo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get USDA food by fdcId
     * @param {FoodApiAppApiFoodFoodUsdaGetUsdaFoodRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodUsdaGetUsdaFood(requestParameters: FoodApiAppApiFoodFoodUsdaGetUsdaFoodRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodUsdaGetUsdaFood(requestParameters.fdcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get USDA food by fdcId
     * @param {FoodApiAppApiFoodFoodUsdaGetUsdaFoodDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodUsdaGetUsdaFoodData(requestParameters: FoodApiAppApiFoodFoodUsdaGetUsdaFoodDataRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodUsdaGetUsdaFoodData(requestParameters.fdcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search foods by text. Return maximum of 20 items
     * @param {FoodApiAppApiFoodFoodUsdaSearchUsdaDatabaseApiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodUsdaSearchUsdaDatabaseApi(requestParameters: FoodApiAppApiFoodFoodUsdaSearchUsdaDatabaseApiRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodUsdaSearchUsdaDatabaseApi(requestParameters.search_term, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary email csv from usda food data by fdcId
     * @param {FoodApiAppApiFoodFoodUsdaSendDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodUsdaSendData(requestParameters: FoodApiAppApiFoodFoodUsdaSendDataRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodUsdaSendData(requestParameters.fdcid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [DEPRECATED] Delete a whiteboard note for a food from the food editor
     * @param {FoodApiAppApiFoodFoodWhiteboardDeleteWhiteboardItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodWhiteboardDeleteWhiteboardItem(requestParameters: FoodApiAppApiFoodFoodWhiteboardDeleteWhiteboardItemRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodWhiteboardDeleteWhiteboardItem(requestParameters.food_name, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a whiteboard note for a food from the food editor
     * @param {FoodApiAppApiFoodFoodWhiteboardDeleteWhiteboardItemQueryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodWhiteboardDeleteWhiteboardItemQuery(requestParameters: FoodApiAppApiFoodFoodWhiteboardDeleteWhiteboardItemQueryRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodWhiteboardDeleteWhiteboardItemQuery(requestParameters.food_name, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [DEPRECATED] Get the whiteboard notes for a food in the food editor
     * @param {FoodApiAppApiFoodFoodWhiteboardGetRelevantQueuesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodWhiteboardGetRelevantQueues(requestParameters: FoodApiAppApiFoodFoodWhiteboardGetRelevantQueuesRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodWhiteboardGetRelevantQueues(requestParameters.food_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the whiteboard notes for a food in the food editor
     * @param {FoodApiAppApiFoodFoodWhiteboardGetRelevantQueuesQueryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodWhiteboardGetRelevantQueuesQuery(requestParameters: FoodApiAppApiFoodFoodWhiteboardGetRelevantQueuesQueryRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodWhiteboardGetRelevantQueuesQuery(requestParameters.food_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [DEPRECATED] Get the whiteboard notes for a food in the food editor
     * @param {FoodApiAppApiFoodFoodWhiteboardGetWhiteboardItemsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodWhiteboardGetWhiteboardItems(requestParameters: FoodApiAppApiFoodFoodWhiteboardGetWhiteboardItemsRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodWhiteboardGetWhiteboardItems(requestParameters.food_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the whiteboard notes for a food in the food editor
     * @param {FoodApiAppApiFoodFoodWhiteboardGetWhiteboardItemsQueryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodWhiteboardGetWhiteboardItemsQuery(requestParameters: FoodApiAppApiFoodFoodWhiteboardGetWhiteboardItemsQueryRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodWhiteboardGetWhiteboardItemsQuery(requestParameters.food_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [DEPRECATED] Create a new whiteboard note for a food in the food editor
     * @param {FoodApiAppApiFoodFoodWhiteboardPostWhiteboardItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodWhiteboardPostWhiteboardItem(requestParameters: FoodApiAppApiFoodFoodWhiteboardPostWhiteboardItemRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodWhiteboardPostWhiteboardItem(requestParameters.food_name, requestParameters.FoodWhiteboardCreateUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new whiteboard note for a food in the food editor
     * @param {FoodApiAppApiFoodFoodWhiteboardPostWhiteboardItemQueryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodWhiteboardPostWhiteboardItemQuery(requestParameters: FoodApiAppApiFoodFoodWhiteboardPostWhiteboardItemQueryRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodWhiteboardPostWhiteboardItemQuery(requestParameters.food_name, requestParameters.FoodWhiteboardCreateUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [DEPRECATED] Update an existing whiteboard note for a food in the food editor
     * @param {FoodApiAppApiFoodFoodWhiteboardPutWhiteboardItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodWhiteboardPutWhiteboardItem(requestParameters: FoodApiAppApiFoodFoodWhiteboardPutWhiteboardItemRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodWhiteboardPutWhiteboardItem(requestParameters.food_name, requestParameters.id, requestParameters.FoodWhiteboardCreateUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing whiteboard note for a food in the food editor
     * @param {FoodApiAppApiFoodFoodWhiteboardPutWhiteboardItemQueryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodFoodWhiteboardPutWhiteboardItemQuery(requestParameters: FoodApiAppApiFoodFoodWhiteboardPutWhiteboardItemQueryRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodFoodWhiteboardPutWhiteboardItemQuery(requestParameters.food_name, requestParameters.id, requestParameters.FoodWhiteboardCreateUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the ontologies for the food editor
     * @param {FoodApiAppApiFoodOntologyGetAllOntologiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiFoodOntologyGetAllOntologies(requestParameters: FoodApiAppApiFoodOntologyGetAllOntologiesRequest = {}, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiFoodOntologyGetAllOntologies(requestParameters.include_archived, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [DEPRECATED] get a calibrated meal item request object
     * @param {FoodApiAppApiMealGetCalibratedCreateMealItemRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiMealGetCalibratedCreateMealItemRequest(requestParameters: FoodApiAppApiMealGetCalibratedCreateMealItemRequestRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiMealGetCalibratedCreateMealItemRequest(requestParameters.food_name, requestParameters.target_unit, requestParameters.target_value, requestParameters.food_replacement_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Testing endpoint
     * @param {FoodApiAppApiMealQueueGetNlpAutoLogResultsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiMealQueueGetNlpAutoLogResults(requestParameters: FoodApiAppApiMealQueueGetNlpAutoLogResultsRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiMealQueueGetNlpAutoLogResults(requestParameters.search_text, requestParameters.user_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Testing endpoint
     * @param {FoodApiAppApiMealQueueGetNlpAutoLogResultsSpacyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiMealQueueGetNlpAutoLogResultsSpacy(requestParameters: FoodApiAppApiMealQueueGetNlpAutoLogResultsSpacyRequest, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiMealQueueGetNlpAutoLogResultsSpacy(requestParameters.search_text, requestParameters.user_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets food error flags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public appApiNutritionGetErrorFlags(options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).appApiNutritionGetErrorFlags(options).then((request) => request(this.axios, this.basePath));
    }
}
