import { dataReviewApi, mealApi } from 'api';
import { MealChangeLogResponse, MealItemResponse, QA2ChangeContext } from 'api/generated/MNT';
import { config } from 'config';
import { logTrackedError } from 'errorTracking';
import type { MealQueueItem } from './mpq';

export type MealReviewItem = {
  clinic_name: string,
  meal_id: string,
  meal_item_ids: string[],
  created_time: string,
  is_processed: boolean,
  is_reviewed: boolean,
  meal_audio_id: string | null,
  meal_audio_url: string | null,
  meal_photo_id: string,
  meal_photo_url: string,
  meal_search_text: string | null,
  patient_id: number | null,
  patient_note: string | null,
  patient_number_of_items: string,
  patient_sizing_estimate: string | null,
  queue_type: string,
  reviewed_time: string | null,
  updated_time: string,
};

export type MealResponse = {
  id: number,
  meal_date: string,
  meal_items: [
    MealItemResponse,
  ],
  meal_name: string,
  meal_time: string,
  patient_id: number,
};

export const getReviewList = async (opts: {
  reviewer_id: number,
  date_since: string,
  date_until: string,
  clinic_id?: number | null,
  patient_id?: number | null,
  meal_id?: number | null,
  meal_item_id?: number | null,
  access_token: string,
}): Promise<MealQueueItem[]> => {
  const url = `${config.API_URL}/api/data_reviewer/${opts.reviewer_id}/meal_photo_queue/to_be_reviewed?`;
  const params = new URLSearchParams({
    ...(opts.clinic_id ? { clinic_id: opts.clinic_id.toString() } : {}),
    ...(opts.patient_id ? { patient_id: opts.patient_id.toString() } : {}),
    ...(opts.meal_id ? { meal_ids: opts.meal_id.toString() } : {}),
    ...(opts.meal_item_id ? { meal_item_ids: opts.meal_item_id.toString() } : {}),
    ...(opts.date_since ? { date_since: opts.date_since } : {}),
    ...(opts.date_until ? { date_until: opts.date_until } : {}),
  });

  const response = await fetch(url + params, {
    headers: {
      'Authorization': `Bearer ${opts.access_token}`,
      'Content-Type': 'application/json',
    },
    method: 'GET',
    mode: 'cors',
  });

  if (response.status !== 200) {
    throw new Error(`${response.status}`);
  }

  const items: MealQueueItem[] = await response.json();
  return items;
};

export const getReviewListFromQueueIds = async (opts: {
  reviewer_id: number,
  queue_ids: number[],
}): Promise<MealQueueItem[]> => {
  const response = await dataReviewApi.appApiQueueReviewGetMealPhotoQueueToBeReviewedFromQueueIds({
    data_reviewer_id: opts.reviewer_id,
    queue_ids: opts.queue_ids,
  });

  if (response.status !== 200) {
    throw new Error(`${response.status}`);
  }

  const items: MealQueueItem[] = response.data;
  return items;
};

export const getMealItems = async (
  patient_id: number,
  meal_id: number,
  access_token: string,
): Promise<MealResponse> => {
  const url = `${config.API_URL}/api/patients/${patient_id}/meals/${meal_id}`;

  const response = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    },
    method: 'GET',
    mode: 'cors',
  });

  if (response.status !== 200) {
    throw new Error(`${response.status}`);
  }

  const meal: MealResponse = await response.json();
  return meal;
};

export const deleteMealItem = async (
  patient_id: number,
  meal_id: number,
  meal_item_id: number,
  access_token: string,
  change_context?: QA2ChangeContext,
) => {
  const response = await mealApi.appApiMealDeleteMealItem({
    meal_id: meal_id,
    patient_id: patient_id,
    meal_item_id: meal_item_id,
    // sending change reason through header as it is not specified in http specs
    // that del requests can have a body; header values must be strings
    X_Rx_Change_Context: JSON.stringify(change_context),
  });

  if (response.status !== 204) {
    throw new Error(`${response.status}`);
  }

  return true;
};

export const getMealChangeLogs = async (
  patient_id: number,
  meal_id: number,
  access_token: string,
): Promise<MealChangeLogResponse | null> => {
  if (patient_id) {
    const url = `${config.API_URL}/api/patients/${patient_id}/meals/${meal_id}/change-logs`;

    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${access_token}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
      mode: 'cors',
    });

    if (response.status !== 200) {
      throw new Error(`${response.status}`);
    }

    return await response.json() as MealChangeLogResponse;
  }

  return null;
};

export const postNewFoodRequest = async (
  user_id: number,
  food_name: string,
  access_token: string,
) => {
  const url = `${config.API_URL}/api/foods/food_scraping?user_id=${user_id}`;

  const response = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    mode: 'cors',

    body: JSON.stringify({
      food_name,
      brand: null,
      manufacturer: null,
      reference_url: null,
    }),
  });

  if (response.status !== 200) {
    logTrackedError({
      sourceName: 'postNewFoodRequest',
      origin: response,
      stackError: new Error(),
      context: {
        user_id,
        food_name,
      },
      userMessage: 'Error submitting new food request. Please try again.',
    });
    return false;
  }

  alert('New food request submission successful');
  return true;
};
