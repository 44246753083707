import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, Button, CardContent, CardMedia, Chip, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import {
  MealPhotoQueueQualityAssuranceResponse,
  MealPhotoQueueResponse,
  MealPushQuestionStatusEnum,
  QualityAssuranceLogResponse,
} from 'api/generated/MNT';
import { Capabilities } from 'auth-capabilities';
import Avatar from 'components/@extended/Avatar';
import { useAuthenticatedBlobQuery } from 'components/AuthenticatedImage';
import { ImageError } from 'components/ImageError';
import MainCard from 'components/MainCard';
import { MealNoteView } from 'components/MealNoteView';
import { MealQALogs } from 'components/MealQALogs';
import { PatientID } from 'components/PatientID';
import { PopoverImageViewer, usePopoverImage } from 'components/PopoverImageViewer';
import { QueueSummaryIcons } from 'components/QueueSummaryIcons';
import { useMealQALogs } from 'components/useMealQALogs';
import { useAuth } from 'context/appContext';
import { HumanTime } from 'food-editor/components/HumanTime';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAsyncResult } from 'react-use-async-result';
import {
  useUserQualityAssuranceAvailableCount,
  useUserQualityAssuranceSession,
} from 'services/UserQualityAssuranceService';
import { useSocketIOPresenceActiveUsers } from 'socketio/SocketIOService';
import { getMaskedClinicType } from 'utils';
import { HistoryView, MealChangeLogs, MealQoSDetails } from './LegacyReviewItem';
import { MealNameAndTimeForQueue } from './QueueItem/meal-builder/MealNameAndTime';
import { getMealPhotoUrl } from './QueueItem/meal-builder/MealPhoto';
import { MealSummary } from './QueueItem/meal-builder/MealSummary';
import { PatientDiet } from './QueueItem/meal-builder/PatientAge';
import { useDraftItems } from './QueueItem/meal-builder/useDraftItems';
import { usePatientContext } from './QueueItem/meal-builder/usePatientContext';
import { QueueTimerChip } from './QueueItem/QueueItemPage';
import { usePushQuestions } from './QueueItem/services/QueueItemEditorService';

export const InitialQAPage = () => {
  return (
    <MainCard>
      <InitialQA />
    </MainCard>
  );
};

const InitialQA = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [qaListItems, setQAListItems] = useState<MealPhotoQueueQualityAssuranceResponse[]>([]);
  const qaAvailable = useUserQualityAssuranceAvailableCount({
    disabled: !auth.hasAuth(Capabilities.commonDataReviewerAdmin),
  });
  const qaSession = useUserQualityAssuranceSession();

  if (qaAvailable.query.isError) {
    return <div>Could not fetch QA items</div>;
  }
  if (qaAvailable.query.isLoading) {
    return <div>Loading...</div>;
  }

  const addQALog = (queueItem: MealPhotoQueueResponse, log: QualityAssuranceLogResponse) => {
    setQAListItems(prev =>
      prev.map(item => {
        return item.queue_item && item.queue_item.id == queueItem.id
          ? { ...item, qa_logs: [log, ...(item.qa_logs || [])] }
          : item;
      })
    );
  };

  return (
    <CardContent>
      <PopoverImageViewer />
      <Stack spacing={3}>
        <QueueReviewSessionHeader />
        {!!qaAvailable.numQA2AvailableQueues && auth.authInfo?.reviewer_id
          ? (
            <Button
              onClick={() => qaSession.startQA2Session()}
              variant="contained"
              sx={{ width: '30%', margin: '0 auto !important' }}
            >
              {qaSession.session ? 'Resume' : 'Start'} Review Session
            </Button>
          )
          : <Typography>No available queues to QA</Typography>}
      </Stack>
    </CardContent>
  );
};

export const QueueReviewSessionHeader = () => {
  const qaSession = useUserQualityAssuranceSession();
  return qaSession.session
    ? (
      <MainCard
        sx={{
          position: qaSession.isQA2ReviewMode ? 'sticky' : '',
          top: '3.5rem',
          width: '100%',
          zIndex: 10,
          marginBottom: '0.5rem !important',
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <Typography variant="h4">Review</Typography>
          <Typography variant="h5">Queues Reviewed: {qaSession.session.completed_queue_count || 0}</Typography>
          <Typography variant="h5">
            Queues Pending: {qaSession.session.target_queue_count - (qaSession.session.completed_queue_count || 0)}
          </Typography>
          <Typography variant="h5">
            Average Review Time: {qaSession.session.average_review_duration_seconds
              ? (qaSession.session.average_review_duration_seconds / 60).toFixed(2) + 'min'
              : '-'}
          </Typography>
          {qaSession.isQA2ReviewMode && (
            <Button
              variant="contained"
              onClick={() => {
                qaSession.pauseQA2Session();
              }}
            >
              Pause
            </Button>
          )}
        </Stack>
      </MainCard>
    )
    : null;
};

const InitialQAList = (props: {
  qaItems: MealPhotoQueueQualityAssuranceResponse[],
  sourceType: 'recent' | 'queue',
  addQALog: (queueItem: MealPhotoQueueResponse, log: QualityAssuranceLogResponse) => void,
}) => {
  const { qaItems } = props;

  return (
    <Stack spacing={6}>
      {!qaItems.length && <div>There's nothing here...</div>}

      {qaItems.map((item) => {
        const queueItem = item.queue_item;
        if (!queueItem) {
          return null;
        }
        return (
          <InitialQAListItem
            key={queueItem.id}
            queueItem={queueItem}
            hasCustomItems={!!item.has_custom_items}
            sourceType={props.sourceType}
            addQALog={props.addQALog}
          />
        );
      })}
    </Stack>
  );
};

const InitialQAListItem = (props: {
  queueItem: MealPhotoQueueResponse,
  hasCustomItems: boolean,
  sourceType: 'recent' | 'queue',
  addQALog: (queueItem: MealPhotoQueueResponse, log: QualityAssuranceLogResponse) => void,
}) => {
  const { queueItem, sourceType } = props;
  const { authInfo } = useAuth();
  const { allActiveUsers } = useSocketIOPresenceActiveUsers();
  const patientContext = usePatientContext(queueItem);
  const itemReviewedRes = useAsyncResult<QualityAssuranceLogResponse | null>();
  const { qaLogs, postMealQA } = useMealQALogs(queueItem);
  const [showQueueInfo, setShowQueueInfo] = useState(true);

  const pushQuestions = usePushQuestions({
    patientId: queueItem.patient_id,
    mealId: queueItem.created_meal_id,
  });
  // Note: set hasPendingQuestion to 'true' while the query is pending to ensure that fast
  // approve isn't accidentally clicked while the query is loading.
  const hasPendingQuestion = !pushQuestions.query.isSuccess
    || pushQuestions.mealQuestions.some(q => q.question_status == MealPushQuestionStatusEnum.Pendingsystem);

  useEffect(() => {
    if (itemReviewedRes.isDone && itemReviewedRes.result) {
      props.addQALog(queueItem, itemReviewedRes.result);
    }
  }, [itemReviewedRes.isDone]);

  const reviewType = qaLogs.find(log => log.user_id == authInfo?.reviewer_id)?.review_type;

  const getReviewUrl = () => {
    return `/queue-item/${encodeURIComponent(queueItem.id)}`;
  };

  const handleFastApprove = async (queueItem: MealPhotoQueueResponse) => {
    if (sourceType == 'recent' && !confirm('You have already reviewed this item. Review again?')) {
      return null;
    }

    if (!authInfo) {
      return null;
    }
    const { access_token, reviewer_id } = authInfo;
    return await postMealQA(access_token, {
      data_reviewer_id: reviewer_id,
      meal_photo_queue_id: queueItem.id,
      is_reviewed: true,
      is_approved: true,
      is_escalated: false,
      note: 'Fast Approved',
      review_type: 'fast_approve',
      questionnaire: {},
    });
  };

  const handleSkip = async (queueItem: MealPhotoQueueResponse) => {
    if (sourceType == 'recent' && !confirm('You have already reviewed this item. Review again?')) {
      return null;
    }
    if (!authInfo) {
      return null;
    }
    const { access_token, reviewer_id } = authInfo;
    return await postMealQA(access_token, {
      data_reviewer_id: reviewer_id,
      meal_photo_queue_id: queueItem.id,
      is_reviewed: false,
      is_approved: false,
      is_escalated: false,
      note: 'Skipped',
      review_type: 'skip',
      questionnaire: {},
    });
  };

  const usersOnCurrentItem = useMemo(() => {
    const users = _(allActiveUsers)
      .filter((u) => {
        const uLoc = u.ident?.location;
        if (!uLoc) {
          return false;
        }
        return uLoc.split('?')[0] === getReviewUrl();
      })
      .filter(u => u.uid != authInfo?.reviewer_id)
      .sort()
      .uniqBy('uid')
      .value();
    return users;
  }, [allActiveUsers, authInfo?.reviewer_id]);

  return (
    <Stack style={{ width: '100%' }} key={queueItem.id} spacing={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
        <Typography variant="h4">
          <strong>#{queueItem.id}</strong>&nbsp;
          <QueueSummaryIcons queue={queueItem} showCustomIcon={props.hasCustomItems} hideClassif />&nbsp;
          <span style={{ fontWeight: 'normal' }}>patient</span>{' '}
          <PatientID userId={queueItem.patient_id} isPriority={!!queueItem.is_priority_patient} />
          <span style={{ fontWeight: 'normal' }}>{' / '}clinic type</span>{' '}
          {patientContext.query.isSuccess && getMaskedClinicType(patientContext.context?.clinics?.[0]?.clinic_type)
            || 'N/A'}
          <span style={{ fontWeight: 'normal' }}>{' / '}</span>
          <HumanTime value={queueItem.reviewed_time} />
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Stack direction="column">
          <div style={{ width: 200 }}>
            <PreviewItem queueItem={queueItem} />
          </div>
          {queueItem.patient_note && (
            <Typography style={{ width: 200 }}>
              <b>Meal note:&nbsp;</b>
              <MealNoteView
                value={queueItem.patient_note}
                queueId={queueItem.id}
              />
            </Typography>
          )}
        </Stack>
        <Stack style={{ width: '100%', maxWidth: 'calc(100% - 200px - 16vw)' }} alignItems="center" spacing={1}>
          <PreviewTable queueItem={queueItem} />
          {showQueueInfo && (
            <IconButton style={{ borderRadius: '50%' }} onClick={() => setShowQueueInfo(false)}>
              <KeyboardArrowUp />
            </IconButton>
          )}
          {!showQueueInfo && (
            <IconButton style={{ borderRadius: '50%' }} onClick={() => setShowQueueInfo(true)}>
              <KeyboardArrowDown />
            </IconButton>
          )}
        </Stack>
        <Stack style={{ width: '16vw' }} justifyContent="flex-start" spacing={1}>
          <Link
            style={{ cursor: 'pointer', width: '100%' }}
            to={getReviewUrl()}
            target="_blank"
            onClick={(evt) => {
              if (sourceType != 'recent') {
                return false;
              }
              if (confirm('You have already reviewed this item. Review again?')) {
                return true;
              }
              evt.preventDefault();
            }}
          >
            <Button
              style={{ width: '100%' }}
              variant={sourceType == 'recent' ? reviewType == 'review' ? 'contained' : 'outlined' : 'contained'}
              disabled={itemReviewedRes.isPending}
            >
              Review
            </Button>
          </Link>
          <Tooltip title={hasPendingQuestion && 'Cannot fast approve queues with pending questions'}>
            <div style={{ width: '100%' }}>
              <Button
                variant={sourceType == 'recent' ? reviewType == 'fast_approve' ? 'contained' : 'outlined' : 'contained'}
                color="success"
                onClick={() => itemReviewedRes.bind(handleFastApprove(queueItem))}
                disabled={itemReviewedRes.isPending || hasPendingQuestion}
                style={{ width: '100%' }}
              >
                Fast Approve
              </Button>
            </div>
          </Tooltip>
          <Button
            variant={sourceType == 'recent' ? reviewType == 'skip' ? 'contained' : 'outlined' : 'contained'}
            color="warning"
            onClick={() => itemReviewedRes.bind(handleSkip(queueItem))}
            disabled={itemReviewedRes.isPending}
          >
            Skip
          </Button>
          {usersOnCurrentItem.map((u) => (
            <Avatar key={u.uid}>
              <Tooltip key={u.uid} title={`${u.uid} is also viewing this page`}>
                <span>{u.uid}</span>
              </Tooltip>
            </Avatar>
          ))}
        </Stack>
      </Stack>
      <span style={{ display: showQueueInfo ? 'block' : 'none' }}>
        <QueueInfo queueItem={queueItem} />
      </span>
    </Stack>
  );
};

const QueueInfo = (props: {
  queueItem: MealPhotoQueueResponse,
}) => {
  const { queueItem } = props;
  const { authInfo, hasAuth } = useAuth();
  const patientContext = usePatientContext(queueItem);
  const { draftItems } = useDraftItems(queueItem);

  const dietRestrictions = queueItem.queue_metadata?.patient_context?.diet_restrictions
    ?? patientContext.context?.diet_restrictions
    ?? [];

  const itemHeight = 300;

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1}>
        <span style={{ width: '30%', height: itemHeight }}>
          <HistoryView
            queueItem={queueItem}
            draftItems={draftItems}
            asCard
            mainCardStyle={{ height: itemHeight, width: '100%' }}
          />
        </span>
        <span style={{ width: '40%', height: itemHeight, overflow: 'scroll' }}>
          <MealChangeLogs
            queueItem={queueItem}
            draftItems={draftItems}
            mainCardStyle={{ width: '100%', height: itemHeight }}
            hideTitle
          />
        </span>
        <MainCard style={{ width: '30%', height: itemHeight, overflowY: 'auto' }}>
          <Stack direction="row" spacing={1} style={{ float: 'right' }}>
            {!queueItem.is_processed && <QueueTimerChip queueItem={queueItem} />}
            {hasAuth(Capabilities.mpqChangeLogsFullAttributionView) && (
              queueItem.is_processed
                ? (
                  <Chip
                    variant="combined"
                    color="warning"
                    label={'QA1: ' + queueItem.first_reviewer_user_id}
                  />
                )
                : authInfo?.reviewer_id === queueItem.first_reviewer_user_id
                ? <Chip variant="combined" color="info" label="QA1: you" />
                : queueItem.first_reviewer_user_id
                ? (
                  <Chip
                    variant="combined"
                    color="error"
                    label={'QA1: ' + queueItem.first_reviewer_user_id}
                  />
                )
                : ''
            )}
          </Stack>
          <PatientDiet dietRestrictions={dietRestrictions} />
          <MealNameAndTimeForQueue queueItem={queueItem} />
          {!!queueItem.patient_note_translations && !!('en' in queueItem.patient_note_translations) && (
            <Typography variant="h4">
              <b>Translated meal note:</b> {queueItem.patient_note_translations.en}
            </Typography>
          )}
        </MainCard>
      </Stack>
      <Stack direction="row" spacing={1}>
        <span style={{ width: '50%' }}>
          <MealQALogs />
        </span>
        <span style={{ width: '50%' }}>
          <MealQoSDetails item={queueItem} mainCardStyle={{ maxHeight: 300, overflowY: 'auto' }} />
        </span>
      </Stack>
    </Stack>
  );
};

const mediaSX = {
  width: 200,
  height: 180,
  borderRadius: 1,
  cursor: 'pointer',
};

const textSX = {
  ...mediaSX,
  display: 'flex',
  cursor: 'default',
  backgroundColor: 'grey.300',
  alignItems: 'center',
  justifyContent: 'center',
};

const PreviewItem = (props: {
  queueItem: MealPhotoQueueResponse,
}) => {
  const { queueItem } = props;
  const img = useAuthenticatedBlobQuery(getMealPhotoUrl(queueItem, 'original'));
  const popoverImage = usePopoverImage();

  return (
    !img.isEnabled
      ? (
        <Box
          sx={textSX}
        >
          {queueItem.patient_note || 'No note available for this item'}
        </Box>
      )
      : img.src
      ? (
        <CardMedia
          component="img"
          src={img.src}
          sx={mediaSX}
          onClick={() => popoverImage.show({ src: img.src })}
        />
      )
      : <ImageError sx={mediaSX} />
  );
};

const PreviewTable = (props: {
  queueItem: MealPhotoQueueResponse,
}) => {
  const { queueItem } = props;

  const patientContext = usePatientContext(queueItem);
  const { draftItems, query } = useDraftItems(queueItem);

  return (
    <Stack style={{ width: '100%' }}>
      {query.isError && <div>Could not fetch item data</div>}
      {query.isLoading && <div>Loading...</div>}
      {query.isSuccess && <MealSummary queue={queueItem} patientContext={patientContext} draftItems={draftItems} />}
    </Stack>
  );
};
