/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { MealPhotoQueueResponse } from './meal-photo-queue-response';

/**
 * 
 * @export
 * @interface QualityAssuranceSessionResponse
 */
export interface QualityAssuranceSessionResponse {
    /**
     * 
     * @type {number}
     * @memberof QualityAssuranceSessionResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof QualityAssuranceSessionResponse
     */
    'data_reviewer_id': number;
    /**
     * 
     * @type {number}
     * @memberof QualityAssuranceSessionResponse
     */
    'target_queue_count': number;
    /**
     * 
     * @type {number}
     * @memberof QualityAssuranceSessionResponse
     */
    'completed_queue_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof QualityAssuranceSessionResponse
     */
    'average_review_duration_seconds'?: number;
    /**
     * 
     * @type {string}
     * @memberof QualityAssuranceSessionResponse
     */
    'status': QualityAssuranceSessionResponseStatusEnum;
    /**
     * 
     * @type {MealPhotoQueueResponse}
     * @memberof QualityAssuranceSessionResponse
     */
    'current_queue'?: MealPhotoQueueResponse;
    /**
     * 
     * @type {number}
     * @memberof QualityAssuranceSessionResponse
     */
    'review_id'?: number | null;
}

export const QualityAssuranceSessionResponseStatusEnum = {
    Active: 'active',
    Paused: 'paused',
    NoAvailableQueues: 'no_available_queues',
    TimedOut: 'timed_out',
    Completed: 'completed'
} as const;

export type QualityAssuranceSessionResponseStatusEnum = typeof QualityAssuranceSessionResponseStatusEnum[keyof typeof QualityAssuranceSessionResponseStatusEnum];


