// project import
import {
  EditOutlined,
  EyeOutlined,
  FileDoneOutlined,
  FileSearchOutlined,
  HistoryOutlined,
  InboxOutlined,
  LikeOutlined,
  RedoOutlined,
  RetweetOutlined,
  RocketOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { foodApi } from 'api';
import { MealPhotoQueueQualityAssuranceResponse } from 'api/generated/MNT';
import { getQueuesForQa } from 'apiClients/mpq';
import { Capabilities } from 'auth-capabilities';
import { QueueSummaryIcons } from 'components/QueueSummaryIcons';
import { UserPresenceStateBadge } from 'components/UserPresenceStateBadge';
import { config } from 'config';
import { useAuth } from 'context/appContext';
import { useMemo } from 'react';
import { useAppUpdateService } from 'services/AppUpdateService';
import { useMealQueueService } from 'services/MealQueueService';
import { useMenuBar } from 'services/MenuBarService';
import { useUserQualityAssuranceAvailableCount } from 'services/UserQualityAssuranceService';
import { NavItemType } from 'types/menu';
import { navItemCheckAuth } from './MainLayout/Drawer/DrawerContent/Navigation';
import { NavItem, NavItemProps } from './MainLayout/Drawer/DrawerContent/Navigation/NavItem';

const useCountAllQAItems = (opts?: {
  disabled?: boolean,
}) => {
  const { authInfo } = useAuth();
  const highPrioNotReviewedQAQuery = useQuery<MealPhotoQueueQualityAssuranceResponse[]>([
    'qa-item-list-prio-not-reviewed',
  ], async () => {
    if (config.EMERGENCY_THROTTLE_QA) {
      return [];
    }
    return getQueuesForQa(authInfo!.access_token, {
      data_reviewer_id: authInfo!.reviewer_id,
      is_priority: 'true',
      is_reviewed: 'false',
      limit: 20,
    });
  }, {
    refetchInterval: 60 * 1000,
    enabled: !opts?.disabled,
  });

  const highPrioEscalatedNoCustomQAQuery = useQuery<MealPhotoQueueQualityAssuranceResponse[]>([
    'qa-item-list-prio-escalated-no-custom',
  ], async () => {
    if (config.EMERGENCY_THROTTLE_QA) {
      return [];
    }
    return getQueuesForQa(authInfo!.access_token, {
      data_reviewer_id: authInfo!.reviewer_id,
      is_priority: 'true',
      is_escalated: 'true',
      has_custom_items: 'false',
      limit: 20,
    });
  }, {
    refetchInterval: 60 * 1000,
    enabled: !opts?.disabled,
  });

  return {
    numQAItems: (highPrioNotReviewedQAQuery.data?.length ?? 0) + (highPrioEscalatedNoCustomQAQuery.data?.length ?? 0),
    isSuccess: highPrioNotReviewedQAQuery.isSuccess && highPrioEscalatedNoCustomQAQuery.isSuccess,
  };
};

// ==============================|| MENU ITEMS ||============================== //

const UpdateAppMenuItem = (props: NavItemProps) => {
  const theme = useTheme();
  const { updateAvailable } = useAppUpdateService();

  const reload = () => {
    if (!updateAvailable) {
      return;
    }

    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('target-version', updateAvailable);
    window.location.href = '?' + queryParams.toString();
  };

  if (config.IS_PREVIEW) {
    return (
      <NavItem
        level={props.level}
        item={{
          id: 'build-time',
          type: 'item',
          disabled: false,
          onClick: () => {
            alert(
              'This is a preview build that contains new and experimental features.\n'
                + 'Please report any issues to the development team :)',
            );
          },
          title: config.RELEASE_LABEL,
          chip: {
            label: 'preview',
            color: 'primary',
          },
        }}
      />
    );
  }

  if (updateAvailable) {
    return (
      <NavItem
        level={props.level}
        item={{
          id: 'build-time',
          type: 'item',
          title: 'Update App',
          icon: RetweetOutlined,
          onClick: reload,
          chip: {
            label: 'Up',
            color: 'warning',
          },
        }}
      />
    );
  }

  return (
    <NavItem
      level={props.level}
      item={{
        id: 'build-time',
        type: 'item',
        disabled: true,
        title: config.RELEASE_LABEL,
      }}
    />
  );
};

const QueueMenuItem = (props: NavItemProps) => {
  const mpq = useMealQueueService();
  const { drawerOpen } = useMenuBar();

  const ownedQueues = mpq.ownedQueues;
  const otherQueues = mpq.allActiveQueues.filter(q => !ownedQueues.find(oq => oq.id === q.id));
  const queuesToShow = [...ownedQueues, ...otherQueues];

  const shouldShowChip = !mpq.currentlyLabellingOwnQueue
    && (mpq.ownedCount || mpq.availableCount);

  return (
    <>
      <NavItem
        level={props.level}
        item={{
          title: 'Queue',
          url: '/',
          icon: InboxOutlined,
          chip: !shouldShowChip ? null : {
            label: '' + (mpq.ownedCount || mpq.availableCount),
            color: 'error',
            size: 'small',
          },
        }}
      />
      {drawerOpen && queuesToShow.map((queue) => (
        <NavItem
          key={queue.id}
          level={props.level + 1}
          item={{
            id: 'queue-' + queue.id,
            title: (
              <>
                <div style={{ position: 'absolute', left: '1.85rem', top: '0.75rem' }}>
                  <UserPresenceStateBadge userId={queue.patient_id} hideOffline />
                </div>
                {queue.patient_id}/{queue.id}
              </>
            ),
            url: '/queue-item/' + queue.id + '?utm_source=navbar',

            iconRight: <QueueSummaryIcons queue={queue} />,
          }}
        />
      ))}
    </>
  );
};

const InitialQAMenuItem = (props: NavItemProps) => {
  const auth = useAuth();
  const qaAvailable = useUserQualityAssuranceAvailableCount({
    disabled: !auth.hasAuth(Capabilities.commonDataReviewerAdmin),
  });

  const toReviewItemsLength = !auth.hasAuth(Capabilities.commonDataReviewerAdmin)
    ? 0
    : qaAvailable.numQA2AvailableQueues;

  return (
    <NavItem
      level={props.level}
      item={{
        id: 'qa',
        title: 'Initial QA',
        url: '/qa/initial-qa',
        icon: RocketOutlined,
        chip: !toReviewItemsLength ? null : {
          label: `${toReviewItemsLength >= 20 ? '20+' : toReviewItemsLength}`,
          color: 'warning',
          size: 'small',
        },
      }}
    />
  );
};

const AllQueuesMenuItem = (props: NavItemProps) => {
  const { authInfo } = useAuth();
  const { numQAItems } = useCountAllQAItems({
    disabled: !authInfo?.is_data_reviewer_admin,
  });

  const toReviewItemsLength = !authInfo?.is_data_reviewer_admin
    ? 0
    : numQAItems;

  return (
    <NavItem
      level={props.level}
      item={{
        id: 'all-qa',
        title: 'All Queues',
        url: '/qa',
        icon: EyeOutlined,
        chip: !toReviewItemsLength ? null : {
          label: `${toReviewItemsLength >= 20 ? '20+' : toReviewItemsLength}`,
          color: 'error',
          size: 'small',
        },
      }}
    />
  );
};

const MyFoodsPage = (props: NavItemProps) => {
  const toDoItemsLength = useMyFoodsChipNum();

  return (
    <NavItem
      level={props.level}
      item={{
        id: 'my-foods',
        title: 'My Foods',
        url:
          '/foods/table/my-foods?food-database-table-sort=%5B%5D&food-database-table-filter=%7B%22is_associated_with_me%22%3Atrue%7D',
        chip: !toDoItemsLength ? null : {
          label: `${toDoItemsLength >= 20 ? '20+' : toDoItemsLength}`,
          color: 'warning',
          size: 'small',
        },
      }}
    />
  );
};

const NAVBAR_MENU_ITEMS: { items: NavItemType[] } = {
  items: [
    {
      id: '',
      title: '',
      type: 'group',
      children: [
        {
          id: 'queue',
          type: 'item',
          auth: Capabilities.mpqItemView,
          render: (props) => <QueueMenuItem {...props} />,
        },
        {
          id: 'food-search',
          title: 'Foods',
          type: 'collapse',
          icon: FileSearchOutlined,
          auth: Capabilities.foodDbView,
          children: [
            {
              id: 'food-search',
              title: 'Food Search',
              type: 'item',
              url: '/foods',
            },
            {
              id: 'all-foods',
              title: 'All Foods',
              type: 'item',
              url: '/foods/table',
            },
            {
              id: 'custom-triage-foods',
              title: 'Custom Items',
              type: 'item',
              url:
                '/foods/table/custom?food-database-table-sort=[{"field"%3A"created_time"%2C"direction"%3A"asc"}]&food-database-table-filter={"status"%3A{"eq"%3A"custom_meal_item"}}',
            },
            {
              id: 'triaged-foods',
              title: 'Triaged Foods',
              type: 'item',
              url:
                '/foods/table/triaged?food-database-table-sort=[{"field"%3A"created_time"%2C"direction"%3A"asc"}]&food-database-table-filter={"status"%3A{"eq"%3A"triaged"}%2C"option_value_preparation_method"%3A{"ne"%3A"recipe"}}',
            },
            {
              id: 'triaged-recipes',
              title: 'Triaged Recipes',
              type: 'item',
              url:
                '/foods/table/triaged-recipes?food-database-table-sort=[{"field"%3A"created_time"%2C"direction"%3A"asc"}]&food-database-table-filter={"status"%3A{"eq"%3A"triaged"}%2C"option_value_preparation_method"%3A{"eq"%3A"recipe"}}',
            },
            {
              id: 'triaged-cpg',
              title: 'Triaged CPGs',
              type: 'item',
              url:
                '/foods/table/triaged-cpg?food-database-table-sort=[{"field"%3A"created_time"%2C"direction"%3A"asc"}]&food-database-table-filter={"status"%3A{"eq"%3A"triaged"}%2C"option_value_preparation_method"%3A{"eq"%3A"cpg"}}',
            },
            {
              id: 'draft-foods',
              title: 'Draft Foods',
              type: 'item',
              url:
                '/foods/table/draft?food-database-table-sort=[{"field"%3A"created_time"%2C"direction"%3A"asc"}]&food-database-table-filter={"status"%3A{"eq"%3A"draft"}}',
            },
            {
              id: 'review-foods',
              title: 'Ready for Review',
              type: 'item',
              url:
                '/foods/table/review?food-database-table-sort=[{"field"%3A"created_time"%2C"direction"%3A"asc"}]&food-database-table-filter={"status"%3A{"eq"%3A"under_review"}}',
            },
            {
              id: 'my-foods',
              title: 'My Foods',
              type: 'item',
              url:
                '/foods/table/my-foods?food-database-table-sort=%5B%5D&food-database-table-filter=%7B%22is_associated_with_me%22%3Atrue%7D',
              render: (props) => <MyFoodsPage {...props} />,
            },
          ],
        },
      ],
    },
    {
      id: 'qa',
      title: 'QA',
      type: 'group',
      children: [
        {
          id: 'initial-qa',
          title: 'Initial QA',
          type: 'item',
          auth: Capabilities.mpqQAView,
          url: '/qa/initial-qa',
          render: (props) => <InitialQAMenuItem {...props} />,
        },
        {
          id: 'all-qa',
          title: 'All Queues',
          type: 'item',
          url: '/qa',
          auth: Capabilities.mpqItemView,
          render: (props) => <AllQueuesMenuItem {...props} />,
        },
        {
          id: 'feedback',
          title: 'Feedback',
          type: 'item',
          url: '/feedback',
          auth: Capabilities.mpqQAView,
          icon: LikeOutlined,
        },
      ],
    },
    {
      id: 'review',
      title: 'Review',
      type: 'group',
      children: [
        {
          id: 'review',
          title: 'Queues',
          type: 'item',
          auth: Capabilities.mpqItemView,
          url: '/review',
          icon: RedoOutlined,
        },
        {
          id: 'custom-meal-items',
          title: 'Custom Meal Items',
          type: 'item',
          auth: Capabilities.commonDataReviewerAdmin,
          url: '/custom-meal-items',
          icon: EditOutlined,
        },
        {
          id: 'report-review',
          title: 'Patient Reports',
          type: 'item',
          auth: Capabilities.patientReportView,
          url: '/patient-reports',
          icon: FileDoneOutlined,
        },
      ],
    },
    {
      id: 'development-and-training',
      title: 'Development & Training',
      type: 'group',
      children: [
        {
          id: 'replay-logs',
          title: 'Replay Logs',
          type: 'item',
          auth: Capabilities.trainingLogReplay,
          url: '/dev/replaylogs',
          icon: HistoryOutlined,
        },
      ],
    },
    {
      id: 'build-info',
      title: 'Build Info',
      type: 'group',
      children: [
        {
          id: 'build-time',
          type: 'item',
          render: props => <UpdateAppMenuItem {...props} />,
        },
      ],
    },
  ],
};

export const useNavbarMenuItems = () => {
  const { hasAuth } = useAuth();

  return useMemo(() => {
    const filterItem = (item: NavItemType): NavItemType | null => {
      if (!navItemCheckAuth(item, hasAuth)) {
        return null;
      }

      const children = item.children?.map(filterItem).filter(x => !!x) as NavItemType[] ?? null;
      if (item.children?.length && !children?.length) {
        return null;
      }

      return {
        ...item,
        children,
      };
    };
    return {
      ...NAVBAR_MENU_ITEMS,
      items: NAVBAR_MENU_ITEMS.items.map(filterItem).filter(x => !!x) as NavItemType[],
    };
  }, [hasAuth]);
};

export const useMyFoodsChipNum = () => {
  const { authInfo } = useAuth();

  // Note: Querying this way causes side bar to blink when hovered
  // Quick fix for now: do useQuery directly with a refetch interval of 1 min
  // const myFoodTable = useFoodDatabaseTable({
  //   tableFilter: { is_associated_with_me: true },
  //   useSearchParams: false,
  // });

  const myFoodTable = useQuery(['my-foods-chip'], async () => {
    const res = await foodApi.appApiFoodFoodSearchGetFoodDatabaseTable({
      filter: JSON.stringify({ is_associated_with_me: true }),
    });
    return res.data;
  }, { refetchInterval: 60 * 1000 });

  if (!myFoodTable.isSuccess) {
    return 0;
  }

  const chipNum =
    myFoodTable.data?.rows?.filter(r =>
      (r.reviewed_by_user_id == authInfo?.reviewer_id && !r.reviewed_time)
      || (r.scraped_by_user_id == authInfo?.reviewer_id && !r.scraped_time)
    ).length ?? 0;

  return chipNum;
};
