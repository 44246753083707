import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { dataReviewApi } from 'api';
import { Capabilities } from 'auth-capabilities';
import { useMealQALogs } from 'components/useMealQALogs';
import { useAuth } from 'context/appContext';
import mixpanel from 'mixpanel-browser';
import { useQueueItemEditor } from 'pages/QueueItem/services/QueueItemEditorService';
import React from 'react';
import { matchPath, useNavigate } from 'react-router';
import { useQueryNeverRefetch } from 'utils';

export const useUserQualityAssuranceSession = () => {
  const auth = useAuth();
  const editor = useQueueItemEditor();
  const mealQALogs = useMealQALogs(editor.queueItem);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const reviewSessionMatch = matchPath({
    path: 'qa/session/:sessionId/queue/:itemId',
  }, location.pathname);

  const reviewerSessionKey = ['review session', auth.authInfo?.reviewer_id];

  const hasSessionPermissions = !!auth.authInfo && auth.hasAuth(Capabilities.commonDataReviewerAdmin);

  const isQA2ReviewMode = React.useMemo(() => {
    return !!reviewSessionMatch;
  }, [reviewSessionMatch]);

  const reviewSessionQuery = useQuery({
    queryKey: reviewerSessionKey,
    queryFn: async () => {
      if (!hasSessionPermissions) {
        return null;
      }
      const currentQaSession = await dataReviewApi.appApiQueueReviewGetQaSessionCurrent({
        data_reviewer_id: auth.authInfo!.reviewer_id,
      });
      if (isQA2ReviewMode && currentQaSession.data.id.toString() !== reviewSessionMatch?.params.sessionId) {
        return null;
      }
      return currentQaSession.data;
    },
    ...useQueryNeverRefetch,
  });

  const startQA2SessionMutation = useMutation({
    mutationFn: async () => {
      if (!hasSessionPermissions) {
        return;
      }

      try {
        let qaSession = await dataReviewApi.appApiQueueReviewPostQaSessionStart({
          data_reviewer_id: auth.authInfo!.reviewer_id,
        });

        if (!qaSession?.data?.id) {
          throw new Error('Failed to start QA session');
        }

        qaSession = await dataReviewApi.appApiQueueReviewPostQaSessionNextQueue({
          data_reviewer_id: auth.authInfo!.reviewer_id,
          qa_session_id: qaSession.data.id,
        });

        if (!qaSession?.data) {
          throw new Error('Failed to get next queue');
        }

        if (qaSession.data.status === 'no_available_queues' || !qaSession.data.current_queue?.id) {
          alert('No available queues to QA');
          return;
        }

        navigate(
          `/qa/session/${qaSession.data.id}/queue/${qaSession.data.current_queue.id}?utm_source=initial-qa`,
        );

        return qaSession.data;
      } catch (err) {
        console.error('Error starting QA session:', err);
        alert('Failed to start QA session');
      }
    },
    onSuccess: (data) => {
      queryClient.setQueryData(reviewerSessionKey, data);
    },
  });

  const hasSession = reviewSessionQuery.isSuccess && reviewSessionQuery.data?.id;

  const getNextQA2SessionQueueMutation = useMutation({
    mutationFn: async () => {
      if (!hasSessionPermissions || !hasSession) {
        return;
      }
      const updatedQaSession = await dataReviewApi.appApiQueueReviewPostQaSessionNextQueue({
        data_reviewer_id: auth.authInfo!.reviewer_id,
        qa_session_id: reviewSessionQuery.data!.id,
      });
      return updatedQaSession.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(reviewerSessionKey, data);
    },
  });

  const pauseQA2SessionMutation = useMutation({
    mutationFn: async () => {
      if (!hasSessionPermissions || !hasSession) {
        return;
      }
      const updatedQaSession = await dataReviewApi.appApiQueueReviewPostQaSessionPause({
        data_reviewer_id: auth.authInfo!.reviewer_id,
        qa_session_id: reviewSessionQuery.data!.id,
      });
      return updatedQaSession.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(reviewerSessionKey, data);
    },
  });

  const resumeQA2SessionMutation = useMutation({
    mutationFn: async () => {
      if (!hasSessionPermissions || !hasSession) {
        return;
      }
      const updatedQaSession = await dataReviewApi.appApiQueueReviewPostQaSessionResume({
        data_reviewer_id: auth.authInfo!.reviewer_id,
        qa_session_id: reviewSessionQuery.data!.id,
      });
      return updatedQaSession.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(reviewerSessionKey, data);
    },
  });

  const saveQA2Log = (args: { isApproved: boolean, isEscalated: boolean }) => {
    if (!hasSessionPermissions) {
      return;
    }
    const qa2LogData = {
      is_reviewed: true,
      is_escalated: args.isEscalated,
      is_approved: args.isApproved,
      review_type: 'review',
      data_reviewer_id: auth.authInfo!.reviewer_id,
      meal_photo_queue_id: editor.queueItem.id,
      note: '',
    };

    mixpanel.track('QA Submitted', qa2LogData);
    return mealQALogs.postMealQA(auth.authInfo!.access_token, qa2LogData);
  };

  return React.useMemo(() => {
    return {
      isQA2ReviewMode,
      query: reviewSessionQuery,
      session: reviewSessionQuery.data ?? null,
      startQA2Session: startQA2SessionMutation.mutate,
      getNextQA2SessionQueue: getNextQA2SessionQueueMutation.mutateAsync,
      pauseQA2Session: pauseQA2SessionMutation.mutate,
      resumeQA2Session: resumeQA2SessionMutation.mutate,
      saveQA2Log,
    };
    // reviewSessionQuery is not stable, we only want to update when data is available/has changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewSessionQuery.isSuccess, reviewSessionQuery.data]);
};

export const useUserQualityAssuranceAvailableCount = (opts?: {
  disabled?: boolean,
}) => {
  const auth = useAuth();

  const qaAvailableCountQuery = useQuery<number>(['initial-qa-count'], async () => {
    const updatedQaSession = await dataReviewApi.appApiQueueReviewGetAvailableCount({
      data_reviewer_id: auth.authInfo!.reviewer_id,
    });
    return updatedQaSession.data;
  }, {
    refetchInterval: 60 * 1000,
    enabled: !opts?.disabled,
  });
  return React.useMemo(() => {
    return {
      numQA2AvailableQueues: qaAvailableCountQuery.isSuccess ? qaAvailableCountQuery.data : 0,
      query: qaAvailableCountQuery,
    };
    // the query returned by useQuery is not stable, we only want to update when data is available/has changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qaAvailableCountQuery.isSuccess, qaAvailableCountQuery.data]);
};
